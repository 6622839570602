import { FC, useState } from "react";
import {
  Stack,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  styled,
  Paper,
} from "@mui/material";
import theme from "../../../theme/theme";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useParams, useNavigate } from "react-router-dom";
import ButtonGradient from "../../../components/ButtonPrimary";
import TitleForm from "../../../components/TitleForm";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationForgotPassword } from "../validation";
import { registerTypes } from "../../../types/Types";
import { getChangePassword } from "../../../services/Services";
import { MappRiskLogo } from "../../../components/svgicons/SvgIcons";
import { SVChangePassRequest } from "../../../services/security/SecurityService";
import { properties } from "../../../utils/Properties_es";
import { useApp } from "../../../hooks/useApp";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(0, 2),
}));

const Span = styled("span")(({ theme }) => ({
  color: "#326267",
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { code } = useParams();
  const [response, setResponse] = useState<any>(undefined);
  const formOptions = { resolver: yupResolver(validationForgotPassword) };

  const {
    isLoading,
    setLoading,
    setErrorMsg,
    successMsg,
    setSuccessMsg,
    errorMsg,
    resetErrorMsg,
    resetSuccessMsg,
  } = useApp();

  const { register, handleSubmit, formState, control } =
    useForm<registerTypes>(formOptions);
  const { errors } = formState;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    setLoading && setLoading(true);
    try {
      await SVChangePassRequest({
        id: code,
        password: data.password,
      });
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_forgotpass_general_sucess);
      navigate("/", { replace: true });
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(
          error.message
            ? error.message
            : properties.com_mapprisk_label_recoverypass_general_error
        );
    }
  };

  return (

        <form onSubmit={handleSubmit(onSubmit)}>
          <TitleForm>Recuperar Contraseña</TitleForm>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Controller
                name={"password"}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Contraseña"
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    autoComplete="password"
                    {...register("password")}
                    error={errors.password && Boolean(errors.password)}
                    helperText={errors.password && errors.password.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"repeatPassword"}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Confirmar Contraseña"
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    autoComplete="repeatPassword"
                    {...register("repeatPassword")}
                    error={
                      errors.repeatPassword && Boolean(errors.repeatPassword)
                    }
                    helperText={
                      errors.repeatPassword && errors.repeatPassword.message
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {response && (
            <Typography
              variant="body1"
              color="initial"
              align="center"
              sx={{ my: 2, mx: "auto" }}
            >
              {response.estatus === 400
                ? response.body.fieldErrors[0]
                : response.body.info}
            </Typography>
          )}
          <Stack pt={3} pb={1} justifyContent="center" alignItems="center">
            <ButtonGradient type="submit" fullWidth>
              Enviar
            </ButtonGradient>
            <Typography align="center" sx={{ mt: 6 }} variant="body1">
              <LinkTo to="/">Regresar a inicio de sesión</LinkTo>
            </Typography>
          </Stack>
        </form>
  );
};

export default ForgotPassword;
