import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequestDeparment = async (
  page: any,
  filter: any,
  companyId: any,
  subcompanyId: any,
  size?: string
) => {
  let responseData = {};

  await privateFetch
    .get(
      URL.REQUEST_DEPARMENT(
        page,
        size ?? UrlBase.registration_x_page,
        companyId,
        subcompanyId,
        filter
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const createDeparment = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_DEPARMENT_CREATE, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const updateDeparment = async (data: any) => {
  let responseData = {};

  await privateFetch
    .put(URL.REQUEST_DEPARMENT_UPDATE(data.id), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const deleteDeparment = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_DEPARMENT_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error);
      throw new Error(error.response.data.message);
      //throw new Error(properties.com_mapprisk_label_role_delete_error);
    });
  return responseData;
};
