import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
  Navigate,
} from "react-router-dom";
import DashboardHome from "../layouts/DashboardHome";
import UserManagement from "../pages/UserManagement";
// import Reports from "../pages/Reports";
import EmailRoleManagement from "../pages/EmailRoleManagement";

import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
import DocumentsRoleManagement from "../pages/DocumentsRoleManagement";
import AuthorityManagement from "../pages/AuthorityManagement";
import AuthorityByRoleManagement from "../pages/AuthorityByRoleManagement";
// import ClientManagement from "../pages/ClientManagement";
import Dashboard from "../pages/Dashboard";
import OfficeManagement from "../pages/OfficeManagement";
import ProviderManagement from "../pages/ProviderManagement";
import CategoryManagement from "../pages/CategoryManagement";
import ReportManagement from "../pages/ReportManagement";
import UploadFileManagement from "../pages/UploadFileManagement";
import SectionManagement from "../pages/SectionManagement";
import AccountManagement from "../pages/AccountManagement";
import RenglonManagement from "../pages/RenglonManagement";
import BalanceManagement from "../pages/BalanceManagement";
import AreaManagement from "../pages/AreaManagement";
import Report from "../pages/Report";
import GeneralFormLayout from "../layouts/GeneralFormLayout";
import Login from "../pages/session/login/Login";
import RecoverPassword from "../pages/session/password/RememberPassword";
import ForgotPassword from "../pages/session/password/ForgotPassword";
import RubrosManagement from "../pages/maintenance/RubrosManagement";
import RubroPage from "../pages/maintenance/Rubro";
import ActiveRegister from "../pages/session/register/ActiveRegister";
import OpenApi from "../pages/maintenance/OpenApi";
import SettingCompanies from "../pages/SettingCompanies";
import SettingAuthorityByRoleManagement from "../pages/SettingAuthorityByRoleManagement";
import RoleManagement from "../pages/RoleManagement";
import RiskLevelsPage from "../pages/maintenance/RiskLevels";
import FactorsManagement from "../pages/maintenance/FactorsManagement";
import TrainingControl from "../pages/TrainingControl";
import SettingTrainingControl from "../pages/SettingTrainingControl";
import SettingLevelFactors from "../pages/maintenance/SettingLevelFactors";
import Agrupacion from "../pages/riskmanagement/Step1Agrupacion";
import ValidateClient from "../pages/ValidateClient";
import SingleFactor from "../pages/maintenance/SingleFactor";
import SecondLevelSingleFactor from "../pages/maintenance/SecondLevelSingleFactor";
import SettingEmployees from "../pages/SettingEmployees";
import MatrizManagement from "../pages/MatrizManagement";
import DeparmentManagement from "../pages/DeparmentManagement";
import { DueDiligence } from "../pages/duediligence";
import RiskMatrixTabs from "../pages/riskmanagement/RiskMatrixTabs";

//borrar
import PruebaNiveles from "../pages/maintenance/PruebaNiveles";
import BranchManagement from "../pages/BranchManagement";
import { DuelDiligentProvider } from "../pages/duediligence/provider/DuediligentProvider";
//
const Session = lazy(() => import("../pages/session/Session"));
/** Utils page */
const NotFound = lazy(() => import("../components/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/" element={<Login />} />
      {/* <Route path="/session" element={<Session />} /> */}
      <Route path="/recover-password" element={<RecoverPassword />} />
      <Route
        path="/forgot-password/:code/:email"
        element={<ForgotPassword />}
      />
      <Route
        path="/active-register/:code/:email"
        element={<ActiveRegister />}
      />
      <Route path="/dashboard" element={<DashboardHome />}>
        <Route path="userManagement" element={<UserManagement />} />
        <Route path="roleManagement" element={<RoleManagement />} />
        <Route path="authority" element={<AuthorityManagement />} />
        <Route path="rubrosManagement" element={<RubrosManagement />} />
        <Route path="rubrosManagement/:endPointName" element={<RubroPage />} />
        <Route path="riskLevels" element={<RiskLevelsPage />} />

        <Route
          path="duediligence/:clientType"
          element={
            <DuelDiligentProvider>
              <DueDiligence />
            </DuelDiligentProvider>
          }
        />

        <Route
          path="authoritiesbyrole/:roleId"
          element={<SettingAuthorityByRoleManagement />}
        />
        <Route path="openapi" element={<OpenApi />} />
        <Route path="settingCompanies" element={<SettingCompanies />} />
        <Route path="factorsManagement" element={<FactorsManagement />} />
        <Route path="singleFactor/:ID/:nameFactor" element={<SingleFactor />} />
        <Route
          path="secondLevelSingleFactor/:IDSingleFactor/:nameSingleFactor/:IDSecondSingleFactor/:nameSecondSingleFactor"
          element={<SecondLevelSingleFactor />}
        />
        <Route path="settingLevelFactors" element={<SettingLevelFactors />} />
        <Route path="trainingcontrol" element={<TrainingControl />} />
        <Route path="agrupacion" element={<Agrupacion />} />
        <Route path="riskMatrixTabs" element={<RiskMatrixTabs />} />
        <Route path="validateclient" element={<ValidateClient />} />
        {/* <Route path="settingEmployees" element={<SettingEmployees />} /> */}
        <Route path="matrizManagement" element={<MatrizManagement />} />
        <Route path="branchManagement" element={<BranchManagement />} />
        <Route
          path="settingTrainingControl"
          element={<SettingTrainingControl />}
        />
        <Route path="pruebaNiveles" element={<PruebaNiveles />} />
        <Route path="DeparmentManagement" element={<DeparmentManagement />} />
      </Route>
      {/* <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} /> */}
    </Route>
  )
);
