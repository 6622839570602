import { useEffect, useState, lazy } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  IconButton,
  useTheme,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyTooltip from "../../components/utils/FancyTooltip";

import { TrashIcon, PlusIcon } from "../../components/svgicons/SvgIcons";

import { useApp } from "../../hooks/useApp";
import Chart from "react-apexcharts";
import { generatePdf } from "../../utils/PdfGenerator";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { sub } from "date-fns";
import { findDocuments } from "../../services/company/CompanyService";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface DialogProps {
  actionButton?: any;
  data: any;
  compania: any;
  subcompania: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

interface ItemType {
  capacitationTotal?: number;
  total_hours?: number;
  completedHours?: number;
  pendingHours?: number;
  period?: number;
}

/*
  const series = [
    {
      name: 'Horas requeridas',
      data: [50,70],
    },
    {
      name: 'Horas Recibidas',
      data: [40,60],
    },
    {
        name: 'Horas pendientes',
        data: [10,10],
      },
  ];

  // Configuration for the chart
  const options = {
    chart: {
      id: 'line-chart',
    },
    xaxis: {
      categories: ['Finanzas','Contabilidad 2'],
    },
    colors: ['#008FFB', '#8bc8bc' , '#FF4560'], // Assign colors to data series
  };
  */

const options = {
  chart: {
    id: "line-chart",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: [],
  },
  colors: [], // Assign colors to data series
};

const series = [
  {
    name: "Horas requeridas",
    data: [],
  },
  {
    name: "Horas Recibidas",
    data: [],
  },
  {
    name: "Horas pendientes",
    data: [],
  },
];

const DashboardTrainingGeneralControlModal = ({
  actionButton,
  data,
  compania,
  subcompania,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  useEffect(() => {
    handleFetchData();
  }, []);
  const [trainingData, setTrainingData] = useState<ItemType>();
  const [chartseries, setChartseries] = useState<any[]>(series);
  const [chartoptions, setChartoptions] = useState<any>(options);

  const handleFetchData = async () => {
    try {
      console.log(" handleFetchData data=", data);
      let trainingData: ItemType = {};
      let capacitationTotal = data.length;
      let total_hours = 0;
      let completedHours = 0;
      let pendingHours = 0;
      let period = 0;

      let arr_total_hours: any[] = [];
      let arr_completedHours: any[] = [];
      let arr_pendingHours: any[] = [];
      let arr_capacitationName: any[] = [];

      for (let i = 0; i < data.length; i++) {
        const obj = data[i];
        period = obj?.capacitationId?.period;
        total_hours += parseFloat(obj?.capacitationId?.total_hours);
        completedHours += parseFloat(obj?.completedHours);
        pendingHours += parseFloat(obj?.pendingHours);

        arr_total_hours.push(obj?.capacitationId?.total_hours);
        arr_completedHours.push(obj?.completedHours);
        arr_pendingHours.push(obj?.pendingHours);
        arr_capacitationName.push(obj?.capacitationId.name);
      }

      const series = [
        {
          name: "Horas requeridas",
          data: arr_total_hours,
        },
        {
          name: "Horas Recibidas",
          data: arr_completedHours,
        },
        {
          name: "Horas pendientes",
          data: arr_pendingHours,
        },
      ];
      setChartseries(series);

      const options = {
        chart: {
          id: "line-chart",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: arr_capacitationName,
        },
        colors: ["#008FFB", "#8bc8bc", "#FF4560"], // Assign colors to data series
      };
      setChartoptions(options);

      trainingData = {
        capacitationTotal: capacitationTotal,
        total_hours: total_hours,
        completedHours: completedHours,
        pendingHours: pendingHours,
        period: period,
      };
      setTrainingData(trainingData);
    } catch (error: any) {
      console.log("handleFetchData error=", error.message);
    }
  };

  const handleCreatePdf = async () => {
    try {
      const response: any = await findDocuments(compania?.id, subcompania?.id);
      if (response?.length > 0) {
        
        const logoHref = `${
          UrlBase.base_url +
          UrlBase.api_context +
          CONSTANT.DISPATCHER_MAINTENANCE_PREFIX
        }/documents/view/${response[0].id}/?originalName=${
          response[0].systemName
        }`;
        generatePdf({ ...trainingData, companiaLabel: compania.description, subcompaniaLabel: subcompania.description, logoHref }, data);
      }
    } catch (error: any) {
      console.log("handleCreatePdf error=", error.message);
    }
   

  };

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "horas" ? "Agregar horas" : null}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Total de capacitaciones:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {trainingData?.capacitationTotal}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Total de horas requeridas:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {trainingData?.total_hours}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Total de horas recibidas:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {trainingData?.completedHours}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Total de horas pendientes:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {trainingData?.pendingHours}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Período:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {trainingData?.period}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {/* 
                    <Chart
                        options={options}
                        series={covidData.map((data) => data)}
                        type="donut"
                        width="300"
                    />
                    */}
            <Chart
              options={chartoptions}
              series={chartseries}
              type="bar"
              height={350}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleCreatePdf}
        >
          Generar reporte
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default DashboardTrainingGeneralControlModal;
