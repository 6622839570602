import { Alert, Stack, Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";

interface ErrorAlertType {
  message: string;
  handleClick?: string;
  textLink?: string;
}

const LinkAlert = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
}));

const ErrorAlert = ({ message, handleClick, textLink }: ErrorAlertType) => {
  return (
    <Grid container spacing={4} sx={{ pb: 8 }}>
      <Grid item xs={12} sx={{ my: 3 }}>
        <Alert severity="error">
          {message}{" "}
          {handleClick && <LinkAlert to={handleClick}>{textLink}</LinkAlert>}
        </Alert>
      </Grid>
    </Grid>
  );
};

export default ErrorAlert;
