import React, { createContext, useContext, useReducer } from "react";
import {
  IDueDiligenceContext,
  IDueDiligenceProviderProps,
  IDueDiligenceState,
  DueDiligenceActionTypes,
  TClientType,
} from "./types";

const initialState: IDueDiligenceState = {
  //client: "",
  client: {
    id: "",
    company: 0,
    name: "",
    document_number: "",
    type: "",
    code: 0,
    executive: 0,
    sucursal: 0,
    user: "",
    final_result: 0,
    factores: [
      {
        factor_id: "",
        factor_option_id: 0,
        sub_element_id: 0,
      },
    ],
  },
  riskLevel: {
    id: null,
    from: null,
    to: null,
    caption: "",
    color: "",
  },
  clientType: { value: "", label: "" },
  //clientType: { value: "natural", label: "Persona Física"},
};

const DuelDiligentContext = createContext<IDueDiligenceContext | undefined>(
  undefined
);

const reducer = (
  state: IDueDiligenceState,
  action: DueDiligenceActionTypes
): IDueDiligenceState => {
  switch (action.type) {
    case "SET_CLIENT":
      return { ...state, client: action.payload };
    case "SET_RISK_LEVEL":
      return { ...state, riskLevel: action.payload };
    case "TOGGLE_CLIENT_TYPE":
      return { ...state, clientType: action.payload };
    default:
      return state;
  }
};

const DueDiligenceActions = {
  setClient: (payload: string) => ({ type: "SET_CLIENT", payload }),
  setRiskLevel: (payload: string) => ({ type: "SET_RISK_LEVEL", payload }),
  toggleClientType: (payload: TClientType) => ({
    type: "TOGGLE_CLIENT_TYPE",
    payload,
  }),
};

const DuelDiligentProvider = ({ children }: IDueDiligenceProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value: IDueDiligenceContext = { state, dispatch };
  return (
    <DuelDiligentContext.Provider value={value}>
      {children}
    </DuelDiligentContext.Provider>
  );
};

const useDueDiligenceContext = (): IDueDiligenceContext => {
  const context = useContext(DuelDiligentContext);
  if (!context) {
    throw new Error(
      "useDueDiligenceContext must be used within a DuelDiligentProvider"
    );
  }
  return context;
};

export { DuelDiligentProvider, useDueDiligenceContext, DueDiligenceActions };
