import localforage from "localforage";
import jwt_decode from "jwt-decode";
import { SVSessionValidateRequest } from "../services/security/SecurityService";
import { UrlBase } from "../url/Urls";

/**
 * Set Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */
export const setAuthData = async (keyName: string, authData: any) => {
  try {
    await setItem(keyName, authData);
    const value: any = await getItem(keyName);
    return JSON.parse(value);
  } catch (err) {
    return authData;
  }
};

/**
 * Get Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */
export const getAuthData = async () => {
  return await getItem("authMappRiskCompliance");
  /* let authData: any = null;
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionCode = urlParams.get('sc');
    const email = urlParams.get('email');

    //if exists param - validate sc and email
    if (sessionCode && email) {
      //obtener token
      const loginData = await SVSessionValidateRequest(email, sessionCode);

      console.log(loginData)
      //set loginData in localstorage
      authData = await setAuthData('authPRAdminInfo', loginData);
      if (authData) {
        window.location.href = '/';
      }
    } else {
      authData = await getItem('authPRAdminInfo');

      //validate authData
      if (authData && authData?.tokenInfo) {
        const decodeToken: any = jwt_decode(authData.tokenInfo.access_token);
        const expiresAt = decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt;
        if (!valid) {
          authData = null;
        }
      }
    }
  } catch (error) {
    console.log(error);
    authData = null;
    //@ts-ignore
    window.location.replace(UrlBase.app_login_base_url);
  }

  return authData; */
};

/**
 * Agrega un item al store
 *
 * @param key
 * @param value
 */
export const setItem = async (key: string, value: any) => {
  await localforage.setItem(key, value).catch((error) => {
    throw new Error("Error en almacenamiento de información");
  });
};

/**
 * busca un item al store
 *
 * @param key
 */
export const getItem = async (key: any) => {
  return await localforage.getItem(key).catch((error) => {
    throw new Error("Error en recuperacion de información");
  });
};

/**
 * Elimina un item al store
 * @param key
 */
export const removeItem = async (key: any) => {
  await localforage.removeItem(key).catch((error) => {
    throw new Error("Error en eliminar la información");
  });
};

export const getIndexData = async (keyName: string) => {
  return await getItem(keyName);
};
