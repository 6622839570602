import ColorPicker from 'react-pick-color';
import './RangeField.css';
interface ColorCircleProps {
    color: string;
    onColorChange: (newColor: string) => void;
    isPickerOpen: boolean;
    onToggleColorPicker: () => void;
}

const ColorCircle: React.FC<ColorCircleProps> = ({
    color,
    onColorChange,
    isPickerOpen,
    onToggleColorPicker,
}) => {
    const isColorLight = (color: string) => {
        if (!color) {
            return true;
        }
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);

        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 128;
    };

    const textColor = isColorLight(color) ? 'black' : 'white';

    const handleColorChange = (newColor: string) => {
        onColorChange(newColor);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="text-circle-color"
                style={{ backgroundColor: color, color: textColor }}
                onClick={onToggleColorPicker}
            >
                <span>{isPickerOpen ? 'Cerrar' : 'Cambiar'}</span>
            </div>

            {isPickerOpen && (
                <div className="color-picker-container">
                    <ColorPicker
                        theme={{ borderColor: 'darkgrey', borderRadius: '8px' }}
                        color={color}
                        onChange={(color) => handleColorChange(color.hex)}
                    />
                </div>
            )}
        </div>
    );
};

export default ColorCircle;
