import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface DialogProps {
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  factorID?: any;
}

interface schemaFactorOption {
  id?: string;
  name: string;
  impact: number;
  probability: number;
  risk: number;
  actual_legend_risk: string;
  has_sub_elements: boolean;
}

const AddOrUpdateSingleFactorPEPS = ({
  cancelModal,
  modalType,
  onSubmit,
  data,
  factorID,
}: DialogProps) => {
  const defaultValues = {
    id: data?.id ?? 0,
    name: data?.name ? data.name : '',
    impact: data?.impact ? data.impact : 1,
    probability: data?.probability ? data.probability : 1,
    risk: data?.risk ? data?.risk : 0,
    factor_id: data?.factor_id ?? factorID,
    actual_legend_risk: data?.actual_legend_risk ?? 0,
    has_sub_elements: data?.has_sub_elements ?? false,
  };

  const schemaFactorOption = Yup.object().shape({
    name: Yup.string().required('Campo es requerido'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<schemaFactorOption>({
    defaultValues,
    resolver: yupResolver(schemaFactorOption),
    mode: 'onChange',
  });

  return (
    <>
      <DialogTitle>{modalType === 'create' ? 'Agregar ítem' : 'Actualizar ítem'}</DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Grid container spacing={3} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size='small'
                  label='Descripción'
                  type='text'
                  variant='standard'
                  {...register('name')}
                  error={errors?.name && true}
                  helperText={errors?.name?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        {/*  </form> */}
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}>
          Aplicar
        </Button>
        <Button
          variant='contained'
          color='secondary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus>
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default AddOrUpdateSingleFactorPEPS;
