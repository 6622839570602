import { Card, CardContent, Box } from "@mui/material";
import { KycOnePannel } from "../KycOnePannel";
import { KycTwoPannel } from "../KycTwoPannel";
import { KycThreePannel } from "../KycThreePannel";
import { StyledTab, StyledTabs, TabCard } from "./KycTabs.styles";
import { useKycTabs } from "./KycTabs.hooks";
import { KycFourPannel } from "../KycFourPannel";
import { DocumentsPannel } from "../../DocumentsPannel";

export const KycPhysicalPersonTabs = ({ factorsAndOptions }: any) => {
  const { activeTab, handleTabChange } = useKycTabs();
  return (
    <TabCard variant="elevation">
      <CardContent sx={{ pl: 0 }}>
        <Box sx={{ flexGrow: 1, bgcolor: "#fafafa", display: "flex" }}>
          <StyledTabs
            onChange={handleTabChange}
            value={activeTab}
            orientation="vertical"
          >
            <StyledTab label="KYC-1" />
            <StyledTab label="KYC-2" />
            <StyledTab label="KYC-3" />
            <StyledTab label="KYC-4" />
            <StyledTab label="Documentos" />
          </StyledTabs>
          {activeTab === 0 && (
            <KycOnePannel factorsAndOptions={factorsAndOptions} />
          )}
          {activeTab === 1 && (
            <KycTwoPannel factorsAndOptions={factorsAndOptions} />
          )}
          {activeTab === 2 && (
            <KycThreePannel factorsAndOptions={factorsAndOptions} />
          )}
          {activeTab === 3 && (
            <KycFourPannel factorsAndOptions={factorsAndOptions} />
          )}
          {activeTab === 4 && <DocumentsPannel />}
        </Box>
      </CardContent>
    </TabCard>
  );
};
