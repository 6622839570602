import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Box,
  useTheme,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { useData } from "../../hooks/useData";
import { isColorLight } from "../../utils/UtilsFunctions";

interface DialogProps {
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  factorID?: any;
}

interface schemaFactorOption {
  id?: string;
  name: string;
  impact: number;
  probability: number;
  risk: number;
  actual_legend_risk: string;
  has_sub_elements: boolean;
}

interface AddOrUpdateFactorOptionType {
  newFactorOption: schemaFactorOption[];
}

const AddOrUpdateSingleFactor = ({
  cancelModal,
  modalType,
  onSubmit,
  data,
  factorID,
}: DialogProps) => {
  const theme = useTheme();
  const { riskData, handleFetchRiskLevels } = useApp();
  const { companyIndexDB } = useData();
  const [riskColors, setRiskColors] = useState("#fff");
  const [textColor, setTextColor] = useState("white");
  const [currentRiskLevel, setCurrentRiskLevel] = useState("");

  console.log(data)

  const inherentRisk =
    riskData !== null && riskData?.find((item: any) => item.type === "1");

  const valueMax =
    inherentRisk?.methodology === "1"
      ? 3
      : inherentRisk?.methodology === "2"
      ? 4
      : 5;

  const schemaFactorOption = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    impact: Yup.number()
      .required("Campo es requerido")
      .typeError("Campo es requerido")
      .max(valueMax, `Valor no puede ser mayor a ${valueMax}`),
    probability: Yup.number()
      .required("Campo es requerido")
      .typeError("Campo es requerido")
      .max(valueMax, `Valor no puede ser mayor a ${valueMax}`),
    risk: Yup.number(),
    factor_id: Yup.number(),
    actual_legend_risk: Yup.number(),
    has_sub_elements: Yup.boolean(),
  });

  /*  const validation = Yup.object().shape({
    newFactorOption: Yup.array().of(Yup.object().shape(schemaFactorOption)),
  }); */

  const defaultValues = {
    id: data?.id ?? 0,
    name: data?.name ? data.name : "",
    impact: data?.impact ? data.impact : 1,
    probability: data?.probability ? data.probability : 1,
    risk: data?.risk ? data?.risk : 0,
    factor_id: data?.factor_id ?? factorID,
    actual_legend_risk: data?.actual_legend_risk ?? 0,
    has_sub_elements: data?.has_sub_elements ?? false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    resetField,
    setValue,
  } = useForm<schemaFactorOption>({
    defaultValues,
    resolver: yupResolver(schemaFactorOption),
    mode: "onChange",
  });
  const watch_impact = watch("impact");
  const watch_probability = watch("probability");
  const watch_risk = watch("risk");

  const compareRanges = (value: number) => {
    const matches = inherentRisk?.details?.filter((element: any) => {
      return element.from <= value && value <= element.to;
    });
    /* const matches = riskData[0]?.details?.filter((element: any) => {
      return element.from <= value && value <= element.to;
    }); */
    if (matches?.length > 0) {
      setValue("actual_legend_risk", matches[0].id);
      setCurrentRiskLevel(`${matches[0].caption}`);
      setRiskColors(`${matches[0].color}`);
      setTextColor(isColorLight(matches[0].color) ? "black" : "white");
    } else {
      setValue("actual_legend_risk", inherentRisk.details[0].id);
      setCurrentRiskLevel("");
      setRiskColors("#FFFFFF");
    }
  };

  useEffect(() => {
    if (riskData?.length < 1) {
      handleFetchRiskLevels &&
        handleFetchRiskLevels(
          companyIndexDB?.company.id,
          companyIndexDB?.subCompany.id
        );
    }
  }, []);

  useEffect(() => {
    let result = Number(watch_probability) * Number(watch_impact);
    result <= Number(inherentRisk?.details[inherentRisk?.details.length - 1].to)
      ? setValue("risk", result)
      : setValue(
          "risk",
          inherentRisk?.details[inherentRisk?.details.length - 1].to
        );
    compareRanges(result);
  }, [watch_impact, watch_probability]);

  return (
    <>
      <DialogTitle>
        {modalType === "create" ? "Agregar ítem" : "Actualizar ítem"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Descripción"
                  type="text"
                  variant="standard"
                  {...register("name")}
                  error={errors?.name && true}
                  helperText={errors?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="impact"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Impactos"
                  type="number"
                  variant="standard"
                  {...register("impact")}
                  onKeyDown={(e) => {
                    if (value.toString().length <= 1 || e.key === "Backspace") {
                      return true;
                    } else {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 1,
                    min: 1,
                    max: valueMax,
                  }}
                  error={errors?.impact && true}
                  helperText={errors?.impact?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="probability"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Probabilidad"
                  type="number"
                  variant="standard"
                  {...register("probability")}
                  onKeyDown={(e) => {
                    if (value.toString().length <= 1 || e.key === "Backspace") {
                      return true;
                    } else {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 1,
                    min: 1,
                    max: valueMax,
                  }}
                  error={errors?.probability && true}
                  helperText={errors?.probability?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="risk"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Riesgo inherente"
                  type="number"
                  variant="standard"
                  disabled
                  {...register("risk")}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 0,
                    max: 10,
                  }}
                  error={errors?.risk && true}
                  helperText={errors?.risk?.message}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Controller
              name="actual_legend_risk"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Box
                    component="span"
                    sx={{
                      bgcolor: riskColors,
                      fontSize: "0.75rem",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "70px",
                      height: "70px",
                      color: textColor,
                      borderRadius: "25rem",
                    }}
                  >
                    {currentRiskLevel}
                  </Box>
                  <Typography variant="body1" color="mapprisk">
                    Leyenda de Riesgo
                  </Typography>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="has_sub_elements"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch checked={value} {...register("has_sub_elements")} />
                  }
                  label="¿Requiere sub elementos?"
                />
              )}
            />
          </Grid>
        </Grid>
        {/*  </form> */}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aplicar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default AddOrUpdateSingleFactor;
