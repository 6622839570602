import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  type: string;
  matrizData?: any;
  numberItems: number;
}

interface AddOrUpdateFactorType {
  newFactor: {
    name: string;
  }[];
}

const AddOrUpdateFactor = ({
  actionButton,
  cancelModal,
  modalType,
  type,
  onSubmit,
  data,
  matrizData,
  numberItems,
}: DialogProps) => {
  const schema_options = {
    name: Yup.string().required("Campo es requerido"),
    matrix_group: Yup.number().required("Campo es requerido"),
  };

  const validation = Yup.object().shape({
    newFactor: Yup.array().of(Yup.object().shape(schema_options)),
  });

  const defaultValues: AddOrUpdateFactorType = {
    newFactor: data
      ? [data]
      : [
          {
            id: 0,
            name: "",
            company: 0,
            editable: true,
            high_risk: false,
            matrix_group: 0,
            percentage: 0,
            sub_company: 0,
            type: "",
          },
        ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateFactorType>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const {
    fields: newFactorFields,
    append: newFactorAppend,
    remove: newFactorRemove,
  } = useFieldArray({
    control,
    name: "newFactor",
    rules: {
      minLength: 1,
      maxLength: 15 - numberItems,
    },
  });

  return (
    <>
      <DialogTitle>
        {modalType === "create" ? "Agregar factor" : "Actualizar factor"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {newFactorFields.map((field, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex" }}
                  key={field.id}
                  gap={6}
                >
                  <Grid item xs={6}>
                    <Controller
                      name={`newFactor.${index}.name`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Nombre de factor"
                          type="text"
                          variant="standard"
                          {...register(`newFactor.${index}.name`)}
                          error={errors?.newFactor?.[index]?.name && true}
                          helperText={errors?.newFactor?.[index]?.name?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={index === 0 ? 5 : 4}>
                    <Controller
                      control={control}
                      // @ts-ignore
                      name={`newFactor.${index}.matrix_group`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <FormControl variant="standard" fullWidth>
                          <InputLabel htmlFor="sucursal-select">
                            Agrupación
                          </InputLabel>
                          <Select
                            fullWidth
                            label=""
                            value={value}
                            onChange={onChange}
                          >
                            {matrizData.content.map((item: any) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {index > 0 && (
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => {
                        newFactorRemove(index);
                      }}
                    >
                      <RemoveCircleIcon fontSize="medium" />
                    </IconButton>
                  )}
                </Grid>
              );
            })}
            {modalType === "create" && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {numberItems + newFactorFields?.length + 1 <= 15 ? (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      newFactorAppend({
                        name: "",
                      });
                      console.log(newFactorFields?.length + 1);
                    }}
                  >
                    <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
                    factor
                  </Button>
                ) : (
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{ marginRight: "auto" }}
                  >
                    *Número máximo de factores a agregar es {15 - numberItems}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aplicar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default AddOrUpdateFactor;
