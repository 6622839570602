import React, { useEffect, useState } from "react";

import { RiskLevel } from "./RiskLevelsModel";
import { getInitialValues } from "../../utils/RiskLevelsUtils";
import RangeField from "../../components/utils/risk_levels/RangeFields";
import MethodologySelector from "../../components/utils/risk_levels/MethodologySelector";
import { Typography, Stack } from "@mui/material";

interface RangeFieldData {
  fromValue: number;
  toValue: number;
  color: any;
}

interface RiskLevelsContainerProps {
  data: RiskLevel | undefined;
  type: string;
  companyId: string;
  subcompanyId: string;
  onModifiedDataChange: (modifiedData: RiskLevel) => void;
}

const RiskLevelsContainer: React.FC<RiskLevelsContainerProps> = ({
  data,
  type,
  companyId,
  subcompanyId,
  onModifiedDataChange,
}) => {
  const [selectedMethodology, setSelectedMethodology] = useState<string>(
    data ? data.methodology : "1"
  );
  const [openedColorPickerIndex, setOpenedColorPickerIndex] = useState<
    number | null
  >(null);
  const [colors, setColors] = useState<string[]>([]);
  const [legends, setLegends] = useState<string[]>([]);
  const [rangeFields, setRangeFields] = useState<RangeFieldData[]>([]);
  const [multiplication_factor, setMultiplication_factor] = useState<number>(
    data ? data.factor : 11.1
  );
  const handleColorPickerToggle = (index: number) => {
    if (openedColorPickerIndex === index) {
      setOpenedColorPickerIndex(null);
    } else {
      setOpenedColorPickerIndex(index);
    }
  };

  let dataToSend: RiskLevel | null = null;

  /* let dataToSend: RiskLevel = {
    id: data?.id ?? 0,
    company: companyId,
    subcompany: subcompanyId,
    type: type,
    methodology: selectedMethodology,
    details: rangeFields.map((rangeField, index) => ({
      from: rangeField.fromValue,
      to: rangeField.toValue,
      color: colors[index],
      caption: legends[index],
    })),
  }; */

  useEffect(() => {
    handleDataToSend(rangeFields);

    dataToSend !== null && onModifiedDataChange(dataToSend);
  }, [rangeFields, multiplication_factor]);

  useEffect(() => {
    const initialValues = getInitialValues(
      selectedMethodology,
      type,
      companyId,
      subcompanyId
    );
    const count = data ? parseInt(data.methodology) : 3;
    const defaultColors = calculateColors(count);

    if (!data && initialValues) {
      setLegends(initialValues.details.map((item) => item.caption));
      setColors(defaultColors);
      setMultiplication_factor(initialValues.factor);

      setRangeFields(
        initialValues.details.map((item, index) => ({
          fromValue: item.from,
          toValue: item.to,
          color: item.color || defaultColors[index],
        }))
      );
    }

    if (data) {
      setSelectedMethodology(data.methodology);
      const initialFromValues = data.details.map((item) => item.from);
      const initialToValues = data.details.map((item) => item.to);
      const initialLegends = data.details.map((item) => item.caption);
      //const multiFactor = changeMultiplicationFactor(data.type);
      const multiFactor = data.factor;

      setLegends(initialLegends);
      setMultiplication_factor(multiFactor);

      setRangeFields(
        initialFromValues.map((_, index) => ({
          fromValue: initialFromValues[index],
          toValue: initialToValues[index],
          color: data.details[index].color || defaultColors[index],
        }))
      );
    }
    dataToSend !== null && onModifiedDataChange(dataToSend);
  }, [data, companyId, subcompanyId]);

  function calculateColors(count: number): string[] {
    const greenColor = "#008000";
    const yellowColor = "#FFD700";
    const orangeColor = "#FF8C00";
    const orangeBColor = "#FF4500";
    const redColor = "#DC143C";

    const colors = [greenColor];

    if (count === 3) {
      colors.push(yellowColor, redColor);
    } else if (count === 4) {
      colors.push(yellowColor, orangeColor, redColor);
    } else if (count === 5) {
      colors.push(yellowColor, orangeColor, orangeBColor, redColor);
    } else {
      colors.push(yellowColor, redColor);
    }

    return colors;
  }

  useEffect(() => {
    //console.log(rangeFields);

    if (data) {
      //console.log(data?.details.length);
      const count = data.details.length;
      const newColors = calculateColors(count);
      setColors(newColors);
    }
  }, [data]);

  const changeMultiplicationFactor = (methodoly: string) => {
    let multi_factor;
    switch (methodoly) {
      case "1":
        multi_factor = 11.1;
        break;
      case "2":
        multi_factor = 6.25;
        break;
      case "3":
        multi_factor = 4;
        break;
      default:
        return null;
    }
    return multi_factor;
  };

  const handleMethodologyChange = (newMethodologyId: string) => {
    setSelectedMethodology(newMethodologyId);
    console.log("1");

    let count;

    switch (newMethodologyId) {
      case "1":
        count = 3;
        break;
      case "2":
        count = 4;
        break;
      case "3":
        count = 5;
        break;
      default:
        count = 3;
    }

    const multiFactor = changeMultiplicationFactor(newMethodologyId);
    multiFactor && setMultiplication_factor(multiFactor);

    const defaultColors = calculateColors(count);

    setColors(defaultColors);

    const initialValues = getInitialValues(
      newMethodologyId,
      type,
      companyId,
      subcompanyId
    );

    if (!initialValues) {
      return;
    }

    setLegends(initialValues.details.map((item) => item.caption));

    const newRangeFields = initialValues.details.map((item, index) => ({
      fromValue: item.from,
      toValue: item.to,
      color: item.color || defaultColors[index],
    }));

    setRangeFields(newRangeFields);
    dataToSend !== null && onModifiedDataChange(dataToSend);
    console.log(dataToSend);
  };

  const handleLegendChange = (index: number, newValue: string) => {
    const newLegends = [...legends];
    newLegends[index] = newValue;
    setLegends(newLegends);
    dataToSend !== null && onModifiedDataChange(dataToSend);
  };

  const handleColorChange = (index: number, newColor: string) => {
    const newColors = [...colors];
    newColors[index] = newColor;
    setColors(newColors);
    dataToSend !== null && onModifiedDataChange(dataToSend);
  };

  const handleRangeFieldChange = (
    index: number,
    newFromValue: number,
    newToValue: number
  ) => {
    const initialValues = getInitialValues(
      selectedMethodology,
      type,
      companyId,
      subcompanyId
    );

    if (newToValue <= newFromValue) {
      newToValue = newFromValue + 1;
    }

    const newRangeFields = [...rangeFields];
    const diffTo = newToValue - rangeFields[index].toValue;

    newRangeFields[index] = {
      fromValue: newFromValue,
      toValue: newToValue,
      color: rangeFields[index].color,
    };

    for (let i = index + 1; i < newRangeFields.length; i++) {
      const item =
        data !== null || data !== false
          ? data?.details[i]
          : initialValues?.details[i];

      const colorToUse = item
        ? item.color
        : calculateColors(newRangeFields.length)[i];

      newRangeFields[i] = {
        fromValue: newRangeFields[i - 1].toValue + 1,
        toValue: newRangeFields[i - 1].toValue + 1 + diffTo,
        color: colorToUse,
      };

      if (newRangeFields[i].toValue <= newRangeFields[i].fromValue) {
        newRangeFields[i].toValue = newRangeFields[i].fromValue + 1;
      }
    }

    //console.log(dataToSend);

    setRangeFields(newRangeFields);
    //dataToSend !== null && onModifiedDataChange(dataToSend);
  };

  const handleDataToSend = (arrayData: RangeFieldData[]) => {
    dataToSend = {
      id: data?.id ?? 0,
      company: companyId,
      subcompany: subcompanyId,
      type: type,
      factor: multiplication_factor,
      methodology: selectedMethodology,
      details: arrayData.map((rangeField, index) => ({
        from: rangeField.fromValue,
        to: rangeField.toValue,
        color: colors[index],
        caption: legends[index],
      })),
    };
  };

  useEffect(() => {
    dataToSend !== null && onModifiedDataChange(dataToSend);
  }, []);

  return (
    <div>
      <Stack
        flexDirection={{ sx: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography color="inherit" noWrap component="div" variant="h5">
          {type === "1" ? "Riesgo Inherente" : "Riesgo LAFT"}
        </Typography>
        <Typography color="inherit" noWrap component="div" variant="body1">
          <strong>Factor de multiplicación:</strong> {multiplication_factor}
        </Typography>
      </Stack>

      <MethodologySelector
        selectedMethodology={selectedMethodology}
        type={type}
        onMethodologyChange={(newMethodology) => {
          setSelectedMethodology(newMethodology);
          handleMethodologyChange(newMethodology);
        }}
      />

      <div>
        {rangeFields.map((rangeField, index) => {
          return (
            <div key={index}>
              <RangeField
                legend={legends[index]}
                fromValue={rangeField.fromValue}
                toValue={rangeField.toValue}
                onLegendChange={(newValue: string) =>
                  handleLegendChange(index, newValue)
                }
                onFromValueChange={(newValue: number) =>
                  handleRangeFieldChange(index, newValue, rangeField.toValue)
                }
                onToValueChange={(newValue: number) =>
                  handleRangeFieldChange(index, rangeField.fromValue, newValue)
                }
                color={colors[index]}
                onColorChange={(newColor: string) =>
                  handleColorChange(index, newColor)
                }
                isColorPickerOpen={openedColorPickerIndex === index}
                onColorPickerToggle={() => handleColorPickerToggle(index)}
                type={type}
                isLast={index === rangeFields.length - 1}
                isLastMinusOne={index === rangeFields.length - 2}
                isLastMinusTwo={index === rangeFields.length - 3}
                isLastMinusThree={index === rangeFields.length - 4}
                isLastMinusFour={index === rangeFields.length - 5}
                rangeFields={rangeFields.length}
                isIndex={index}
                selectedMethodology={selectedMethodology}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RiskLevelsContainer;
