import { useEffect, useState } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  IconButton,
  useTheme,
  DialogContentText,
  Autocomplete,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyTooltip from "../../components/utils/FancyTooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TrashIcon, PlusIcon } from "../../components/svgicons/SvgIcons";

import { useApp } from "../../hooks/useApp";
import { useData } from "../../hooks/useData";
import InputLabelToForm from "../InputLabelToForm";
import { CONSTANT } from "../../utils/Constants";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));
const Text = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.mappRisk.light,
  fontFamily: "D-dinExp",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "17px",
  padding: theme.spacing(4),
}));
const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateTraining {
  id?: number;
  name: string;
  method: string;
  instructor: string;
  date: Date;
  period: string;
  total_hours: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateSettingsEmployeesTrainingModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { companyIndexDB } = useData();
  const [companiaLabel, setCompaniaLabel] = useState("");
  const [subcompaniaLabel, setSubcompaniaLabel] = useState("");

  let dateData;
  if (data.id !== 0) {
    dateData = data.date;
    const [dia, mes, anio] = dateData.split("/").map(Number);
    dateData = new Date(anio, mes - 1, dia);
  }

  useEffect(() => {
    handleFetchData();
  }, []);
  const handleFetchData = async () => {
    try {
      //console.log("AddOrUpdateSettingsEmployeesTrainingModal handleFetchData companyIndexDB=",companyIndexDB);
      console.log(
        "AddOrUpdateSettingsEmployeesTrainingModal handleFetchData data=",
        data
      );
      if (companyIndexDB.company)
        setCompaniaLabel(companyIndexDB.company.description);
      if (companyIndexDB.subCompany)
        setSubcompaniaLabel(companyIndexDB.subCompany.description);
    } catch (error: any) {
      console.log("handleFetchData error=", error.message);
    }
  };

  const setPeriod = (dateSel: any) => {
    try {
      console.log("date=", dateSel);
      const currentDate = new Date(dateSel);
      const currentYear = currentDate.getFullYear();
      console.log("currentYear=", currentYear);
      resetField("period", {
        defaultValue: currentYear,
      });
    } catch (error: any) {
      console.log("setPeriod error=", error.message);
    }
  };
  const onSubmit2 = (data: any) => {
    try {
      let bActive = true;
      data.company = parseInt(companyIndexDB.company.id);

      let subcompany = null;
      if (companyIndexDB.subCompany) {
        subcompany = parseInt(companyIndexDB.subCompany.id);
      }
      data.subcompany = subcompany;
      //console.log("onSubmit2 companyIndexDB=",companyIndexDB);

      const date = data.date;
      const currentDate = new Date(date);
      const day = String(currentDate.getDate()).padStart(2, "0"); // Zero-padding the day
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month as it's zero-based
      const year = currentDate.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      data.date = formattedDate;
      //console.log("onSubmit2 data=",data);

      //bActive=false;
      if (bActive) onSubmit(data);
    } catch (error: any) {
      console.log("setPeriod error=", error.message);
    }
  };

  //Validación de datos
  /*
    const formSchemaOptionsMethod = {
        id: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
        letter: Yup.string().required("Required"),
    };
    */

  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    instructor: Yup.string().required("Campo es requerido"),
    method: Yup.string().required("Campo es requerido"),
    total_hours: Yup.number()
      .required("Campo es requerido")
      .min(1, "El número debe ser mayor o igual a 1")
      .typeError("Por favor ingresa un número o decimal válido")
      .required("Campo es requerido"),
    date: Yup.string().required("Campo es requerido"),
    period: Yup.string().required("Campo es requerido"),
  });

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const defaultValues = {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : "",
    method: data.method ? data.method : "",
    instructor: data.instructor ? data.instructor : "",
    date: data.date ? dateData : new Date(),
    period: data.period ? data.period : currentYear,
    total_hours: data.total_hours ? data.total_hours : "",
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateTraining>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? properties.com_mapprisk_label_settingcontroltrainnong_button_update_title
          : properties.com_mapprisk_label_settingcontroltrainnong_button_add_title}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {/*
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            fullWidth
                            label="Compañía seleccionada"
                            size="small"
                            type="text"
                            variant="standard"
                            value={companiaLabel}
                            sx={{ "& input": { pl: "0!important" } }}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            fullWidth
                            label="Sub Compañía seleccionada"
                            size="small"
                            type="text"
                            variant="standard"
                            value={subcompaniaLabel}
                            sx={{ "& input": { pl: "0!important" } }}
                            disabled
                        />
                    </Grid>
                    */}

            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>

            <Grid item sm={12} xs={12} sx={{ marginTop: "16px" }}>
              <Controller
                name={"method"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <InputLabelToForm style={{ top: 10 }}>
                      Modalidad
                    </InputLabelToForm>
                    <Select
                      // disabled
                      variant="standard"
                      value={value}
                      sx={{ "& select": { pl: "0!important" } }}
                      {...register("method")}
                    >
                      <MenuItem value="Online">Online</MenuItem>
                      <MenuItem value="Presencial">Presencial</MenuItem>
                    </Select>
                    <FormHelperText error={true}>
                      {errors.method?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name={"instructor"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Instructor"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("instructor")}
                    error={errors.instructor && Boolean(errors.instructor)}
                    helperText={errors.instructor && errors.instructor.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"total_hours"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Duración en horas"
                    type="number"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("total_hours")}
                    error={errors.total_hours && Boolean(errors.total_hours)}
                    helperText={
                      errors.total_hours && errors.total_hours.message
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "16px" }}>
              <Controller
                name={"date"}
                control={control}
                defaultValue={new Date()}
                render={({ field, ...props }) => (
                  <FormControl fullWidth>
                    <InputLabelToForm
                      error={errors.date && Boolean(errors.date)}
                    >
                      Fecha
                    </InputLabelToForm>
                    <DatePicker
                      locale="es"
                      selected={field.value}
                      minDate={new Date()}
                      onChange={(date) => {
                        field.onChange(date);
                        setPeriod(date);
                      }}
                      dateFormat={"dd/MM/yyyy"}
                    />
                  </FormControl>
                )}
                {...(errors.date && (
                  <FormHelperText>{errors.date.message}</FormHelperText>
                ))}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"period"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    label="Período"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("period")}
                    error={errors.period && Boolean(errors.instructor)}
                    helperText={errors.period && errors.period.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit2)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateSettingsEmployeesTrainingModal;
