import { IconButton, useTheme } from "@mui/material";
import FancyTooltip from "../FancyTooltip";
import { TrashIcon } from "../../svgicons/SvgIcons";

interface deleteButton {
  title?: string;
  dataID: string;
  dataName?: string;
  handleOpenModal: any;
  disabled?: boolean;
}
const DeleteButton = ({
  title,
  dataID,
  dataName,
  handleOpenModal,
  disabled,
}: deleteButton) => {
  const theme = useTheme();
  return (
    <FancyTooltip title={title ?? "Eliminar"} placement="top">
      <IconButton
        aria-label="trash"
        component="label"
        color="error"
        disabled={disabled ?? false}
        sx={{
          "&:hover": {
            color: theme.palette.secondary.dark,
          },
        }}
        data-id={dataID}
        data-name-element={dataName}
        onClick={handleOpenModal}
        data-name="delete"
      >
        <TrashIcon />
      </IconButton>
    </FancyTooltip>
  );
};

export default DeleteButton;
