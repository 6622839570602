import { useEffect, useState, lazy } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  IconButton,
  useTheme,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyTooltip from "../../components/utils/FancyTooltip";

import { TrashIcon, PlusIcon } from "../../components/svgicons/SvgIcons";

import { useApp } from "../../hooks/useApp";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateTraining {
  id?: number;
  completedHours?: number;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

interface ItemType {
  id?: number;
  employeeId?: {
    id?: number;
    name?: string;
    last_name?: string;
    phone?: string;
    email?: string;
    state?: string;
  };
  capacitationId?: {
    id?: number;
    name?: string;
    method?: string;
    instructor?: string;
    total_hours?: number;
    date?: string;
    period?: string;
  };
  completedHours?: number;
  pendingHours?: number;
}

const AddTrainingControlModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const [employeename, setEmployeename] = useState("");
  const [capacitacionDataitem, setCapacitacionDataitem] = useState<ItemType>();

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleFetchData = async () => {
    try {
      //console.log(" handleFetchData data=", data);
      setCapacitacionDataitem(data);
      const name = `${data.employeeId.firstName} ${data.employeeId.lastName}`;
      setEmployeename(name);
    } catch (error: any) {
      console.log("handleFetchData error=", error.message);
    }
  };

  const onSubmit2 = (data: any) => {
    try {
      let bActive = true;
      //console.log("onSubmit2 data=", data);
      //console.log("onSubmit2 capacitacionDataitem=", capacitacionDataitem);

      const updateItem = {
        id: data.id,
        employeeId: {
          id: capacitacionDataitem?.employeeId?.id,
        },
        capacitationId: {
          id: capacitacionDataitem?.capacitationId?.id,
        },
        completedHours: data.completedHours,
        urlCertificateStorage: "",
      };

      //console.log("onSubmit2 updateItem=", updateItem);

      //bActive=false;
      if (bActive) onSubmit(updateItem);
    } catch (error: any) {
      console.log("setPeriod error=", error.message);
    }
  };

  //Validación de datos
  const validation = Yup.object().shape({
    completedHours: Yup.number()
      .required("Campo es requerido")
      .min(1, "El número debe ser mayor o igual a 1")
      .typeError("Por favor ingresa un número o decimal válido")
      .required("Campo es requerido"),
  });
  const defaultValues = {
    id: data.id ? data.id : "",
    completedHours: data.completedHours ? data.completedHours : "",
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateTraining>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        Agregar horas
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Empleado:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {employeename}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Nombre:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Modalidad:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.method}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Instructor:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.instructor}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Duración en horas:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.total_hours}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Fecha:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.date}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Período:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.period}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name={"completedHours"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Total de horas aplicadas"
                  type="number"
                  variant="standard"
                  value={value}
                  sx={{ "& input": { pl: "0!important" } }}
                  {...register("completedHours")}
                  error={
                    errors.completedHours && Boolean(errors.completedHours)
                  }
                  helperText={
                    errors.completedHours && errors.completedHours.message
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit2)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddTrainingControlModal;
