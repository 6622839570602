import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material";
import DeleteButton from "./utils/iconButtons/DeleteButton";
import UpdateButton from "./utils/iconButtons/UpdateButton";
import AddButton from "./utils/iconButtons/AddButton";
import FancyTablePagination from "./utils/FancyTablePagination";

const FactorTables = ({
  data,
  handleOpenModal,
  handleChangePage,
  page,
}: any) => {
  const navigate = useNavigate();

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell align="center">Elementos</TableCell>
              <TableCell align="center">Peso factor</TableCell>
              <TableCell align="center">Alto riesgo</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data !== null && data?.content?.length > 0 ? (
              data.content.map((item: any, i: number) => (
                <TableRow key={i}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="center">{item.elements}</TableCell>
                  <TableCell align="center">{item.percentage}%</TableCell>
                  <TableCell align="center">
                    {item.high_risk ? "Sí" : "No"}
                  </TableCell>
                  <TableCell align="center">
                    <AddButton
                      dataID={item.id}
                      dataName={item.name}
                      //handleOpenModal={handleOpenModal}
                      handleOpenModal={() =>
                        navigate(
                          `/dashboard/singleFactor/${item.id}/${item.name}`
                        )
                      }
                    />
                    <UpdateButton
                      dataID={item.id}
                      dataName={item.name}
                      handleOpenModal={handleOpenModal}
                      disabled={!item.editable}
                    />
                    <DeleteButton
                      dataID={item.id}
                      dataName={item.name}
                      handleOpenModal={handleOpenModal}
                      disabled={!item.editable}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  No hay elementos
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FancyTablePagination
        count={data?.content?.length > 0 ? data?.content?.length : 0}
        rowsPerPage={data?.size}
        page={page}
        onPageChange={handleChangePage}
        totalElements={data?.totalElements}
        totalPages={data?.totalPages}
        numberOfElements={data?.numberOfElements ?? 0}
      />
    </>
  );
};

export default FactorTables;
