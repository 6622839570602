import { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import FancyPaper from "../../components/FancyPaper";
import { FiltersPannel } from "./components/FiltersPannel";
import { KycPhysicalPersonTabs } from "./components/PhysicalPerson/KycPhysicalPersonTabs";
import { KycTabsLegalPerson } from "./components/LegalPerson/KycTabsLegalPerson";
import { useDueDiligenceContext } from "./provider/DuediligentProvider";
import { useApp } from "../../hooks/useApp";
import { useData } from "../../hooks/useData";
import ErrorAlert from "../../components/utils/ErrorAlert";
import { getSingleFactors } from "../../services/factors/FactorsService";
import { getSubElements } from "../../services/subelements/SubElementsService";
import { getProfileMatriz } from "../../services/profile/ProfileService";
import { customerMatriz } from "./provider/types";

/* type customerMatriz = {
  id: number;
  company: number;
  name: string;
  type: string;
  code: number;
  executive: number;
  sucursal: number;
  user: string | null;
  factores: {
    factor_id: number;
    factor_option_id: number;
    sub_element_id: number | null;
  }[];
}; */

export const DueDiligence = () => {
  const { state } = useDueDiligenceContext();
  const { setLoading } = useApp();
  const { companyIndexDB, subCompanyData, handleFetchFactors, factors } =
    useData();
  const location = useLocation();
  const [factorsAndOptions, setFactorsAndOptions] = useState<any>(null);
  const [customerMatriz, setcustomerMatriz] = useState<customerMatriz[] | null>(
    null
  );

  const getTitle = () => {
    const title = location.pathname.split("/").pop() || "";
    const titleMap = {
      simplified: "Debida diligencia simplificada",
      normal: "Debida diligencia normal",
      extended: "Debida diligencia extendida",
    };
    return titleMap[title as keyof typeof titleMap];
  };

  useEffect(() => {
    const getData = async () => {
      if (subCompanyData !== null && companyIndexDB?.subCompany?.id) {
        handleFetchFactors &&
          handleFetchFactors(
            0,
            companyIndexDB?.company?.id,
            companyIndexDB?.subCompany?.id,
            100
          );
        await handleClients();
        // setShowInitModal(true);
      } else if (
        subCompanyData !== null &&
        companyIndexDB?.subCompany === null
      ) {
        handleFetchFactors &&
          handleFetchFactors(0, companyIndexDB?.company?.id, "", 100);
        //await handleClients();
      } else {
        return;
      }
      // await cleanDataFactor();
    };
    getData();
    // @ts-ignore
  }, [companyIndexDB, subCompanyData]);

  useEffect(() => {
    const getDataFactor = async () => {
      await cleanDataFactor();
    };
    //if (!!state) {
    if (state.clientType.value.length > 0) {
      getDataFactor();
    }
  }, [state]);

  const handleClients = async () => {
    try {
      const response = await getProfileMatriz(
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id,
        ""
      );
      if (response.content.length > 0) {
        setcustomerMatriz(response.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const factorOptions = async (id: string) => {
    try {
      const opciones = await getSingleFactors(id, 0, "", 1000);
      return opciones;
    } catch (error) {
      console.error("Error en factorOptions:", error);
    }
  };

  const cleanDataFactor = async () => {
    setLoading && setLoading(true);

    try {
      const dataJ = factors?.dataLegal?.content || [];
      const dataF = factors?.dataPhysical?.content || [];
      const dataMerge =
        state.clientType.value === "J" ? [...dataJ] : [...dataF];

      const factorsWithOptions = [];
      const dataMergeWithOptions: any = [];

      //if (matrixGroups === null) return;

      // Usar Promise.all para manejar múltiples llamadas asíncronas
      const factorsWithOptionsForGroup = await Promise.all(
        dataMerge.map(async (factor: any) => {
          const options: any = await factorOptions(factor.id);
          const optionsWithSubElements = await Promise.all(
            options!!.content!!.map(async (option: any) => {
              // console.log('option', option);
              let subElementos = null;

              if (option.has_sub_elements) {
                // console.log('option', option);
                const subElements: any = await getSubElements(0, option.id);
                subElementos = subElements.content.map((subElement: any) => {
                  return {
                    id: subElement.id,
                    name: subElement.name,
                  };
                });
              }

              return {
                id: option.id,
                description: option.name,
                impact: option.impact,
                risk: option.risk,
                probability: option.probability,
                subElements: subElementos,
              };
            })
          );

          return {
            ...factor,
            options: optionsWithSubElements,
          };
        })
      );

      factorsWithOptions.push(...factorsWithOptionsForGroup);
      setFactorsAndOptions([...factorsWithOptions]);

      setLoading && setLoading(false);
    } catch (error) {
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  return (
    <FancyPaper pagetitle={getTitle()}>
      {factors === null || factors.length === 0 ? (
        <ErrorAlert
          message={
            !companyIndexDB?.company?.description
              ? "Debe seleccionar primero compañía y subcompañía"
              : "La compañía tiene subcompañías asociadas, debe seleccionar una de ellas"
          }
        />
      ) : (
        <Grid container>
          <Grid item md={12} sm={12} xs={12} sx={{ mt: 5 }}>
            <Box display="flex">
              <FiltersPannel customerMatriz={customerMatriz} />
            </Box>
          </Grid>
          <Grid item md={12} sm={12} xs={12} sx={{ mt: 5 }}>
            <Box display="flex">
              {state.clientType.value === "J" && <KycTabsLegalPerson />}
              {state.clientType.value === "F" && (
                <KycPhysicalPersonTabs factorsAndOptions={factorsAndOptions} />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </FancyPaper>
  );
};
