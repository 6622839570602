import { URL } from "../../utils/UrlConstants";
import { UrlBase } from "../../url/Urls";
import { privateFetch, privateFetchScreening } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { getItem } from "../../utils/LocalStorageManager";

export const createRequestCompanyScreening = async (data: any) => {
  let responseData = {};  
  await privateFetch
    .post(URL.REQUEST_COMPANY_SCREENING, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const getCompanyScreening = async () => {

  let responseData = {};
  await privateFetch
    .get(UrlBase.base_url_screening + UrlBase.api_context + URL.LIST_COMPANY_SCREENING)
   /*  .get(
      "https://mapprisk-client-screening-dispatcher.bitcode-enterprise.dev/v1/maintenance/company/companies",
      {}
    ) */
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });

  return responseData as { companies: any[]; subCompanies: any[] };
};

export const getCompanyScreeningRelation = async (id:string) => {

  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_COMPANY_SCREENING_REL(id))
   
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });

  return responseData as { companies: any[]; subCompanies: any[] };
};

