import React, { useState, useEffect } from "react";

const PruebaNiveles = () => {
  const [data, setData] = useState([
    { from: 1, to: 2 },
    { from: 3, to: 4 },
    { from: 5, to: 6 },
    { from: 7, to: 8 },
    { from: 9, to: 15 },
  ]);

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    const updatedData = data.map((item) => {
      if (name === "to" && item.level < data.length - 1) {
        // El valor del input to no puede superar o igualar al valor del siguiente nivel de input from
        const nextLevelFrom = data[item.level + 1].from;
        if (value >= nextLevelFrom) {
          value = nextLevelFrom - 1;
        }
      }

      // El último nivel el input from no puede superar al último input to
      if (item.level === data.length - 1) {
        const lastTo = data[data.length - 1].to;
        if (value >= lastTo) {
          value = lastTo - 1;
        }
      }

      return {
        ...item,
        [name]: value,
      };
    });

    setData(updatedData);
  };

  useEffect(() => {
    // Deshabilitar el input to del último nivel
    const lastLevel = data.length - 1;
    //data[lastLevel].to.disabled = true;
  }, [data]);

  return (
    <form style={{ padding: "60px" }}>
      {data.map((item, index) => (
        <div key={index}>
          <input
            type="number"
            name="from"
            value={item.from}
            //disabled={index === lastLevel}
          />
          <input
            type="number"
            name="to"
            value={item.to}
            onChange={handleChange}
          />
        </div>
      ))}
    </form>
  );
};

export default PruebaNiveles;
