import { Box, Grid, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation.schema";
import {
  AutocompleteInput,
  BaseInput,
  CheckGroupInput,
  MaskInput,
  SelectInput,
} from "../../Input";
import { DateInput } from "../../Input/DateInput";

export const KycTwoPannel = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      annualIncome: "",
      otherAnnualIncome: "",
      otherIncomeDetails: "",
      incomeOrigin: {
        privada: false,
        publica: false,
        capitalMixto: false,
        ong: false,
      },
    },
  });

  const formSubmit = (data: any) => {
    console.log({ data });
  };

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <form onSubmit={handleSubmit(formSubmit)} style={{ paddingLeft: 16 }}>
        <Grid container spacing={8} sx={{ pt: 0, pb: 0 }}>
          {/* First column */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              pt: 0,
              pb: 0,
              ml: {
                xs: 0,
                md: 12,
              },
            }}
          >
            <Typography variant="h5">Tipo de empresa</Typography>
            <Stack display="flex" justifyContent="center" alignItems="left">
              <CheckGroupInput
                name="incomeOrigin"
                control={control}
                errors={errors}
                label=""
                data={[
                  { value: "privada", label: "Empresa privada" },
                  { value: "publica", label: "Empresa pública" },
                  { value: "capitalMixto", label: "Capital mixto" },
                  { value: "ong", label: "ONG" },
                ]}
              />
            </Stack>
          </Grid>
          {/* Second column */}
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Typography variant="h5">Información financiera</Typography>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <SelectInput
                name="annualIncome"
                label="Ingresos anuales"
                control={control}
                errors={errors}
                register={register("annualIncome")}
                data={[
                  {
                    id: "1",
                    name: "Menos de DOP 600,000.00",
                  },
                  {
                    id: "2",
                    name: "Entre DOP 600,000.00 y DOP 1,999,999",
                  },
                  {
                    id: "3",
                    name: "Entre DOP 2,000,000.00 y DOP 3,999,999",
                  },
                  {
                    id: "1",
                    name: "Mas de DOP 4,000,000.00",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <SelectInput
                name="otherAnnualIncome"
                label="Otros ingresos anuales"
                control={control}
                errors={errors}
                register={register("otherAnnualIncome")}
                data={[
                  {
                    id: "1",
                    name: "Menos de DOP 600,000.00",
                  },
                  {
                    id: "2",
                    name: "Entre DOP 600,000.00 y DOP 1,999,999",
                  },
                  {
                    id: "3",
                    name: "Entre DOP 2,000,000.00 y DOP 3,999,999",
                  },
                  {
                    id: "1",
                    name: "Mas de DOP 4,000,000.00",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="otherIncomeDetails"
                control={control}
                errors={errors}
                register={register("otherIncomeDetails")}
                label="Detalle otros ingresos"
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
