import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { UrlBase } from "../../url/Urls";

export const getFactors = async (page: number, type: string, companyID: number, subCompanyID?: string, size: number = 10) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_FACTORS_BY_COMPANY(page, size, type, companyID, subCompanyID))
    //.get(URL.REQUEST_FACTORS_BY_COMPANY(page, 5, type, companyID, subCompanyID))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const createUpdFactors = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_FACTORS_CUD(null), data)
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const deleteFactors = async (id: string) => {
  let responseData = "";
  await privateFetch
    .delete(URL.REQUEST_FACTORS_CUD(id))
    .then(async () => {
      responseData = "Factor eliminado"
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getSingleFactors = async (factor: string, page: number, filter?: string, size?: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_SINGLE_FACTOR(factor, page, size ? size : UrlBase.registration_x_page
      , filter))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};


export const createUpdSingleFactors = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_CUD_SINGLE_FACTOR(null), [data])
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const deleteSingleFactors = async (id: string) => {
  try {
    await privateFetch.delete(URL.REQUEST_CUD_SINGLE_FACTOR(id));
    return "Elemento eliminado";
  } catch (error: any) {
      throw new Error(error.response.data.message);
  }
};

