import { useLayoutEffect, useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  styled,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { UrlBase } from "../../url/Urls";

import { useApp } from "../../hooks/useApp";
import FancyPaper from "../../components/FancyPaper";

const styles = {
  width: "100%",
  height: "85vh",
  border: "none",
};

const InputLabelForm = styled(InputLabel)(() => ({
  transform: "translate(0px, -9px) scale(0.75)",
}));

const OpenApi = () => {
  const { setLoading } = useApp();
  const [apiName, setApiName] = useState("maintenance/api");

  useLayoutEffect(() => {
    setLoading && setLoading(true);
    setTimeout(() => {
      setLoading && setLoading(false);
    }, 2000);
  }, []);

  const handleChangeApi = async (e: SelectChangeEvent) => {
    setLoading && setLoading(true);
    setApiName(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading && setLoading(false);
    }, 2500);
  }, [apiName]);
  return (
    <>
      <FancyPaper pagetitle="Mantenimiento / Open API">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabelForm>Apis</InputLabelForm>

              <Select
                defaultValue="maintenance/api"
                onChange={handleChangeApi}
                variant="standard"
                fullWidth
              >
                <MenuItem value="maintenance/api">Mantenimiento</MenuItem>
                <MenuItem value="users">Usuarios</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <iframe
              title="Api Investment"
              src={`${
                UrlBase.base_url + UrlBase.api_context
              }/${apiName}/swagger-ui/index.html#/`}
              style={styles}
            />
          </Grid>
        </Grid>
      </FancyPaper>
    </>
  );
};

export default OpenApi;
