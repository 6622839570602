import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Grid,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import { useApp } from "../../hooks/useApp";
import { getAllRubros } from "../../services/rubros/RubrosService";
import moment from "moment";
import FancyTablePagination from "../../components/utils/FancyTablePagination";

const RubrosManagement = () => {
  const { setLoading, setErrorMsg } = useApp();
  const theme = useTheme();
  const [allRubros, setAllRubros] = useState<any>([]);

  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("");

  //navigation react
  const navigate = useNavigate();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (page: number, filter: string) => {
    setLoading && setLoading(true);
    try {
      let data = await getAllRubros(page, 10, filter);
      if (data) {
        // @ts-ignore
        setAllRubros(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleApplyFilter = async () => {
    if (filter !== "") {
      console.log(filter);
      await handleFetchData(0, filter);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, "");
    }
  };

  const formatDate = (date: any) => {
    const fecha = moment(date).format("DD/MM/YYYY HH:mm:ss");
    return fecha === "Invalid date" ? "No hay ultima fecha" : fecha;
  };

  return (
    <>
      <FancyPaper pagetitle="Factores">
        <Grid
          container
          spacing={8}
          sx={{
            pb: 8,
            justifyContent: {
              xs: "flex-start",
              md: "flex-end",
            },
          }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                placeholder="Filtro de búsqueda"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "0.2rem 0 0 0.2rem",
                  },
                  "& fieldset": {
                    borderRightWidth: "0",
                  },
                }}
                value={filter}
                onChange={(e: any) => {
                  setFilter(e.target.value);
                  if (e.target.value === "") {
                    handleFetchData(0, "");
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell align="center">Elementos</TableCell>
                <TableCell align="center">Fecha de Actualización</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allRubros.content &&
                allRubros.content.map((row: any, i: number) => (
                  <TableRow
                    onClick={() => {
                      navigate(
                        `/dashboard/rubrosManagement/${row.endPointName}`
                      );
                      // console.log(row.endPointName)
                    }}
                    key={i}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                      },
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{row.tableName}</TableCell>
                    <TableCell align="center">{row.numberData}</TableCell>
                    <TableCell align="center">
                      {formatDate(row.modified)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={allRubros?.length > 0 ? allRubros?.length : 0}
          rowsPerPage={allRubros.size > 0 ? allRubros.size : 0}
          page={page ? page : 0}
          onPageChange={handleChangePage}
          totalElements={
            allRubros.totalElements > 0 ? allRubros.totalElements : 0
          }
          totalPages={allRubros.totalPages}
          numberOfElements={allRubros?.numberOfElements ?? 0}
        />
      </FancyPaper>
    </>
  );
};

export default RubrosManagement;
