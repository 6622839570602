import {
  HouseIcon,
  GearIcon,
  TableIcon,
  FromBracketIcon,
  ChartIcon,
} from "../components/svgicons/SvgIcons";
import { properties } from "./Properties_es";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import BadgeIcon from "@mui/icons-material/Badge";

import { MenuItem } from "../types/Menu";
import { getReports } from "../services/report/ReportService";

// async function getReportMenuItems() {
//   const response = await getReports(0, 100);
//   const menuItemsReport = response.content.map((item) => {
//     return {
//       label: item.name,
//       to: `/report/${item.id}`,
//       // to: '',
//       permissions: ['is_superuser', 'ACTIVO:WRITE',],
//     }
//   }
//   )
//   return menuItemsReport
// }

// getReportMenuItems().then((menuItemsReport) => {

//   MENUITEMS[1].children = menuItemsReport
// })

export const MENUITEMS: MenuItem[] = [
  {
    label: "Inicio",
    icon: (
      <HouseIcon
        sx={{ transform: "translateY(-1px)", fontSize: "0.9225rem" }}
      />
    ),
    to: "/dashboard",
    permissions: ["is_superuser", "DASHBOARD:READ"],
  },
  // {
  //   label: 'Reportes',
  //   icon: <ChartIcon />,
  //   to: null,
  //   permissions: ['is_superuser', 'ACTIVO:READ', 'ACTIVO:WRITE'],
  //   expand: true,
  //   children: []
  // },
  {
    label: "Gestión de riesgos",
    icon: <PermDataSettingIcon />,
    to: null,
    expand: true,
    children: [
      /*  {
        label: "Matriz de riesgo",
        to: "agrupacion",
        permissions: [
          "is_superuser",
          "is_user",
          "CAPICITACIONESMANAGEMENT:READ",
        ], //
      }, */
      {
        label: "Matriz de riesgo",
        to: "riskMatrixTabs",
        permissions: ["is_superuser", "is_user","MATRIXTABS:READ"], //
      },
      {
        label: "Verificación en listas",
        to: "validateclient",
        permissions: [
          "is_superuser",
          "is_user",
          "VALIDATECLIENT:READ",
        ], //
      },
    ],
    permissions: ["is_superuser", "RISKMANAGMENTMENU:READ"],
  },
  {
    label: "Debida diligencia",
    icon: <QueryStatsIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Simplificada",
        to: "duediligence/simplified",
        permissions: ["is_superuser", "is_user", "SIMPLIFIEDMANAGEMENT:READ"], //
      },
      {
        label: "Normal",
        to: "duediligence/normal",
        permissions: ["is_superuser", "is_user", "NORMALMANAGEMENT:READ"], //
      },
      {
        label: "Ampliada",
        to: "duediligence/extended",
        permissions: ["is_superuser", "is_user", "EXPANDEDMANAGEMENT:READ"], //
      },
    ],
    permissions: ["is_superuser", "DUEDILIGENCEMENU:READ"],
  },
  {
    label: "Mantenimientos",
    icon: <TableIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Niveles de riesgo",
        to: "riskLevels",
        permissions: ["is_superuser", "RISKLEVELS:READ"],
      },
      {
        label: "Factores",
        to: "factorsManagement",
        permissions: ["is_superuser", "is_user", "FACTORSMANAGEMENT:READ"],
      },
      {
        label: "Matriz agrupaciones",
        to: "matrizManagement",
        permissions: ["is_superuser", "is_user", "MATRIZMANAGEMENT:READ"],
      },
      {
        label: "Sucursales",
        to: "branchManagement",
        permissions: ["is_superuser", "is_user", "BRANCHMANAGEMENT:READ"],
      },
      {
        label: "Departamentos",
        to: "deparmentManagement",
        permissions: ["is_superuser", "is_user", "DEPARTAMENTS:READ"],
      },
      {
        label: "OpenApi",
        to: "openapi",
        permissions: ["is_superuser", "OPENAPI:READ"],
      },
    ],
    permissions: ["is_superuser", "MAINTENANCEMENU:READ"],
  },
  {
    label: "Capacitaciones",
    icon: <BadgeIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Capacitaciones",
        to: "settingTrainingControl",
        permissions: ["is_superuser", "is_user", "CAPACITACIONES:WRITE"], //
      },
      {
        label: "Registro y control de capacitaciones",
        to: "trainingcontrol",
        permissions: [
          "is_superuser",
          "is_user",
          "CAPICITACIONESMANAGEMENT:READ",
        ], //
      },
    ],
    permissions: ["is_superuser", "CAPACITACIONESMENU:READ"],
  },
  {
    label: "Configuraciones",
    icon: (
      <GearIcon sx={{ transform: "translateY(-1px)", fontSize: "1.025rem" }} />
    ),
    to: null,
    expand: true,
    children: [
      {
        label: "Usuarios",
        to: "userManagement",
        permissions: ["is_superuser", "USER:READ"],
      },
      {
        label: "Perfiles",
        to: "roleManagement",
        permissions: ["is_superuser", "ROLE:READ"],
      },
      {
        label: "Permisos",
        to: "authority",
        permissions: ["is_superuser", "AUTHORITY:READ"],
      },
      {
        label: "Compañías",
        to: "settingCompanies",
        permissions: ["is_superuser", "COMPANIES:READ"],
      },
    ],
    permissions: ["is_superuser", "SETTINGSMENU:READ"],
  },
  {
    label: properties.com_mapprisk_label_dashboard_menu_logout,
    icon: <FromBracketIcon />,
    to: null,

    permissions: ["is_superuser", "is_user"],
  },
];
