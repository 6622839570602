import { URL } from "../../utils/UrlConstants";
import { UrlBase } from "../../url/Urls";
import { privateFetch } from "../../utils/CustomFetch.js";

export const validateUser = async (data: any) => {
  let responseData = {};

  //return [];
  await privateFetch
    .post(URL.REQUEST_EXTERNALSERVICE_VALIDATEUSER, JSON.stringify(data), {
      headers: {
        Accept: "application/pdf",
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const uploadDocumentInternalList = async (
  userId: string,
  companyId: string,
  subCompanyId: string | null,
  data: any
) => {
  let responseData = {};
  const formData = new FormData();
  formData.append("file", data as File);
  const response = await privateFetch
    .post(
      URL.REQUEST_DOCUMENT_UPD_INTERNALLIST(userId, companyId, subCompanyId),
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundaryAGajSuGLyB3CXIsT",
        },
      }
    )
    .then(async (response) => {
      responseData = await response.status;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const getInternalListDocument = async (
  company: string,
  subCompany?: string
) => {
  let responseData = {};

  await privateFetch
    .get(UrlBase.base_url_screening + UrlBase.api_context + URL.REQUEST_EXTERNALSERVICE_INTERNALLIST(company, subCompany))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.message);
    });
  return responseData;
};

export const deleteDocumentInternalList = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_EXTERNALSERVICE_DELETE_INTERNALLIST(id))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de eliminar documento lista interna");
    });
  return responseData;
};
