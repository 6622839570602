import { Box, Grid, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation.schema";
import {
  AutocompleteInput,
  BaseInput,
  CheckGroupInput,
  MaskInput,
  SelectInput,
  RadioGroupInput,
} from "../../Input";
import { DateInput } from "../../Input/DateInput";

export const KycThirdPannel = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      businessName: "",
      identification: "",
      shareholding: "",
      matchingOnLists: "",
    },
  });

  const formSubmit = (data: any) => {
    console.log({ data });
  };

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <form onSubmit={handleSubmit(formSubmit)} style={{paddingLeft: 16}}>
        <Grid container spacing={8} sx={{ pt: 0, pb: 0 }}>
          {/* First column */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              pt: 0,
              pb: 0,
            }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="businessName"
                control={control}
                errors={errors}
                register={register("businessName")}
                label="Nombre / razón social"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="identification"
                control={control}
                errors={errors}
                register={register("identification")}
                label="Identificación"
                fullWidth
              />
            </Grid>
          </Grid>
          {/* Second column */}
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="shareholding"
                control={control}
                errors={errors}
                register={register("shareholding")}
                label="Participación accionaria"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <RadioGroupInput
                name="shareholding"
                control={control}
                errors={errors}
                label="Consideración en listas"
                data={[
                  { value: "si", label: "Sí" },
                  { value: "no", label: "No" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
