import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

interface MethodologySelectorProps {
    selectedMethodology: string;
    onMethodologyChange: (newMethodology: string) => void;
    type: string;
}

const MethodologySelector: React.FC<MethodologySelectorProps> = ({ selectedMethodology, onMethodologyChange, type }) => {
    let options: readonly any[] = [];

    if (type === '1') {
        options = [
            { id: '1', name: '3x3' },
            { id: '2', name: '4x4' },
            { id: '3', name: '5x5' },
        ];
    } else if (type === '2') {
        options = [
            { id: '1', name: '3 niveles' },
            { id: '2', name: '4 niveles' },
            { id: '3', name: '5 niveles' },
        ];
    }

    const selectedOption = options.find((option) => option.id === selectedMethodology);

    const handleAutocompleteChange = (event: React.ChangeEvent<{}>, newValue: { id: string; name: string } | null) => {
        if (newValue) {
            onMethodologyChange(newValue.id);
        }
    };

    return (
        <div>
            <Autocomplete
                options={options}
                getOptionLabel={(option) => option.name || ''}
                value={selectedOption || null}
                onChange={handleAutocompleteChange}
                renderInput={(params) => (
                    <TextField
                        style={{ width: "200px" }}
                        {...params}
                        fullWidth
                        label={type === '1' ? 'Metodología' : 'Leyenda'}
                        variant="standard"
                        error={false}
                    />
                )}
            />
        </div>
    );
};

export default MethodologySelector;
