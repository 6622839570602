import { useState, SyntheticEvent } from "react";

export const useKycTabs = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return {
        activeTab,
        handleTabChange
    };
};