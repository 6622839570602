import { Fragment, useState } from "react";
import { Box, FormLabel, Grid, List, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { QuestionGroup } from "../../QuestionGroup";
import { QuestionItem } from "../../QuestionItem";
import { BaseInput, MaskInput, SelectInput } from "../../Input";
import InputLabelToForm from "../../../../../components/InputLabelToForm";
import { amber } from "@mui/material/colors";
import { Label } from "@mui/icons-material";

type TQuestion = {
  question: string;
  answer: "si" | "no" | "";
};
type TPEP = {
  id: number;
  description: string;
  impact: number;
  risk: number;
  probability: number;
  subElements: {
    id: string;
    name: string;
  }[];
};

export const KycThreePannel = ({ factorsAndOptions }: any) => {
  const [peps, setPeps] = useState<TPEP[] | null>(
    factorsAndOptions.filter((item: any) => item.name === "PEPS")[0].options ??
      null
  );
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      spouseName: "",
      spouseCedula: "",
      spouseWorkPlace: "",
      spouseWorkAddress: "",
      spouseWorkPhone: "",
    },
  });
  const [answers, setAnswers] = useState<TQuestion[]>([
    {
      question: "Ha manejado recursos públicos durante los últimos 3 años?",
      answer: "",
    },
    {
      question:
        "Ho ejercicido algún grado de poder público durante los últimos 3 años?",
      answer: "",
    },
    {
      question:
        "¿Es persona reconocida, de influencia pública política o de notoriedad pública?",
      answer: "",
    },
    {
      question:
        "¿Es afirmativa la respuesta a alguna de las preguntas anteriores, para su conyuge, padre, abuelos, hermanos, hijos, nietos, suegro, nuera, yerno?",
      answer: "",
    },
  ]);
  const handleAnswer = (index: number, value: string) => {
    console.log({ index, value });
    setAnswers((prev) => {
      prev[index].answer = value as TQuestion["answer"];
      return [...prev];
    });
  };

  const formSubmit = (data: any) => {
    console.log({ data: { ...data, answers: answers } });
  };
  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <form onSubmit={handleSubmit(formSubmit)}>
        <Grid container spacing={8} sx={{ pt: 0, pb: 0 }}>
          {/**first column */}
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={8}
            sx={{ pt: 0, pb: 0 }}
          >
            <QuestionGroup title="Informacion Adicional PEPs">
              <Box sx={{ width: "100%" }}>
                {peps?.map((item: TPEP, index: number) => (
                  <Fragment key={index}>
                    <FormLabel style={{ color: "#5C5D60" }}>
                      {item?.description}
                    </FormLabel>

                    <SelectInput
                      uncontrolled
                      name={`answer.${index}`}
                      errors={errors}
                      data={item.subElements}
                      selectProps={{
                        onChange: (e) =>
                          handleAnswer(index, e.target?.value as string),
                        value: item.subElements[index]?.name,
                      }}
                    />
                  </Fragment>
                ))}
                {/* {answers?.length > 0 &&
                  answers.map((item: TQuestion, index: number) => (
                    <Fragment key={index}>
                      <FormLabel style={{ color: "#5C5D60" }}>
                        {item?.question}
                      </FormLabel>

                      <SelectInput
                        uncontrolled
                        key={item.question}
                        name={`answer.${index}`}
                        errors={errors}
                        data={[
                          { id: "si", text: "Si" },
                          { id: "no", text: "No" },
                        ]}
                        selectProps={{
                          onChange: (e) =>
                            handleAnswer(index, e.target?.value as string),
                          value: answers[index]?.answer,
                        }}
                      />
                    </Fragment>
                  ))} */}
              </Box>
            </QuestionGroup>
          </Grid>
          {/**second column */}
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <Typography variant="h5" gutterBottom>
                Datos del Cónyuge
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <BaseInput
                name="spouseName"
                control={control}
                errors={errors}
                label="Nombre/Apellido"
                register={register("spouseName")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <BaseInput
                name="spouseCedula"
                control={control}
                errors={errors}
                label="Cédula"
                register={register("spouseCedula")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <BaseInput
                name="spouseWorkPlace"
                control={control}
                errors={errors}
                label="Lugar de trabajo"
                register={register("spouseWorkPlace")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <BaseInput
                name="spouseWorkAddress"
                control={control}
                errors={errors}
                label="Dirección Trabajo"
                register={register("spouseWorkAddress")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <MaskInput
                name="spouseWorkPhone"
                control={control}
                errors={errors}
                label="Teléfono Trabajo"
                register={register("spouseWorkPhone")}
                maskType="phone"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
