import { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Divider,
  Dialog,
  Stack,
} from "@mui/material";
import {
  TrashIcon,
  PlusIcon,
  PenIcon,
} from "../../components/svgicons/SvgIcons";
import FancyPaper from "../../components/FancyPaper";
import FancyTooltip from "../../components/utils/FancyTooltip";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import { useForm } from "react-hook-form";
import { properties } from "../../utils/Properties_es";
import { useApp } from "../../hooks/useApp";
import DeleteModal from "../../components/utils/DeleteModal";
import ResourceAccess from "../../components/security/ResourceAccess";
import { useNavigate, useParams } from "react-router-dom";
import {
  createRubro,
  deleteRubro,
  getRubroWithUrl,
  updateRubro,
  getRubroFindId,
} from "../../services/rubros/RubrosService";
import { en_esKeys } from "../../components/utils/I18n";
import RowHead from "../../components/RowHead";
import AddOrUpdateRubro from "../../components/utils/AddOrUpdateRubro";

const RubroPage = () => {
  const {
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    isLoading,
  } = useApp();
  const theme = useTheme();
  const [rubroData, setRubroData] = useState<any>([]);
  const [rubroDataContent, setRubroDataContent] = useState<any>([]);
  const [initForm, setInitForm] = useState<any>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const { endPointName } = useParams();
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  useEffect(() => {
    let dataInit;
    dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (currentPage: number, filter: string) => {
    setLoading && setLoading(true);
    try {
      let data: any = await getRubroWithUrl(endPointName!!, 10, currentPage);
      if (data) {
        const cleanedData = data.content.map(
          ({
            updateddAt,
            createdAt,
            userCreated,
            userModified,
            company,
            subcompany,
            createddAt,
            createdBy,
            ...rest
          }: any) => rest
        );
        setRubroDataContent(cleanedData);
        setRubroData(data);
        setDataLoaded(true);
        // @ts-ignore
        setInitForm(data);
        //setInitForm(data.content[0]);
        // @ts-ignore
        setTitle(data.content[0].tableName);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(
          "Esta tabla no contiene factores, comuniquese con el administrador de base de datos"
        );
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, "");
    }
  };

  const handleFectById = async (url: string, id: string) => {
    setLoading && setLoading(true);
    try {
      let dataId = await getRubroFindId(url, id);
      if (dataId) {
        setLoading && setLoading(false);
        return dataId;
      }
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRubro(endPointName!!, data);

      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_emailrole_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRubro(endPointName!!, data);

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_document_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      setPage(0);
      // handleChangePage("", 0);
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRubro(endPointName!!, id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_document_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);
      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Validate errors in case of opening modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any, row?: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      //setInitForm(row);
      const rubroID =
        endPointName && (await handleFectById(endPointName, row.id));
      setInitForm(rubroID);
    }

    if (modalAction === "delete") {
      object = rubroData.content.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* {isLoading && <Loading />} */}
      {
        /* !!title &&  */ !isLoading && (
          <>
            <FancyPaper
              pagetitle={title}
              prevpage="Factores "
              linkprevpage="/dashboard/rubrosManagement"
            >
              <Grid container spacing={8} sx={{ pb: 8 }}>
                <Grid item md={9} sm={6} xs={12}>
                  <Stack direction="row" alignItems="center">
                    <span
                      style={{
                        fontSize: "1.5rem",
                      }}
                    >
                      {" "}
                      Tabla de {title}
                    </span>
                  </Stack>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <ResourceAccess
                      isCode={true}
                      pathOrCode={"RUBROSMANAGEMENT:WRITE"}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenModal}
                        data-name="create"
                      >
                        Nuevo ítem
                        <PlusIcon sx={{ ml: 1 }} />
                      </Button>
                    </ResourceAccess>
                  </Stack>
                </Grid>
              </Grid>

              <Divider />
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {dataLoaded && <RowHead data={rubroDataContent[0]} />}
                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rubroDataContent !== null &&
                      rubroDataContent.map(
                        (row: any, i: number) =>
                          row.id !== null && (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {en_esKeys.map((key: string) => {
                                if (row.hasOwnProperty(key)) {
                                  return (
                                    <TableCell align="center" key={key}>
                                      {row[key]}
                                    </TableCell>
                                  );
                                } else {
                                  return null; // Ignorar las propiedades sin valor
                                }
                              })}
                              <TableCell align="center">
                                <ResourceAccess
                                  isCode={true}
                                  pathOrCode={"RUBROSMANAGEMENT:WRITE"}
                                >
                                  <FancyTooltip title="Editar" placement="top">
                                    <IconButton
                                      aria-label="edit"
                                      component="label"
                                      color="secondary"
                                      sx={{
                                        "&:hover": {
                                          color: theme.palette.secondary.dark,
                                        },
                                      }}
                                      onClick={(e) => handleOpenModal(e, row)}
                                      data-name="update"
                                      data-id={row.id}
                                    >
                                      <PenIcon />
                                    </IconButton>
                                  </FancyTooltip>
                                  <FancyTooltip
                                    title="Eliminar"
                                    placement="top"
                                  >
                                    <IconButton
                                      aria-label="trash"
                                      component="label"
                                      color="secondary"
                                      sx={{
                                        "&:hover": {
                                          color: theme.palette.secondary.dark,
                                        },
                                      }}
                                      data-id={row.id}
                                      onClick={handleOpenModal}
                                      data-name="delete"
                                    >
                                      <TrashIcon />
                                    </IconButton>
                                  </FancyTooltip>
                                </ResourceAccess>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <FancyTablePagination
                count={
                  rubroData?.content?.length > 0
                    ? rubroData?.content?.length
                    : 0
                }
                rowsPerPage={rubroData.size}
                page={page}
                onPageChange={handleChangePage}
                totalElements={rubroData.totalElements}
                totalPages={rubroData.totalPages}
                numberOfElements={rubroData?.numberOfElements ?? 0}
              />
            </FancyPaper>
            {(modalData?.modalType === "create" ||
              modalData?.modalType === "update") && (
              <Dialog
                open={modalData.modalOpen}
                onClose={handleCancelModal}
                fullWidth
              >
                <AddOrUpdateRubro
                  data={initForm?.content[0]}
                  onSubmit={onSubmit}
                  cancelModal={handleCancelModal}
                  modalType={modalData?.modalType}
                />
              </Dialog>
            )}
            {modalData?.modalType === "delete" && (
              <Dialog
                open={modalData.modalOpen}
                onClose={handleCancelModal}
                fullWidth
              >
                <DeleteModal
                  //@ts-ignore
                  textChildren={modalData?.modalObject?.name}
                  actionButton={
                    <>
                      {" "}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          mt: 2,
                          mr: 2,
                        }}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Aceptar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          mt: 2,
                        }}
                        onClick={handleCancelModal}
                        autoFocus
                      >
                        Cancelar
                      </Button>{" "}
                    </>
                  }
                />
              </Dialog>
            )}
          </>
        )
      }

      {
        /* !!!title &&  */ rubroDataContent === null && !isLoading && (
          <Stack
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            height="100%"
            marginTop="-2rem"
          >
            <span
              style={{
                fontSize: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              Esta tabla no contiene rubros, comuniquese con el administrador de
              base de datos
            </span>
            <Button
              onClick={() => navigate("/dashboard/rubrosManagement")}
              variant="contained"
            >
              Regresar
            </Button>
          </Stack>
        )
      }
    </>
  );
};

export default RubroPage;
