import { useEffect, useState, lazy } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  IconButton,
  useTheme,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyTooltip from "../../components/utils/FancyTooltip";

import { TrashIcon, PlusIcon } from "../../components/svgicons/SvgIcons";

import { useApp } from "../../hooks/useApp";
import Chart from "react-apexcharts";

import { getRequestEmployeeCapacitationChart } from "../../services/trainingcontrol/TrainingControl";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateTraining {
  id?: number;
  total_hours_apply: string;
  name: string;
  method: string;
  instructor: string;
  date: Date;
  period: string;
  total_hours: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const series = [
  {
    name: "Horas requeridas",
    data: [10],
  },
  {
    name: "Horas Recibidas",
    data: [4],
  },
  {
    name: "Horas pendientes",
    data: [6],
  },
];

interface ItemType {
  id?: number;
  capacitationId?: {
    name?: string;
    method?: string;
    instructor?: string;
    total_hours?: number;
    date?: string;
    period?: string;
  };
  completedHours?: number;
  pendingHours?: number;
}

// Configuration for the chart
const options = {
  chart: {
    id: "line-chart",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: ["Horas"],
  },
  colors: ["#008FFB", "#8bc8bc", "#FF4560"], // Assign colors to data series
};

const DashboardTrainingControlModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const [employeename, setEmployeename] = useState("");
  const [capacitacionDataitem, setCapacitacionDataitem] = useState<ItemType>();
  const [employeeCapacitationChartData, setEmployeeCapacitationChartData] =
    useState<any[]>([]);
  useEffect(() => {
    handleFetchData();
  }, []);

  const handleFetchData = async () => {
    try {
      //console.log(" handleFetchData data=", data);
      setCapacitacionDataitem(data);

      let employeeCapacitationChartData: any =
        await getRequestEmployeeCapacitationChart(data.id);
      setEmployeeCapacitationChartData(employeeCapacitationChartData);

      const name = `${data.employeeId.firstName} ${data.employeeId.lastName}`;
      setEmployeename(name);
    } catch (error: any) {
      console.log("handleFetchData error=", error.message);
    }
  };

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        Progreso
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Empleado:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {employeename}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Nombre:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Modalidad:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.method}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Instructor:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.instructor}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Duración en horas:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.total_hours}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Fecha:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.date}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
            >
              Período:
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.primary"
              component="div"
              style={{
                background: "#eee",
                padding: "2px 5px",
                borderBottom: "1px solid #5C5D60",
                marginBottom: "5px",
              }}
            >
              {capacitacionDataitem?.capacitationId?.period}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Chart
              options={options}
              series={employeeCapacitationChartData}
              type="bar"
              height={350}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default DashboardTrainingControlModal;
