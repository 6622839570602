import { Box, Paper, Typography, Divider, useTheme } from "@mui/material";
import { useNavigate } from "react-router";

const FancyPaper = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate()

  // console.log(decodeURIComponent(props.pagetitle))

  return (
    <Box
      sx={{
        p: {
          xs: 5,
          md: `${theme.spacing(12)} ${theme.spacing(12)} ${theme.spacing(6)}`,
        },
      }}
    >
      <Typography
        variant="caption"
        color="info.dark"
        onClick={() => navigate("/dashboard")}
        sx={{
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        }}
      >
        Inicio{" "}
      </Typography>
      {props.pagemenutitle && (
        <Typography variant="caption" color="text.primary">
          {"/ "} {props.pagemenutitle}
        </Typography>
      )}
      {props.prevpage && props.linkprevpage && (
        <Typography
          variant="caption"
          color="info.dark"
          onClick={() => navigate(props.linkprevpage)}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          {"/ "} {props.prevpage}
        </Typography>
      )}
      {props.prevpages &&
        props.prevpages.map((item: any) => (
          <Typography
            variant="caption"
            color="info.dark"
            onClick={() => navigate(item.linkprevpage)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
          >
            {"/ "} {item.name}
          </Typography>
        ))}
      <Typography variant="caption" color="text.primary">
        {"/ "} {props.pagetitle}
      </Typography>

      <Divider />
      <Paper
        sx={{
          width: "100%",
          mt: {
            xs: 5,
            md: 10,
          },
          mb: 5,
        }}
        {...props}
      >
        {props.children}
      </Paper>
    </Box>
  );
};

export default FancyPaper;
