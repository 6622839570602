import {
  Box,
  Button,
  ButtonBase,
  TextField,
  Typography,
  styled,
  useTheme,
  FormLabel,
  IconButton,
  Theme,
  ButtonBaseProps,
} from "@mui/material";
import { Control, UseFormRegisterReturn } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const Field = styled(TextField)(({ theme }) => ({
  flexBasis: "calc(100% - 320px)",
  marginBottom: "0",
  marginTop: "0",
  flexGrow: 1,
  "& .MuiFormLabel-root.Mui-disabled": {
    color: theme.palette.text.primary,
    transform: "translate(0, -1.5px) scale(0.75)",
  },
}));

const Dropzone = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const Label = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0",
  marginTop: "0",
  color: theme.palette.text.primary,
  "& .MuiFormLabel-root.Mui-disabled": {
    color: theme.palette.text.primary,
    transform: "translate(0, -1.5px) scale(0.75)",
  },
}));

const SButtonBase = styled(ButtonBase)<ButtonBaseProps>(({ theme }) => ({
  padding: theme.spacing(2),
  position: "absolute",
  width: "100%",
  bottom: "0",
  height: "100%",
  borderButtom: `1px solid ${theme.palette.mappRisk.light}`,
  borderRadius: 2,
  "&:hover": {
    backgroundColor: theme.palette.mappRisk.light,
  },
  "&:active": {
    borderColor: theme.palette.mappRisk.main,
  },
}));

interface IFileInputProps {
  label?: string;
  name: string;
  fileName: any;
  register: UseFormRegisterReturn<any>;
  control: Control<any>;
  errors: any;
  extensionList?: string;
  onChanges?: (file: File[]) => void;
}

export const FileInput = ({
  label,
  fileName,
  name,
  register,
  control,
  errors,
  extensionList = "",
  onChanges,
  ...props
}: IFileInputProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onChanges && onChanges(acceptedFiles);
    console.log({ acceptedFiles });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" flexGrow={1} sx={{ mb: 2 }}>
      {label && <Label htmlFor="">{label}</Label>}
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        sx={{ position: "relative", width: "100%" }}
      >
        <Field
          variant="standard"
          margin="normal"
          fullWidth
          disabled
          value={fileName}
          error={errors[name] ? true : false}
          helperText={errors[name] ? errors[name].message : ""}
          InputProps={{
            disableUnderline: true,
            startAdornment: <AttachFileIcon />,
          }}
        />

        <SButtonBase
          component="label"
          sx={{
            borderColor: fileName ? theme.palette.mappRisk.main : theme.palette.mappRisk.light,
          }}
          // data-write={ResourceAccess(true, "CHANGESTAGE:WRITE")}
        >
          <Dropzone {...getRootProps()}>
            <input
              {...getInputProps()}
              name="file"
              accept={`image/png, image/jpeg, image/jpg,.pdf,.doc,.docx,.xls,.xlsx,.eml`}
              data-ext={extensionList.replace(/[^A-Z,/"']+/gi, "")}
            />
            {isDragActive ? (
              <Typography
                variant="body1"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                Suelta los archivos aquí...
              </Typography>
            ) : (
              ""
            )}
          </Dropzone>
        </SButtonBase>
      </Box>
    </Box>
  );
};
