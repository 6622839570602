import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Grid, Stack, Button, Dialog } from '@mui/material';
import FancyPaper from '../../components/FancyPaper';
import { useApp } from '../../hooks/useApp';
import { useData } from '../../hooks/useData';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from '@mui/material';
import FancyTablePagination from '../../components/utils/FancyTablePagination';
import DeleteButton from '../../components/utils/iconButtons/DeleteButton';
import UpdateButton from '../../components/utils/iconButtons/UpdateButton';
import DeleteModal from '../../components/utils/DeleteModal';
import { PlusIcon } from '../../components/svgicons/SvgIcons';
import ErrorAlert from '../../components/utils/ErrorAlert';
import {
  createSubElement,
  deleteSubElement,
  getSubElements,
} from '../../services/subelements/SubElementsService';
import AddOrUpdateSubElements from '../../components/utils/AddOrUpdateSubElements';

const SecondLevelSingleFactor = () => {
  const { IDSingleFactor, nameSingleFactor, IDSecondSingleFactor, nameSecondSingleFactor } =
    useParams();

  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    errorMsg,
    modalData,
    setModalData,
    riskData,
    handleFetchRiskLevels,
    defaultRiskData,
  } = useApp();
  const { companyIndexDB, subCompanyData } = useData();

  const [subElements, setSubElements] = useState<any>([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const getData = async () => {
      if (IDSecondSingleFactor) {
        await handleFetch(0);
      }
    };
    getData();
  }, [IDSecondSingleFactor]);

  console.log(subElements);

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();

    riskData === null &&
      handleFetchRiskLevels &&
      handleFetchRiskLevels(companyIndexDB?.company.id, companyIndexDB?.subCompany.id);

    const modalAction = event.currentTarget.getAttribute('data-name');
    let object = null;

    const id = event.currentTarget.getAttribute('data-id');
    const name = event.currentTarget.getAttribute('data-name-element');

    if (modalAction === 'update') {
      object = await subElements.content.filter((f: any) => f.id === Number(id))?.[0];
    } else {
      object = { id: id, name: name ?? '' };
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const handleFetch = async (currentPage: number) => {
    setLoading && setLoading(true);
    try {
      setPage(currentPage);
      let data: any = await getSubElements(currentPage, IDSecondSingleFactor!!);
      if (data) {
        setSubElements(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleAddSubElement = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    console.log(data);
    try {
      await createSubElement({
        ...data,
        factor_option_id: IDSecondSingleFactor,
      });
      setSuccessMsg && setSuccessMsg(data.id ? 'Actualizado' : 'Agregado');

      await handleFetch(0);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleDelete = async (id: string) => {
    handleCancelModal();
    setLoading && setLoading(true);
    console.log(id);
    try {
      let responseDelete = await deleteSubElement(id);
      if (responseDelete) {
        setSuccessMsg && setSuccessMsg(responseDelete);
      }
      setLoading && setLoading(false);
      IDSecondSingleFactor && (await handleFetch(page));
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      IDSecondSingleFactor && (await handleFetch(customPage));
    }
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case 'create':
        await handleAddSubElement(data);
        break;
      case 'update':
        await handleAddSubElement(data);
        break;
      case 'delete':
        //@ts-ignore
        await handleDelete(modalData?.modalObject.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper
        pagemenutitle='Mantenimiento'
        prevpages={[
          {
            linkprevpage: `/dashboard/factorsManagement`,
            name: 'Factores',
          },
          {
            linkprevpage: `/dashboard/singleFactor/${IDSingleFactor}/${nameSingleFactor}`,
            name: nameSingleFactor,
          },
        ]}
        pagetitle={`${nameSecondSingleFactor}`}>
        {companyIndexDB === null ||
        companyIndexDB?.company === null ||
        companyIndexDB?.company?.length === 0 ? (
          <ErrorAlert message='Debe seleccionar primero compañía y subcompañía' />
        ) : (
          <>
          {subCompanyData?.length > 0 &&
              companyIndexDB?.subCompany === null && (
                <ErrorAlert message="La compañía tiene subcompañías asociadas, debe seleccionar una de ellas" />
              )}
            <Grid container spacing={4} sx={{ pb: 8 }}>
              <Grid item xs={12} sx={{ my: 3 }}>
                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleOpenModal}
                    data-name='create'
                    disabled={defaultRiskData}>
                    Agregar
                    <PlusIcon sx={{ ml: 1 }} />
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                {!!subElements.content && !!subElements.content.length ? (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>ID</TableCell>
                        <TableCell align='center'>Nombre</TableCell>
                        <TableCell align='center'>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subElements.content.map((item: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell align='center'>{item.id}</TableCell>
                          <TableCell align='center'>{item.name}</TableCell>
                          <TableCell align='center'>
                            <UpdateButton
                              dataID={item.id}
                              dataName={item.name}
                              handleOpenModal={handleOpenModal}
                            />
                            <DeleteButton
                              dataID={item.id}
                              dataName={item.name}
                              handleOpenModal={handleOpenModal}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                ) : (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>Id</TableCell>
                        <TableCell align='center'>Nombre</TableCell>
                        <TableCell align='center'>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' colSpan={3}>
                          No hay elementos
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
            {!!subElements.content && !!subElements.content?.length && 
             (
                <FancyTablePagination
                  count={subElements?.content?.length}
                  rowsPerPage={subElements?.size}
                  page={page}
                  onPageChange={handleChangePage}
                  totalElements={subElements?.totalElements}
                  totalPages={subElements?.totalPages}
                  numberOfElements={subElements?.numberOfElements ?? 0}
                />
              )}
          </>
        )}
      </FancyPaper>
      {(modalData?.modalType === 'create' || modalData?.modalType === 'update') && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <AddOrUpdateSubElements
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            factorID={IDSecondSingleFactor && IDSecondSingleFactor}
          />
        </Dialog>
      )}
      {modalData?.modalType === 'delete' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject.name}
            actionButton={
              <>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={onSubmit}>
                  Aceptar
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus>
                  Cancelar
                </Button>{' '}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SecondLevelSingleFactor;
