import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: "2rem",
  marginTop: theme.spacing(3),
}));

const ButtonPrimary = ({ type, children, ...props }: any) => {
  return (
    <CustomButton type={type} variant="contained" color="primary" {...props}>
      {children}
    </CustomButton>
  );
};

export default ButtonPrimary;
