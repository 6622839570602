import { Controller, Control, ValidationRule } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import DatePicker, { ReactDatePickerProps} from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { CONSTANT } from "../../../../utils/Constants";
import InputLabelToForm from "../../../../components/InputLabelToForm";

interface IDateInputProps extends Omit<ReactDatePickerProps, "name" | "required"| "onChange"> {
    control: Control<any>;
    errors: any;
    name: string;
    label?: string;
    required?: string | ValidationRule<boolean> | undefined;
};
export const DateInput = ({ name, label, errors, control, required, ...rest}: IDateInputProps) => {
    registerLocale("es", es);
    return (
        <Controller
              name={name}
              control={control}
              defaultValue={new Date()}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors[name] && Boolean(errors[name].message)}
                  >
                    { label }
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    selected={value}
                    onChange={(date) => {
                      onChange(date);
                    }}
                    dateFormat={CONSTANT.DATE_FORMAT}
                    popperModifiers={[
                      {
                        name: "preventOverflow",
                        options: {
                          rootBoundary: "viewport",
                          tether: false,
                          altAxis: true,
                        },
                      },
                    ]}
                   { ...rest}
                  />
                </FormControl>
              )}
              {...(errors[name] && (
                <FormHelperText>{errors[name].message}</FormHelperText>
              ))}
            />
    );
};