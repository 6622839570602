import { URL } from "../../utils/UrlConstants";
import { privateFetch, privateFetchTest } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";

export const getAllRubros = async (page:  number, size: number, filter: string) => {
  let responseData = {};  
  console.log(filter)
  await privateFetchTest
    .get(
      URL.REQUEST_RUBRO_LIST(page, size, filter)
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      //throw new Error(properties.com_parval_label_role_find_error);
      throw new Error(error);
    });
  return responseData;
};

export const getRubroWithUrl = async (url:string, size: number, page: number) => {
  let responseData = {};
  await privateFetchTest
    .get(URL.REQUEST_RUBRO_WITH_URL(url, size, page), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const getRubroFindId = async (url:string, id: string) => {
  let responseData = {};
  await privateFetchTest
    .get(URL.REQUEST_RUBRO_WITH_URL_ID(url, id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const createRubro = async (url: string , data:any, ) => {
  const dataBody = {
    ...data,
    type: "J",
    //weight: 0,
  };
  
  try {
    const response = await privateFetchTest.post(
      URL.REQUEST_CREATE_RUBRO(url),
      dataBody,
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error al crear el rubro");
  }
};


export const updateRubro = async (url : string, data:any) => {
  const dataBody = {
    ...data,
    type: data.type,
    //type: "J",
    weight: 0,
  };
  
  try {
    const response = await privateFetchTest.put(
      URL.REQUEST_UPDATE_RUBRO(url, data.id),
      dataBody,
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error al crear el rubro");
  }
};


export const deleteRubro = async (url: string ,id:any) => {
  let responseData = {};
  await privateFetchTest
    .delete(URL.REQUEST_DELETE_RUBRO(url,id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error)
    });
  return responseData;
};
