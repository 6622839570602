import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequestEmployeeList = async (
  page: any,
  filter: any,
  companyId: any,
  subcompanyId: any
) => {
  let responseData = {};
  let params;
  //console.log("getRequest filter=",filter);
  params = new URLSearchParams([["filter", filter]]);
  if (filter === "") params = new URLSearchParams([["ref", filter]]);

  const pageSize: any = 100000;

  await privateFetch
    .get(URL.REQUEST_EMPLOYEES(page, pageSize, companyId, subcompanyId), {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequest = async (
  page: any,
  filter: any,
  companyId: any,
  subcompanyId: any
) => {
  let responseData = {};
  let params;
  //console.log("getRequest filter=",filter);
  params = new URLSearchParams([["filter", filter]]);
  if (filter === "") params = new URLSearchParams([["ref", filter]]);

  await privateFetch
    .get(
      URL.REQUEST_EMPLOYEES(
        page,
        UrlBase.registration_x_page,
        companyId,
        subcompanyId
      ),
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_EMPLOYEES_BYID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const createRequestEmployee = async (data: any) => {
  let responseData = {};

  //return [];
  await privateFetch
    .post(URL.REQUEST_EMPLOYEES_CREATE, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  /*
  const requestBody = {
    id: data.id,
  };
  */
  console.log("updateRequest data", data);
  await privateFetch
    .put(URL.REQUEST_EMPLOYEES_UPDATE(data.id), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_EMPLOYEES_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error);
      throw new Error(error.response.data.message);
      //throw new Error(properties.com_mapprisk_label_role_delete_error);
    });
  return responseData;
};
