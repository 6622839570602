import React from "react";
import {
  Controller,
  Control,
  UseFormRegisterReturn,
  ValidationRule,
} from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import { IMaskInput } from "react-imask";

interface ITextMaskCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, ITextMaskCustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        lazy={true}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

type TMaskType = "phone" | "idNumber" | "currency";

interface IMaskInputProps extends Omit<TextFieldProps, "name" | "required"> {
  maskType: TMaskType;
  control: Control<any>;
  register: UseFormRegisterReturn<any>;
  name: string;
  errors: any;
  required?: string | ValidationRule<boolean> | undefined;
}

export const MaskInput = ({
  maskType,
  name,
  control,
  register,
  required,
  errors,
  ...rest
}: IMaskInputProps) => {
  const masks = {
    phone: "(#00)-000-0000",
    idNumber: "00-000-000-000",
    currency: Number,
  };

  const definitions = {
    phone: {
      "#": /[2-9]/,
    },
    currency: {
      "#": /[1-9]/,
    },
    idNumber: {
      "#": /[0-9]/,
    },
  };

  const props = {
    currency: {
      scale: 2, // digits after point, 0 for integers
      thousandsSeparator: "", // any single char
      padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
      normalizeZeros: true, // appends or removes zeros at ends
      radix: ",", // fractional delimiter
      mapToRadix: ["."],
      
    },
    phone: {},
    idNumber: {}
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange: onTextChange, value } }) => (
        <TextField
          variant="standard"
          value={value}
          sx={{
            "& input": { pl: "0!important" },
          }}
          InputProps={{
            inputComponent: TextMaskCustom as any,
            inputProps: {
              ...props[maskType],
              mask: masks[maskType],
              definitions: definitions[maskType],
            },
          }}
          // Custom attribute
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          onChange={onTextChange}
          {...rest}
        />
      )}
    />
  );
};
