import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";

export const getRequestUserMatriz = async (company: string, subCompany: string, filter: string) => {
  let responseData: any;
  const params = new URLSearchParams([
    ["subCompany", subCompany ?? ""],
    ["filter", filter],
  ]);

  let keysParams: any = [];
  params.forEach((value, key) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });
  keysParams.forEach((key: any) => {
    params.delete(key);
  });

  await privateFetch
    .get(URL.REQUEST_USER_LIST("0", "1000", company), {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};
