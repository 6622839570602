import { Box, Grid, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation.schema";
import {
  AutocompleteInput,
  BaseInput,
  CheckGroupInput,
  MaskInput,
  SelectInput,
  RadioGroupInput,
} from "../../Input";
import { DateInput } from "../../Input/DateInput";

export const KycFourthPannel = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      institutionName: "",
      account: "",
      contact: "",
      phone: "",
    },
  });

  const formSubmit = (data: any) => {
    console.log({ data });
  };

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <form onSubmit={handleSubmit(formSubmit)} style={{paddingLeft: 16}}>
        <Grid container spacing={8} sx={{ pt: 0, pb: 0 }}>
          {/* First column */}

          <Grid item xs={12} md={3} sx={{ mt: 2 }}>
            <BaseInput
              name="institutionName"
              control={control}
              errors={errors}
              register={register("institutionName")}
              label="Nombre institución"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ mt: 2 }}>
            <BaseInput
              name="account"
              control={control}
              errors={errors}
              register={register("account")}
              label="No. Cuenta"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ mt: 2 }}>
            <BaseInput
              name="contact"
              control={control}
              errors={errors}
              register={register("contact")}
              label="Contacto"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ mt: 2 }}>
            <MaskInput
              maskType="phone"
              name="phone"
              label="Teléfono"
              fullWidth
              control={control}
              errors={errors}
              register={register("phone")}
            />
          </Grid>
          {/* Second column */}
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          ></Grid>
        </Grid>
      </form>
    </Box>
  );
};
