export const getInitialValues = (
  methodology: string,
  type: string,
  companyId: any,
  subcompanyId: any
) => {
  // export const getInitialValues = (data: RiskLevel) => {

  let details;
  let factor;

  switch (type) {
    case "1":
      switch (methodology) {
        case "1":
          details = [
            {
              id: 1,
              from: 1,
              to: 3,
              caption: "Muy bajo",
              color: null,
            },
            {
              id: 2,
              from: 4,
              to: 6,
              caption: "Bajo",
              color: null,
            },
            {
              id: 3,
              from: 7,
              to: 9,
              caption: "Medio",
              color: null,
            },
          ];
          break;
        case "2":
          details = [
            {
              id: 1,
              from: 1,
              to: 4,
              caption: "Muy bajo",
              color: null,
            },
            {
              id: 2,
              from: 5,
              to: 8,
              caption: "Bajo",
              color: null,
            },
            {
              id: 3,
              from: 9,
              to: 12,
              caption: "Medio",
              color: null,
            },
            {
              id: 3,
              from: 13,
              to: 16,
              caption: "Alto",
              color: null,
            },
          ];
          break;
        case "3":
          details = [
            {
              id: 1,
              from: 1,
              to: 5,
              caption: "Muy bajo",
              color: null,
            },
            {
              id: 2,
              from: 6,
              to: 10,
              caption: "Bajo",
              color: null,
            },
            {
              id: 3,
              from: 11,
              to: 15,
              caption: "Medio",
              color: null,
            },
            {
              id: 3,
              from: 16,
              to: 20,
              caption: "Alto",
              color: null,
            },
            {
              id: 3,
              from: 21,
              to: 25,
              caption: "Muy Alto",
              color: null,
            },
          ];
          break;
        default:
          return null;
      }
      break;
    case "2":
      switch (methodology) {
        case "1":
          details = [
            {
              id: 1,
              from: 1,
              to: 33,
              caption: "Muy bajo",
              color: null,
            },
            {
              id: 2,
              from: 34,
              to: 66,
              caption: "Bajo",
              color: null,
            },
            {
              id: 3,
              from: 67,
              to: 100,
              caption: "Medio",
              color: null,
            },
          ];
          break;
        case "2":
          details = [
            {
              id: 1,
              from: 1,
              to: 25,
              caption: "Muy bajo",
              color: null,
            },
            {
              id: 2,
              from: 26,
              to: 50,
              caption: "Bajo",
              color: null,
            },
            {
              id: 3,
              from: 51,
              to: 75,
              caption: "Medio",
              color: null,
            },
            {
              id: 3,
              from: 76,
              to: 100,
              caption: "Alto",
              color: null,
            },
          ];
          break;
        case "3":
          details = [
            {
              id: 1,
              from: 1,
              to: 20,
              caption: "Muy bajo",
              color: null,
            },
            {
              id: 2,
              from: 21,
              to: 40,
              caption: "Bajo",
              color: null,
            },
            {
              id: 3,
              from: 41,
              to: 60,
              caption: "Medio",
              color: null,
            },
            {
              id: 3,
              from: 61,
              to: 80,
              caption: "Alto",
              color: null,
            },
            {
              id: 3,
              from: 81,
              to: 100,
              caption: "Muy Alto",
              color: null,
            },
          ];
          break;
        default:
          return null;
      }
      break;
    default:
      return null;
  }

  switch (methodology) {
    case "1":
      factor = 11.1;
      break;
    case "2":
      factor = 6.25;
      break;
    case "3":
      factor = 4;
      break;
    default:
      return null;
  }

  return {
    id: 0,
    company: companyId,
    subcompany: subcompanyId,
    type: type,
    methodology: methodology,
    details: details,
    factor: factor,
  };
};
