import { Controller, Control, ValidationRule } from "react-hook-form";
import {
  Autocomplete,
  TextField,
  AutocompleteProps,
  TextFieldProps,
} from "@mui/material";

interface IAutocompleteInputProps
  extends Pick<
    AutocompleteProps<any, any, any, any>,
    "getOptionLabel" | "isOptionEqualToValue"
  > {
  control: Control<any>;
  errors: any;
  data: any;
  name: string;
  required?: string | ValidationRule<boolean>;
  inputProps?: Omit<TextFieldProps, "variant" | "helperText" | "error" | "sx">;
  disabled?: boolean;
}

export const AutocompleteInput = ({
  control,
  errors,
  data,
  name,
  getOptionLabel,
  isOptionEqualToValue,
  inputProps,
  required,
  disabled,
}: IAutocompleteInputProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          onChange={(event, newValue: any) => {
            onChange(newValue);
          }}
          options={data}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          fullWidth
          //@ts-ignore
          value={value}
          disabled={disabled ?? false}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                sx={{
                  "& input": { pl: "0!important" },
                }}
                error={!!errors.employeeId}
                helperText={errors.employeeId && errors.employeeId.message}
                {...inputProps}
              />
            );
          }}
        />
      )}
    />
  );
};
