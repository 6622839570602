import { Typography, useTheme, Stack, Paper, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { MappRiskLogo } from "./svgicons/SvgIcons";
import MessageManager from "./utils/MessageManager";
import Loading from "./utils/Loading";
import Footer from "./Footer";
import TitleForm from "./TitleForm";
import { CONSTANT } from "../utils/Constants";
import { useApp } from "../hooks/useApp";
import Logo from "../resources/images/mapprisk_logo.svg"

import Background from "../resources/images/login-background.png";
// import Background2 from "../resources/images/login-background-2.png";
import Background3 from "../resources/images/login-background-3.png";

import { useState } from "react";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  minHeight: "100vh",
  padding: "0", // Elimina el margen
  overflowX: "hidden",
}));

const BackgroundContainer = styled("div")(({ theme }) => ({
  flex: "0 0 50%", // Ocupar el 50% de la pantalla
  position: "relative",
  height: "100vh",
  // background: `linear-gradient(45deg, rgba(67,164,145,0.26) 0%, rgba(67,164,145,0.5) 100%), url(${Background3})`,
  background: `linear-gradient(45deg, rgba(68, 137, 123, 0.26) 0%, rgba(121, 220, 200, 0.5) 100%), url(${Background3})`,

  backgroundSize: "cover",
  backgroundPosition: "0%",
  zIndex: "-1",
}));

const ContentContainer = styled("div")(({ theme }) => ({
  flex: "1", // Ocupar el espacio restante
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
}));

const Image = styled("img")(() => ({
  width: "80px",
}));

interface PaperSessionsType {
  children: any;
  to?: string;
  textLink?: string;
  titleForm?: string;
}

const PaperSessions = ({
  children,
  to,
  textLink,
  titleForm,
}: PaperSessionsType) => {
  const theme = useTheme();
  const { isLoading, successMsg, errorMsg, resetErrorMsg, resetSuccessMsg } =
    useApp();

  // // Estado para rastrear la imagen de fondo actual
  const [backgroundImage, setBackgroundImage] = useState(Background3);

  // // Función para cambiar la imagen de fondo al hacer clic en el logo
  // const toggleBackgroundImage = () => {
  //   setBackgroundImage((prevImage: string) =>
  //     prevImage === Background ? Background2 : Background
  //   );
  // };

  const toggleBackgroundImage = () => {
    setBackgroundImage((prevImage) =>
      prevImage === Background3 ? Background : Background3
    );
  };

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}
      <Container>
      {/* <BackgroundContainer
        style={{ background: `linear-gradient(45deg, rgba(67,164,145,0.26) 0%, rgba(67,164,145,1) 100%), url(${backgroundImage})` }}
      /> */}
        <BackgroundContainer
        // style={{ background: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "0% 100%", }}
      />
        <ContentContainer>
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems={"center"}
            sx={{
              position: {
                md: "absolute",
              },
              left: {
                md: 0,
              },
              top: {
                md: "10px",
              },
            }}
          >
          <Image src={Logo} alt="MappRisk" sx={{width:"80px", marginX: "1rem"}} onClick={toggleBackgroundImage} />
            <Typography
              variant="h5"
              sx={{
                fontSize: "1.5rem",
                color: "#DD9807",
              }}
            >
              MappRisk Compliance
            </Typography>
          </Stack>
          <Paper
            sx={{
              width: "430px",
              maxWidth: "100%",
              borderRadius: "25px",
              background: theme.palette.primary.contrastText,
              boxShadow:
                "0px 1px 1px 1px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
              marginBottom: `${theme.spacing(3)}!important`,
              padding: {
                xs: theme.spacing(8),
                md: `${theme.spacing(8)} ${theme.spacing(12)}`,
              },
            }}
          >
            {titleForm && <TitleForm>{titleForm}</TitleForm>}
            {children}
            {textLink && to && (
              <Typography align="center" sx={{ mt: 6 }} variant="body1">
                <LinkTo to={to}>{textLink}</LinkTo>
              </Typography>
            )}
          </Paper>
          <Footer />
        </ContentContainer>
      </Container>
    </>
  );
};

export default PaperSessions;
