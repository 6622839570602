import { IconButton, useTheme } from "@mui/material";
import FancyTooltip from "../FancyTooltip";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

interface ItfButton {
  title?: string;
  dataID: string;
  dataName?: string;
  handleOpenModal?: any;
  disabled?: boolean;
}

const AddEmployeeButton = ({
  title,
  dataID,
  dataName,
  handleOpenModal,
  disabled,
}: ItfButton) => {
  const theme = useTheme();
  return (
    <FancyTooltip title={title ?? "Agregar Empleado"} placement="top">
      <IconButton
        aria-label="edit"
        component="label"
        color="primary"
        disabled={disabled ?? false}
        sx={{
          "&:hover": {
            color: theme.palette.secondary.dark,
          },
        }}
        onClick={handleOpenModal}
        data-name="addEmployee"
        data-id={dataID}
      >
        <PersonAddIcon sx={{ fontSize: 20 }} />
      </IconButton>
    </FancyTooltip>
  );
};

export default AddEmployeeButton;
