import { Controller, Control, UseFormRegisterReturn } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";

interface IBaseInputProps extends Omit<TextFieldProps, "name"> {
  control: Control<any>;
  errors: any;
  name: string;
  register: UseFormRegisterReturn<any>;
}

export const BaseInput = ({
  control,
  errors,
  name,
  required,
  register,
  ...rest
}: IBaseInputProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <TextField
          variant="standard"
          value={value}
          {...register}
          sx={{
            "& input": { pl: "0!important" },
          }}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          {...rest}
        />
      )}
    />
  );
};
