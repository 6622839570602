import { Controller, Control, UseFormRegisterReturn } from "react-hook-form";
import {
  Select,
  FormControl,
  SelectProps,
  MenuItem,
  FormHelperText,
  InputLabel,
} from "@mui/material";

type TData = {
  id: string;
  name: string;
};

interface ISelectInputProps {
  control?: Control<any>;
  errors: any;
  data: TData[];
  name: string;
  label?: string;
  selectProps?: Omit<SelectProps, "variant" | "helperText" | "error" | "sx">;
  register?: UseFormRegisterReturn<any>;
  uncontrolled?: boolean;
}

export const SelectInput = ({
  control,
  selectProps,
  register,
  data,
  errors,
  name,
  label,
  uncontrolled,
}: ISelectInputProps) => {
  return (
    <>
      {!uncontrolled ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth style={{ minWidth: 48 }}>
              {label ? (
                <InputLabel id={`${name}-select-label`} sx={{ left: "-14px" }}>
                  {label}
                </InputLabel>
              ) : null}
              <Select
                // disabled
                labelId={`${name}-select-label`}
                id={`${name}-select`}
                variant="standard"
                label={label}
                value={value}
                sx={{ "& select": { pl: "0!important", minWidth: 28 } }}
                style={{ minWidth: 28 }}
                {...selectProps}
                {...register}
              >
                {data.map((item: any) => (
                  <MenuItem key={item.id} value={item.id} data-ana={item.id}>
                    {item.id}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!errors[name]}>
                {errors[name] && errors[name].message}
              </FormHelperText>
            </FormControl>
          )}
        />
      ) : (
        <FormControl fullWidth style={{ minWidth: 48 }}>
          {label ? (
            <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
          ) : null}
          <Select
            // disabled
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            variant="standard"
            label={label}
            sx={{ "& select": { pl: "0!important", minWidth: 28 } }}
            style={{ minWidth: 28 }}
            {...selectProps}
          >
            {data.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!errors[name]}>
            {errors[name] && errors[name].message}
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};
