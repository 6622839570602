import { useEffect, useState } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  IconButton,
  useTheme,
  FormControl,
  Avatar,
  Autocomplete,
  Typography,
  Divider,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyTooltip from "../../components/utils/FancyTooltip";
import { deleteRequestSubcompany } from "../../services/subCompany/SubCompanyServices";
import { TrashIcon, PlusIcon } from "../../components/svgicons/SvgIcons";
import { useApp } from "../../hooks/useApp";
import { findDocuments } from "../../services/company/CompanyService";
import {
  getCompanyScreening,
  getCompanyScreeningRelation,
} from "../../services/company/ScreeningCompanyRelation";
import { UrlBase } from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdate {
  id?: number;
  description: string;
  numberInquiries?: number;
  documents?: File | null;
  scrnCompany?: CompanyOption;
}

interface screeningRelatioType {
  id: number;
  companyId: number;
  subCompanyId: number;
  scrnCompanyId: number;
  scrnSubcompanyId: number;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

interface ItemType {
  description: string;
  numberInquiries: number;
  file: File | null;
  screeningCompanyId: number;
  screeningSubCompanyId: number;
  screeningNumberInquiries: number | null;
  scrnSubcompany: CompanyOption;
}

interface CountryOption {
  id: number;
  name: string;
}

interface CompanyOption extends CountryOption {}

const AddCompanyModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { setLoading, setErrorMsg, setSuccessMsg, errorMsg } = useApp();
  const [file, setFile] = useState<any>(null);
  const [fileUploaded, setFileUploaded] = useState<any>(null);
  const [companiesDataState, setCompaniesDataState] = useState<any[]>([]);
  const [subCompaniesDataState, setSubCompaniesDataState] = useState<any[]>([]);
  const [filterSubCompaniesDataState, setFilterSubCompaniesDataState] =
    useState<any[]>([]);
  const [relationCompanyScreening, setRelationCompanyScreening] =
    useState<screeningRelatioType | null>(null);

  //Validación de datos
  const theme = useTheme();

  //const [items, setItems] = useState<ItemType[] | null>(data.addSubCompanies);
  const [items, setItems] = useState<ItemType[] | null>(null);
  const [numberInquirieReadOnly, setNumberInquirieReadOnly] = useState(
    data.addSubCompanies.length
  );
  let formatItems = data.addSubCompanies;
  const [iCount, setICount] = useState<number>(0);

  const validation = Yup.object().shape({
    description: Yup.string().required("Campo es requerido"),
    numberInquiries: Yup.number()
      .required("Campo es requerido")
      .min(1, "El número debe ser mayor que cero")
      .typeError("El campo debe ser numérico"),
    scrnCompany: Yup.object()
      .shape({ id: Yup.number(), name: Yup.string() })
      .nullable()
      .required("Campo es requerido"),
  });

  //console.log("items: ", items);

  const defaultValues = {
    id: data.id ? data.id : 0,
    description: data.description ? data.description : "",
    numberInquiries: data.numberInquiries ? data.numberInquiries : 0,
    documents: null,
    addSubCompanies: [],
    //scrnCompany: data.scrnCompany ? data.scrnCompany : null,
    scrnCompany: companiesDataState
      ? data.addSubCompanies.length > 0
        ? companiesDataState.find(
            (i: any) => i.id === data.addSubCompanies[0].screeningCompanyId
          )
        : companiesDataState.find((i: any) => i.id === data.screeningCompanyId)
      : null,
  };

  useEffect(() => {
    const getDataScreeningCompanies = async () => {
      const companiesData = await getCompanyScreening();
      if (companiesData.companies) {
        setCompaniesDataState(companiesData.companies);
        setSubCompaniesDataState(companiesData.subCompanies);
      }
    };
    getDataScreeningCompanies();
  }, []);

  useEffect(() => {
    const getDataDocument = async () => {
      await handleFetchDocumentByCompany(data.id);
      formatItems.forEach(handleFetchDocumentBySubCompanies);
    };
    if (data.id !== 0) {
      getDataDocument();
    }
  }, [filterSubCompaniesDataState]);

  useEffect(() => {
    if (iCount >= formatItems.length) {
      setItems(formatItems);
    }
  }, [iCount]);

  const handleFetchDocumentByCompany = async (id: string) => {
    try {
      let getDocument: any = await findDocuments(id);
      if (getDocument) {
        if (getDocument[0]?.systemName) {
          setFileUploaded(getDocument[0]);
        }
      } else {
        setFileUploaded(null);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleFindScreeningSubCompany = async (id: number) => {
    const screeningSubCompany = await filterSubCompaniesDataState.find(
      (fsub: any) => fsub.id === id
    );
    return screeningSubCompany;
  };

  const handleFetchDocumentBySubCompanies = async (item: any) => {
    console.log("item nuevo: ", item);

    // Llamamos a la función findDocuments()
    const response: any = await findDocuments(item.companyId.id, item.id);
    // Buscamos la subcompañía de Screening
    console.log("filterSubCompaniesDataState: ", filterSubCompaniesDataState);
    /* const setScreeningSubCompany = await filterSubCompaniesDataState.find(
      (fsub: any) => fsub.id === item.screeningSubCompanyId
    ); */
    const setScreeningSubCompany = await handleFindScreeningSubCompany(
      item.screeningSubCompanyId
    );

    // Agregamos la respuesta a la propiedad file del elemento
    if (response.length > 0) {
      item.oldFile = response[0];
      item.file = null;
    } else {
      item.oldFile = null;
      item.file = null;
    }

    console.log("setScreeningSubCompany: ", setScreeningSubCompany);
    item.scrnSubcompany = setScreeningSubCompany;
    console.log("item actualizado: ", item);
    setICount((prevState) => prevState + 1);
  };

  const addItem = async () => {
    try {
      const newItem = {
        id: 0,
        description: "",
        numberInquiries: 0,
        error: 0,
        errorMsg: "",
        errorConsultas: 0,
        errorMsgConsultas: "",
        file: null,
        oldFile: null,
        scrnSubcompany: {
          company: 0,
          id: 0,
          isCRC: null,
          isJCE: null,
          name: "",
        },
        errorScrnSubcompany: 0,
        errorMsgScrnSubcompany: "",
        screeningCompanyId: 0,
        screeningNumberInquiries: 0,
        screeningSubCompanyId: 0,
      };
      if (items !== null) {
        const addItems = [...items, newItem];
        setItems(addItems);
        setNumberInquirieReadOnly(true);
        const sum = Object.values(addItems).reduce(
          (acc, currentItem) => acc + currentItem.numberInquiries,
          0
        );

        resetField("numberInquiries", {
          defaultValue: sum,
        });
      }
    } catch (error: any) {
      console.log("handleAddSubCompanies error=", error.message);
    }
  };
  const removeItem = async (index: number) => {
    try {
      const deletedItems: any = items?.filter((_, i) => i !== index);

      if (deletedItems?.length === 0) {
        setNumberInquirieReadOnly(false);
        resetField("numberInquiries", {
          defaultValue: 0,
        });
      } else {
        const sum = Object.values(deletedItems).reduce(
          (acc, currentItem: any) => acc + currentItem?.numberInquiries,
          0
        );

        resetField("numberInquiries", {
          defaultValue: sum,
        });
      }
      setItems(deletedItems);

      //enviar endpoint de eliminar======
      //@ts-ignore
      const idSubcompany = items[index].id;
      if (idSubcompany !== 0) {
        let deleteData = await deleteRequestSubcompany(idSubcompany);
      }
    } catch (error: any) {
      console.log("removeItem error=", error.message);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /* const getImageData = async (newName: File) => {
    let imageData: any = await newName
      .arrayBuffer()
      .then((buffer: any) => new Image(buffer));
    return imageData;
  }; */

  const updateItemName = async (index: number, label: any, newName: any) => {
    if (items !== null) {
      const updatedItems = [...items];
      if (label === "description") {
        updatedItems[index].description = newName;

        if (newName !== "") {
          //@ts-ignore
          updatedItems[index].error = false;
          //@ts-ignore
          updatedItems[index].errorMsg = "";
        } else {
          //@ts-ignore
          updatedItems[index].error = true;
          //@ts-ignore
          updatedItems[index].errorMsg = "Campo es requerido";
        }
      }

      if (label === "numberInquiries") {
        updatedItems[index].numberInquiries = parseInt(newName);
        if (newName !== 0) {
          //@ts-ignore
          updatedItems[index].errorConsultas = false;
          //@ts-ignore
          updatedItems[index].errorMsgConsultas = "";
        } else {
          //@ts-ignore
          updatedItems[index].errorConsultas = true;
          //@ts-ignore
          updatedItems[index].errorMsgConsultas = "Campo es requerido";
        }
        let sum = Object.values(items).reduce(
          (acc, currentItem) => acc + currentItem.numberInquiries,
          0
        );
        if (isNaN(sum)) sum = 0;
        resetField("numberInquiries", {
          defaultValue: sum,
        });
      }
      if (label === "file") {
        console.log(newName);
        const allowExt: any = ["jpeg", "jpg", "png"];
        updatedItems[index].file = newName;

        if (newName) {
          const fileName = newName.name.split(".").pop().toUpperCase();
          const isAllowExt = allowExt.some(
            (ext: any) => ext.toUpperCase() === fileName.toUpperCase()
          );
          const imgUrl: any = window.URL.createObjectURL(new Blob([newName]));
          const img = new Image();
          img.src = imgUrl;
          let width: any;
          let height: any;

          if (!isAllowExt) {
            //@ts-ignore
            updatedItems[index].errorFile = true;
            //@ts-ignore
            updatedItems[
              index //@ts-ignore
            ].errorMsgFile = `Tipo de archivo no permitido solo ${JSON.stringify(
              //@ts-ignore
              allowExt
            ).replace(/[^A-Z,/]+/gi, " ")}`;
          } else {
            img.onload = () => {
              width = img.naturalWidth;
              height = img.naturalHeight;
              if (width !== height) {
                //@ts-ignore
                updatedItems[index].errorFile = true;
                //@ts-ignore
                updatedItems[index].errorMsgFile =
                  "La proporción de logo debe ser 1:1, cuadrado";

                return false;
              }
              return true;
            };
            //@ts-ignore
            updatedItems[index].errorFile = false;
            //@ts-ignore
            updatedItems[index].errorMsgFile = "";
          }
        }
      }

      if (label === "scrnSubcompany") {
        updatedItems[index].scrnSubcompany = newName;
        updatedItems[index].screeningSubCompanyId = newName.id;
        if (newName) {
          //@ts-ignore
          updatedItems[index].errorConsultas = false;
          //@ts-ignore
          updatedItems[index].errorMsgConsultas = "";
        } else {
          //@ts-ignore
          updatedItems[index].errorConsultas = true;
          //@ts-ignore
          updatedItems[index].errorMsgConsultas = "Campo es requerido";
        }
      }
      console.log("updatedItems: ", updatedItems);
      setItems(updatedItems);
    }
  };
  const changeFile = (newValue: File | null) => {
    console.log(newValue);
  };

  const onSubmit2 = (data: any) => {
    data.addSubCompanies = items;

    console.log("Entra onSubmit2:", data);

    if (items?.length === 0) {
      onSubmit(data);
    } else {
      let bActive = true;
      if (items !== null) {
        for (let i = 0; i < items.length; i++) {
          const obj: any = items !== null && items[i];
          //console.log(obj);
          const description = obj.description;
          if (description.trim() === "") {
            bActive = false;
            //@ts-ignore
            items[i].error = 1;
            //@ts-ignore
            items[i].errorMsg = "Campo es requerido";
          } else {
            //@ts-ignore
            items[i].error = 0;
            //@ts-ignore
            items[i].errorMsg = "";
          }
          if (obj.numberInquiries === 0 || isNaN(obj.numberInquiries)) {
            bActive = false;
            //@ts-ignore
            items[i].errorConsultas = 1;
            //@ts-ignore
            items[i].errorMsgConsultas = "Campo es requerido";
          } else {
            //@ts-ignore
            items[i].errorConsultas = 0;
            //@ts-ignore
            items[i].errorMsgConsultas = "";
          }
          //@ts-ignore
          if (items[i].errorFile) {
            bActive = false;
            //@ts-ignore
            items[i].errorFile = true;
            //@ts-ignore
            items[i].errorMsgFile =
              "La proporción de logo debe ser 1:1, cuadrado";
            console.log(obj.file);
            console.log(items[i]);
          } else {
            //@ts-ignore
            items[i].errorFile = false;
            //@ts-ignore
            items[i].errorMsgFile = "";
          }
        }
      }
      //console.log(bActive);
      if (bActive) console.log("data: ", data);
      if (bActive) onSubmit(data);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    watch,
    reset,
  } = useForm<AddOrUpdate>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const watch_logo: any = watch("documents");
  const watch_scrnCompany: any = watch("scrnCompany");
  const watch_id_company: any = watch("id");

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line
  }, [companiesDataState]);

  useEffect(() => {
    if (watch_scrnCompany?.id !== 0) {
      let filterSubCompanies = subCompaniesDataState.filter(
        (item: any) => Number(item.company) === watch_scrnCompany?.id
      );
      setFilterSubCompaniesDataState(filterSubCompanies);
    }
  }, [watch_scrnCompany]);

  //seguramente borrar
  /*  useEffect(() => {
    console.log("watch_id_company: ", watch_id_company);
    let getRelationScreening = async () => {
      let getRelation: any = await getCompanyScreeningRelation(
        watch_id_company
      );
      console.log("getRelation: ", getRelation);
      setRelationCompanyScreening(getRelation);
    };

    getRelationScreening();
  }, [watch_id_company]); */

  useEffect(() => {
    const allowExt: any = ["jpeg", "jpg", "png"];
    if (watch_logo !== null && watch_logo?.name) {
      const fileName = watch_logo.name.split(".").pop().toUpperCase();
      const isAllowExt = allowExt.some(
        (ext: any) => ext.toUpperCase() === fileName.toUpperCase()
      );
      if (!isAllowExt) {
        setErrorMsg &&
          setErrorMsg(
            `Tipo de archivo no permitido <br>Solo ${JSON.stringify(
              allowExt
            ).replace(/[^A-Z,/]+/gi, " ")}`
          );
        setFile(null);
        resetField("documents");
      }
      if (watch_logo.size > 5e6) {
        setErrorMsg &&
          setErrorMsg(
            `Tamaño de archivo no permitido, <br>El archivo supera el tamaño máximo permitido de 10Mb`
          );
        resetField("documents");
        setFile(null);
      }
      setFile(window.URL.createObjectURL(new Blob([watch_logo])));
    }
  }, [watch_logo]);

  useEffect(() => {
    if (items !== null && items.length > 0) {
      setFile(null);
      resetField("documents", {
        defaultValue: null,
      });
    }
  }, [items]);

  return (
    <>
      <DialogTitle
        id="alert-dialog-title"
        sx={{ pt: 4, px: 4, position: "relative" }}
      >
        {modalType === "update"
          ? properties.com_mapprisk_label_company_button_update_title
          : properties.com_mapprisk_label_company_button_add_title}
        {items?.length === 0 && (
          <Avatar
            variant="square"
            sx={{
              position: "absolute",
              right: "24px",
              top: "50%",
              transform: "translateY(-50%)",
              bgcolor: "#fff",
              color: theme.palette.info.dark,
            }}
          >
            {file !== null ? (
              <img src={file} style={{ width: "100%" }} />
            ) : fileUploaded !== null ? (
              <img
                src={`${
                  UrlBase.base_url +
                  UrlBase.api_context +
                  CONSTANT.DISPATCHER_MAINTENANCE_PREFIX
                }/documents/view/${fileUploaded?.id}/?originalName=${
                  fileUploaded?.systemName
                }`}
                style={{ width: "100%" }}
              />
            ) : (
              <>N</>
            )}
          </Avatar>
        )}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={4}>
              <Controller
                name={"documents"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl>
                    <MuiFileInput
                      value={value}
                      onChange={onChange}
                      hideSizeText
                      label="Logo"
                      variant="standard"
                      inputProps={{
                        accept: "image/png, image/jpeg, image/jpg",
                      }}
                      disabled={items !== null && items.length > 0}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"numberInquiries"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Número de consultas"
                    type="number"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    //disabled={numberInquirieReadOnly > 0 || false}
                    disabled
                    {...register("numberInquiries")}
                    error={
                      errors.numberInquiries && Boolean(errors.numberInquiries)
                    }
                    helperText={
                      errors.numberInquiries && errors.numberInquiries.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={16}>
              <Controller
                control={control}
                name="scrnCompany"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: CompanyOption | null) => {
                      onChange(newValue);
                    }}
                    options={companiesDataState}
                    getOptionLabel={(option: CompanyOption) => option.name}
                    isOptionEqualToValue={(
                      option: CompanyOption,
                      value: CompanyOption | null
                    ) => {
                      return option.id === value?.id;
                    }}
                    value={value || null}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Seleccione la compañía de Screening"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.scrnCompany}
                          helperText={
                            errors.scrnCompany && errors.scrnCompany.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {items !== null && items.length > 0 && (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" color="secondary.dark">
                    Sub Compañías
                  </Typography>
                  <Divider sx={{ mb: 8 }} />
                </Grid>
              </Grid>
              {items.map((row: any, index: number) => (
                <Grid
                  key={index}
                  container
                  spacing={3}
                  maxWidth="lg"
                  sx={{ pt: 0, pb: 5 }}
                >
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Sub compañía"
                      value={row.description}
                      type="text"
                      onChange={(event) =>
                        updateItemName(index, "description", event.target.value)
                      }
                      variant="standard"
                      sx={{ "& input": { pl: "0!important" } }}
                      error={row.error}
                      helperText={row.errorMsg}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Nro. de consultas"
                      value={
                        row.screeningCompanyId !== 0 &&
                        row.screeningCompanyId !== null
                          ? row.screeningNumberInquiries
                          : 0
                      }
                      type="number"
                      disabled
                      onChange={(event) =>
                        updateItemName(
                          index,
                          "numberInquiries",
                          event.target.value
                        )
                      }
                      variant="standard"
                      sx={{ "& input": { pl: "0!important" } }}
                      error={row.errorConsultas}
                      helperText={row.errorMsgConsultas}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                      display: "flex",
                      alignItems: `${
                        row.errorFile ? "flex-start" : "flex-end"
                      }`,
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "#fff",
                        color: theme.palette.info.dark,
                      }}
                    >
                      {row.file === null && row.oldFile !== null ? (
                        <img
                          src={`${
                            UrlBase.base_url +
                            UrlBase.api_context +
                            CONSTANT.DISPATCHER_MAINTENANCE_PREFIX
                          }/documents/view/${row.oldFile?.id}/?originalName=${
                            row.oldFile?.systemName
                          }`}
                          style={{ width: "100%" }}
                        />
                      ) : row.file !== null ? (
                        <>
                          <img
                            src={window.URL.createObjectURL(
                              new Blob([row.file])
                            )}
                            style={{ width: "100%" }}
                          />
                        </>
                      ) : (
                        <>N</>
                      )}
                    </Avatar>
                    <FormControl>
                      <MuiFileInput
                        value={row.file}
                        onChange={(event) =>
                          updateItemName(index, "file", event)
                        }
                        hideSizeText
                        label="Logo"
                        variant="standard"
                        inputProps={{
                          accept: "image/png, image/jpeg, image/jpg",
                        }}
                        error={row.errorFile}
                        helperText={row.errorMsgFile}
                      />
                    </FormControl>

                    <FancyTooltip title="Eliminar" placement="top">
                      <IconButton
                        aria-label="trash"
                        component="label"
                        color="secondary"
                        sx={{
                          "&:hover": {
                            color: theme.palette.secondary.dark,
                          },
                        }}
                        data-name="delete"
                        style={{ paddingTop: 25 }}
                        onClick={() => removeItem(index)}
                      >
                        <TrashIcon />
                      </IconButton>
                    </FancyTooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      onChange={(event, newValue) =>
                        updateItemName(index, "scrnSubcompany", newValue)
                      }
                      options={filterSubCompaniesDataState}
                      getOptionLabel={(option: CompanyOption) =>
                        option.name ?? ""
                      }
                      disabled={filterSubCompaniesDataState.length === 0}
                      value={row.scrnSubcompany ?? {}}
                      data-ana={row.scrnSubcompany?.id}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Seleccione la sub compañía de Screening"
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={row.errorScrnSubcompany}
                            helperText={row.errorMsgScrnSubcompany}
                          />
                        );
                      }}
                    />
                    {items.length - 1 !== index && (
                      <Divider sx={{ mt: 8, mb: 2 }} />
                    )}
                  </Grid>
                </Grid>
              ))}
            </>
          )}

          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 0, pb: 5 }}>
            <Grid item md={12} sm={12} xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                data-name="create"
                onClick={addItem}
              >
                Agregar sub compañía
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit2)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddCompanyModal;
