import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";

export const getSubElements = async (page: number, optionFactor: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_SUBELEMENTS_TO_OPTIONS(page, 10, optionFactor))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const createSubElement = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.CREATE_SUBELEMENT, [data])
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const deleteSubElement = async (id: string) => {
  let responseData = "";
  await privateFetch
    .delete(URL.DELETE_SUBELEMENT(id))
    .then(async () => {
      responseData = "Elemento eliminado correctamente";
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};