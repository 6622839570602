import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequest = async (
  page: any,
  filter: any,
  companyId: any,
  subcompanyId: any
) => {
  let responseData = {};
  let params;

  params = new URLSearchParams([["filter", filter]]);
  if (filter === "") params = new URLSearchParams([["ref", filter]]);

  await privateFetch
    .get(
      URL.REQUEST_CONTROLTRAINING(
        page,
        UrlBase.registration_x_page,
        companyId,
        subcompanyId
      ),
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_CONTROLTRAINING_BYID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};

  //return [];
  await privateFetch
    .post(URL.REQUEST_CONTROLTRAINING_CREATE, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  /*
  const requestBody = {
    id: data.id,
  };
  */
  console.log("updateRequest data", data);
  await privateFetch
    .put(URL.REQUEST_CONTROLTRAINING_UPDATE(data.id), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_CONTROLTRAINING_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error);
      throw new Error(error.response.data.message);
      //throw new Error(properties.com_mapprisk_label_role_delete_error);
    });
  return responseData;
};

export const getRequestCapacitationEmployee = async (
  page: any,
  filter: any
) => {
  let responseData = {};
  let params;
  console.log("getRequest capacitationId=", filter);
  params = new URLSearchParams([["capacitationId", filter]]);
  if (filter === "") params = new URLSearchParams([["ref", filter]]);

  const pageSize = "100000";

  await privateFetch
    .get(URL.REQUEST_CAPACITATIONEMPLOYEE(page, pageSize), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequestEmployeeCapacitation = async (
  page: any,
  filter: any,
  employeeId: any,
  companyId: any,
  subcompanyId: any,
  trainer: string,
  departmentId: any,
  period: any,
  method: string,
  capacitationId: string
) => {
  let responseData = {};
  let params: any;
  /*  console.log("getRequest filter=", filter);
  params = new URLSearchParams([["filter", filter]]);
  if (filter === "") params = new URLSearchParams([["ref", filter]]); */
  params = new URLSearchParams([
    ["employeeId", employeeId ?? ""],
    ["instructor", trainer ?? ""],
    ["department", departmentId ?? ""],
    ["period", period ?? ""],
    ["method", method ?? ""],
    ["capacitationId", capacitationId ?? ""],
    ["companyId", companyId ?? ""],
    ["subcompanyId", subcompanyId ?? ""],
  ]);

  let keysParams: any = [];
  params.forEach((value: any, key: string) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });

  keysParams.forEach((key: string) => {
    params.delete(key);
  });

  const pageSize = "100000";
  await privateFetch
    .get(
      URL.REQUEST_CAPACITATIONEMPLOYEE2(page, UrlBase.registration_x_page),
      /* URL.REQUEST_CAPACITATIONEMPLOYEE2(
        page,
        pageSize,
        employeeId,
        departmentId,
        period
      ), */
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequestEmployeeCapacitationChart = async (
  capacitationEmployeeId: any
) => {
  let responseData = {};

  await privateFetch
    .get(URL.REQUEST_CAPACITATIONEMPLOYEECHART(capacitationEmployeeId))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const createRequestCapacitationEmployee = async (data: any) => {
  let responseData = {};

  //return [];
  await privateFetch
    .post(URL.REQUEST_CAPACITATIONEMPLOYEE_CREATE, JSON.stringify(data.data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const updateRequestCapacitationEmployee = async (data: any) => {
  let responseData = {};

  await privateFetch
    .put(
      URL.REQUEST_CAPACITATIONEMPLOYEE_UPDATE(data.id),
      JSON.stringify(data),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    )

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const deleteRequestCapacitationEmployee = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_CONTROLTRAININGEMPLOYEE_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error);
      throw new Error(error.response.data.message);
      //throw new Error(properties.com_mapprisk_label_role_delete_error);
    });
  return responseData;
};

export const getCertificate = (id: string) => {};
