import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useData } from '../../hooks/useData';

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

interface AddOrUpdateEmployee {
  id?: number;
  description: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateBranchModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { companyIndexDB } = useData();

  const onSubmit2 = (data: any) => {
    try {
      let bActive = true;
      data.company = parseInt(companyIndexDB.company.id);

      let subcompany = null;
      if (companyIndexDB.subCompany) {
        subcompany = parseInt(companyIndexDB.subCompany.id);
      }
      data.subcompany = subcompany;
      //console.log("onSubmit2 data=",data);

      //bActive=false;
      if (bActive) onSubmit(data);
    } catch (error: any) {
      console.log('setPeriod error=', error.message);
    }
  };

  //Validación de datos

  const validation = Yup.object().shape({
    description: Yup.string().required('Campo es requerido'),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    description: data.description ? data.description : '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateEmployee>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: 'onChange',
  });

  return (
    <>
      <Title id='alert-dialog-title' sx={{ pt: 4, px: 4 }}>
        {modalType === 'update' ? 'Editar sucursal' : 'Agregar nueva sucursal'}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>

            <Grid item xs={12}>
              <Controller
                name={'description'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Nombre'
                    type='text'
                    variant='standard'
                    value={value}
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('description')}
                    error={errors.description && Boolean(errors.description)}
                    helperText={errors.description && errors.description.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant='contained'
          color='primary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit2)}>
          Aceptar
        </Button>
        <Button
          variant='contained'
          color='secondary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus>
          Cancelar
        </Button>{' '}
      </Actions>
    </>
  );
};

export default AddOrUpdateBranchModal;
