export const en_es: any = {
  name: 'Nombre',
  impact: 'Impacto',
  probability	: 'Probabilidad',
  risk: 'Riesgo inherente',
  actual_legend_risk: 'Leyenda de Riesgo actual',
  description: 'Descripción',
  transactionKnowledge: 'Conocimiento de Transacción',
  economicCapacity: 'Capacidad Económica',
  comprehension: 'Conocimiento de la transacción',
  riskTolerance: 'Tolerancia a Riegos',
  typeRiskAssume: 'Tipo de Riesgo dispuesto a asumir',
  clasification: 'Clasificación',
  capacityFinancialUnderstanding: 'Capacidad comprensión Financiera',
  capacityEconomicIntegral: 'Capacidad Económica Integral',
  
  elements: 'Elementos',
  createDate: 'Fecha de Creación',
  updateDate: 'Fecha de Actualización',
  tipeRiskAssume: 'Tipo de Riesgo Asumido',
  typeRiskWillingAssume: 'Tipo Riesgo Dispuesto Asumir',
  capacityFinanceUnderstanding: 'Capacidad de comprensión Financiera',
  provinceCode: 'Código de Provincias',
  username: 'Correo electrónico',
  firstName: 'Nombre',
  lastName: 'Apellido',
  phone: 'Teléfono',
  activationCode: 'Código de Activación',
  password: 'Contraseña',
  country: 'País',
  country_code: 'Código País',   
  inherentRisk: 'Riesgo inherente',
  userRole: 'Rol de Usuario',
  status: 'Estado',
  municipalityCode: 'Código de municipio',
  countryCode: 'Código de País',
  sectorCode: 'Código de Sector',
  createdAt: 'Fecha de Creación',
  // tableName: 'Nombre',
  numberData: 'Elementos',
  modified: 'Fecha de Actualización',
  firsname: 'Nombre',
  formtype: 'Tipo de Cuenta',
  weight: 'Peso',
  stepForm: 'Paso completado',
  
  category: 'Categoría',
  code: 'Código',
  company: 'Compañía',
  subcompany: 'Sub Compañía',
  actualLegendRisk: 'Leyenda de Riesgo actual',
  actualLegenRisk: 'Leyenda de Riesgo actual',
  description_brand: 'Marca',
  description_model: 'Modelo',
  
  
}

export const en_esKeys = Object.keys(en_es)
