import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
export interface ICheckboxInputProps extends CheckboxProps {
  name: string;
  label?: string;
  register?: any;
  errors: any;
  control?: Control<any>;
}
export const CheckboxInput = ({
  name,
  label,
  register,
  control,
  errors,
  ...rest
}: ICheckboxInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={{ m: 1 }} component="span" variant="standard">
          <FormControlLabel
            control={<Checkbox checked={value} name={name} {...rest} {...register} />}
            label={label}
          />
        </FormControl>
      )}
    />
  );
};
