import { Box, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { BaseInput, MaskInput, RadioGroupInput } from "../../Input";
import { validationSchema } from "./validation.schema";

export const KycOnePannel = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      businessName: "",
      noRNC: "",
      noCommercialReg: "",
      regisExpiration: "",
      branch: "",
      street: "",
      building: "",
      town: "",
      address: "",
      matrixHouse: "",
      economicActivity: "",
      officeOne: "",
      officeTwo: "",
      email: "",
      /*  workPlace: "",
      workPhone: "",
      workAdress: "",
      role: "", */
    },
  });

  const formSubmit = (data: any) => {
    console.log({ data });
  };

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <form onSubmit={handleSubmit(formSubmit)} style={{paddingLeft: 16}}>
        <Grid container spacing={8} sx={{ pt: 0, pb: 0 }}>
          <Grid
            item
            //container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="businessName"
                label="Razón social"
                fullWidth
                control={control}
                errors={errors}
                multiline
                rows={2}
                register={register("businessName")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="noRNC"
                fullWidth
                label="No RNC"
                control={control}
                errors={errors}
                register={register("noRNC")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="noCommercialReg"
                fullWidth
                label="No. Reg Mercantil"
                control={control}
                errors={errors}
                register={register("noCommercialReg")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="regisExpiration"
                fullWidth
                label="Venc. registro"
                control={control}
                errors={errors}
                register={register("regisExpiration")}
              />
            </Grid>
            {/* <Grid item xs={12} md={12} sx={{ mt: 2, minHeight: "62px" }}></Grid> */}
          </Grid>
          {/*Columna 2*/}
          <Grid
            item
            //container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="branch"
                label="Sucursal / Filial"
                fullWidth
                control={control}
                errors={errors}
                register={register("branch")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="street"
                label="Calle / Avenida"
                fullWidth
                control={control}
                errors={errors}
                register={register("street")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="building"
                label="Edificio"
                fullWidth
                control={control}
                errors={errors}
                register={register("building")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="address"
                label="Dirección"
                fullWidth
                control={control}
                errors={errors}
                //multiline
                //rows={2}
                register={register("address")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="matrixHouse"
                label="Casa matriz"
                fullWidth
                control={control}
                errors={errors}
                register={register("matrixHouse")}
              />
            </Grid>
          </Grid>
          {/*Columna 3*/}
          <Grid
            item
            //container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="economicActivity"
                label="Actividad económica"
                fullWidth
                control={control}
                errors={errors}
                multiline
                rows={2}
                register={register("economicActivity")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <MaskInput
                maskType="phone"
                name="officeOne"
                label="Teléfono oficina I"
                fullWidth
                control={control}
                errors={errors}
                register={register("officeOne")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <MaskInput
                maskType="phone"
                name="officeTwo"
                label="Teléfono  oficina II"
                fullWidth
                control={control}
                errors={errors}
                register={register("officeTwo")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="email"
                label="Correo Electrónico"
                fullWidth
                control={control}
                errors={errors}
                register={register("email")}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
