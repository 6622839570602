import { Box, Typography } from "@mui/material";

interface IQuestionGroupProps {
  title?: string;
  children: React.ReactNode;
}
export const QuestionGroup = ({ title, children }: IQuestionGroupProps) => {
  return (
    <Box sx={{ width: "100%", p: 4, display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Box>
  );
};
