import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";

export const getRequestSubcompany = async (page: any, companyId: any) => {
  let responseData = {};

  const pagesize: any = 1000;
  await privateFetch
    .get(URL.REQUEST_SUBCOMPANY_BYID2(page, pagesize, companyId))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequestSubcompany = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_COMPANY_BYID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_mapprisk_label_role_findid_error);
    });
  return responseData;
};

export const createRequestSubcompany = async (data: any) => {
  let responseData = {};
  //console.log("createRequestSubcompany=",data.dataSubCompanies);
  //return [];
  await privateFetch
    .post(
      URL.REQUEST_SUBCOMPANY_CREATE,
      JSON.stringify(data.dataSubCompanies),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    )

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const updateRequestSubcompany = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    description: data.description,
    numberInquiries: data.numberInquiries,
  };
  //console.log(requestBody);

  await privateFetch
    .put(URL.REQUEST_SUBCOMPANY_UPDATE(data.id), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_mapprisk_label_role_update_error);
    });
  return responseData;
};

export const deleteRequestSubcompany = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_SUBCOMPANY_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error);
      throw new Error(error.response.data.message);
      //throw new Error(properties.com_mapprisk_label_role_delete_error);
    });
  return responseData;
};
