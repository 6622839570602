import { useState, createContext, useMemo } from "react";
import { companiesTypes, options } from "../types/Context";
import {
  getRequest,
  getRequestCompanyDropDown,
} from "../services/company/CompanyService";
import { getRequestSubcompany } from "../services/subCompany/SubCompanyServices";
import { setItem, getItem } from "../utils/LocalStorageManager";
import { getFactors } from "../services/factors/FactorsService";
import { useApp } from "../hooks/useApp";

const initialState = {
  page: 0,
  companyData: [],
  loading: false,
  error: "",
  factors: [],
  valueTab: 0,
  hasSubCompany: false,
};

const DataContext = createContext<companiesTypes>(initialState);

const DataProvider = ({ children }: any) => {
  const { setLoading } = useApp();

  // const [loading, setLoading] = useState(initialState.loading);
  const [companyData, setCompanyData] = useState<options[]>(
    initialState.companyData
  );
  const [subCompanyData, setSubCompanyData] = useState<any>([]);
  const [page, setPage] = useState(initialState.page);
  const [error, setError] = useState(initialState.error);
  const [companyIndexDB, setCompanyIndexDB] = useState<any>(null);
  const [hasSubCompany, setHasSubCompany] = useState<boolean>(false);
  const [factors, setFactors] = useState<any>(initialState.factors);
  const [valueTab, setValueTab] = useState(0);

  const handleFetchDataCompany = async (
    currentPage: number,
    filter?: string
  ) => {
    setLoading && setLoading(true);
    try {
      //setPreFilter(filter);
      setPage(currentPage);
      let data: any = await getRequestCompanyDropDown(currentPage, filter);
      if (data) {
        let clearData = data.map(
          ({ createdAt, createdBy, updatedAt, updateBy, ...rest }: any) => rest
        );
        setCompanyData(clearData);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading && setLoading(false);
      setError && setError(error.message);
    }
  };

  const handleFetchDataSubCompany = async (
    currentPage: number,
    filter?: string
  ) => {
    let hasCompany = false;
    try {
      setPage(currentPage);
      let data: any = await getRequestSubcompany(currentPage, filter);
      if (data && data.content.length > 0) {
        hasCompany = true;
        let clearData = data.content.map(({ companyId, ...rest }: any) => rest);
        setSubCompanyData(clearData);
      } else {
        hasCompany = false;
        setSubCompanyData(null);
      }
    } catch (error: any) {
      console.log(error);
    }
    return hasCompany;
  };

  const handleLocalCompaniesAndSubcompanies = async () => {
    let localCompany = await getItem("setLocalCompany");
    let localSubCompany = await getItem("setLocalSubCompany");
    setCompanyIndexDB({
      company: localCompany,
      subCompany: localSubCompany,
    });
  };

  const handleFetchFactors = async (
    page: number,
    companyID: number,
    subCompanyID?: string,
    size?: number
  ) => {
    let dataPhysical: any = null;
    let dataLegal: any = null;
    let dataPhysicalGlobal: any = null;
    let dataLegalGlobal: any = null;
    setLoading && setLoading(true);
    try {
      dataPhysical = await getFactors(page, "F", companyID, subCompanyID, size);
      dataLegal = await getFactors(page, "J", companyID, subCompanyID, size);
      dataPhysicalGlobal = await getFactors(
        page,
        "F",
        companyID,
        subCompanyID,
        100
      );
      dataLegalGlobal = await getFactors(
        page,
        "J",
        companyID,
        subCompanyID,
        100
      );
      if (dataPhysical && dataLegal) {
        setFactors({
          ...factors,
          dataPhysical: dataPhysical,
          dataLegal: dataLegal,
          dataPhysicalGlobal: dataPhysicalGlobal,
          dataLegalGlobal: dataLegalGlobal,
        });
      } else {
        setFactors(null);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setFactors(null);
      setError(error.message);
      setLoading && setLoading(false);
    }
  };

  const formContextValues: companiesTypes = useMemo(
    () => ({
      page,
      //preFilter,
      companyData,
      setCompanyData,
      handleFetchDataCompany,
      subCompanyData,
      setSubCompanyData,
      handleFetchDataSubCompany,
      error,
      companyIndexDB,
      handleLocalCompaniesAndSubcompanies,
      factors,
      handleFetchFactors,
      valueTab,
      setValueTab,
      hasSubCompany,
      setHasSubCompany,
    }),
    // eslint-disable-next-line
    [companyData, subCompanyData, companyIndexDB, factors, valueTab]
  );

  return (
    <DataContext.Provider value={formContextValues}>
      {children}
    </DataContext.Provider>
  );
};
export { DataProvider };

export default DataContext;
