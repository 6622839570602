import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { useApp } from "../../../hooks/useApp";
import PaperSessions from "../../../components/PaperSessions";
import { CONSTANT } from "../../../utils/Constants";
import { SVActiveUserRequest } from "../../../services/security/SecurityService";

const ActiveRegister = () => {
  const { setLoading, setErrorMsg, setSuccessMsg } = useApp();
  const { code, email } = useParams();
  const [active, setActive] = useState(false);

  useLayoutEffect(() => {
    const process = async () => {
      await handleActiveUser();
    };
    process();
    // eslint-disable-next-line
  }, []);

  const handleActiveUser = async () => {
    setLoading && setLoading(true);
    try {
      let responseData: any = await SVActiveUserRequest(email, code);
      if (!responseData && responseData?.info !== CONSTANT.STATUS_INFO_ACTIVE) {
        setLoading && setLoading(false);
        setErrorMsg && setErrorMsg(responseData.message);
        return;
      }
      setLoading && setLoading(false);
      setActive(true);
      setSuccessMsg && setSuccessMsg(responseData.message);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  return (
    <PaperSessions
      to="/"
      textLink="Regresar a inicio de sesión"
      titleForm="Activar usuario"
    >
      <Typography
        variant="body1"
        align="center"
        sx={{ mb: 3, fontSize: "1rem" }}
      >
        {active ? (
          <>¡Ha autenticado y activado su cuenta correctamente!</>
        ) : (
          <>Error en proceso de activar usuario</>
        )}
      </Typography>
    </PaperSessions>
  );
};

export default ActiveRegister;
