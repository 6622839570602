import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { ReactNode } from "react";
import { Control, Controller, ValidationRule } from "react-hook-form";

type TData = {
  value: any;
  label: string;
};

interface ICheckGroupInputProps {
  control: Control<any>;
  name: string;
  required?: string | ValidationRule<boolean> | undefined;
  label?: ReactNode | undefined;
  data: TData[];
  errors: any;
}
export const CheckGroupInput = ({
  name,
  control,
  label,
  required,
  data,
  errors,
}: ICheckGroupInputProps) => {
  const handleChecked = (e: any, value: any, onChange: any) => {
    console.log(e.target.name);
    return onChange({ ...value, [e.target.name]: e.target.checked });
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel
            error={errors[name]}
            component="legend"
            sx={{ color: "text.primary" }}
          >
            {label}
          </FormLabel>
          <FormGroup>
            {data?.length &&
              data.map((item: TData) => (
                <FormControlLabel
                  key={item.value}
                  control={
                    <Checkbox
                      checked={value[item.value]}
                      onChange={(e) => {
                        handleChecked(e, value, onChange);
                      }}
                      name={item.value}
                    />
                  }
                  label={item.label}
                />
              ))}
          </FormGroup>
        </FormControl>
      )}
    />
  );
};
