import { Box, Grid, IconButton, Stack } from "@mui/material";
import { QuestionGroup } from "../QuestionGroup";
import { useForm } from "react-hook-form";
import { CheckboxInput, DateInput, FileInput } from "../Input";
import { TrashIcon } from "../../../../components/svgicons/SvgIcons";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect } from "react";
import { Watch } from "@mui/icons-material";

export const DocumentsPannel = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      cedula: {
        files: [],
        filesCount: 1,
        fileName: "",
        exeption: false,
      },
      creditHistory: {
        files: [],
        filesCount: 1,
        fileName: "",
        exeption: false,
      },
      expirationDate: new Date("2024-10-10"),
    },
  });
  const formSubmit = (data: any) => {
    console.log({ data });
  };

  const handleShowFile = (index: number, fieldName: "cedula" | "creditHistory") => {

  };

  const handleDeleteFile = (
    index: number,
    fieldName: "cedula" | "creditHistory"
  ) => {
    debugger;
    const files = getValues(`${fieldName}.files`);
    const filesCount = getValues(`${fieldName}.filesCount`);
    files.splice(index, 1);
    setValue(`${fieldName}.files`, files);
    setValue(`${fieldName}.filesCount`, filesCount - 1 );
  };

  const watchCedulaFiles = watch("cedula.files");
  const watchCreditHistoryFiles = watch("creditHistory.files");
  const watchCedulaFilesCount = watch("cedula.filesCount");
  const watchCreditHistoryFilesCount = watch("creditHistory.filesCount");

  useEffect(() => {
    
  }, [watchCedulaFiles, watchCreditHistoryFiles]);

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <Stack display="flex" flexDirection="column" sx={{ pt: 0, pb: 0 }}>
        <QuestionGroup title="Documentos Adjuntos">
          <form onSubmit={handleSubmit(formSubmit)} style={{ paddingLeft: 16 }}>
            <Stack display="flex" flexDirection="column" sx={{ pt: 0, pb: 0 }}>
              <Grid container spacing={8}>
                <Grid
                  item
                  xs={12}
                  md={8}
                  rowGap={4}
                  sx={{ mt: 1, pl: 4 }}
                  display="flex"
                  flexDirection="column"
                >
                  <Stack
                    display="flex"
                    sx={{ pt: 0, pb: 0 }}
                    columnGap={4}
                    flexDirection="row"
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {Array.from(Array(watchCedulaFilesCount).keys()).map(
                        (_, index) => (
                          <Stack direction="row" display="flex">
                            <FileInput
                              key={`cedula-file-${index}`}
                              fileName={
                                (watchCedulaFiles[index] as File)?.name
                              }
                              register={register("cedula")}
                              control={control}
                              name={`cedula.file.${index}`}
                              label={
                                index === 0 ? "Cédula o Pasaporte" : undefined
                              }
                              errors={errors}
                              onChanges={(files) =>
                                setValue("cedula.files", [
                                  ...watchCedulaFiles,
                                  files[0],
                                ] as never)
                              }
                            />
                            <IconButton
                              disabled={!Boolean((watchCedulaFiles[index] as File)
                                ?.name)}
                              disableRipple
                              onClick={() => {
                                handleShowFile(index, "cedula");
                              }}
                            >
                              <VisibilityIcon style={{ color: "#f1b80e", fontSize: '24px' }} />
                            </IconButton>
                            <IconButton
                              disabled={index === 0}
                              style={{
                                visibility: index === 0 ? "hidden" : "visible",
                              }}
                              disableRipple
                              onClick={() => {
                                handleDeleteFile(index, "cedula");
                              }}
                            >
                              <TrashIcon style={{ color: "#ff5757", fontSize: '24px' }} />
                            </IconButton>
                          </Stack>
                        )
                      )}

                      {watchCedulaFiles.length === watchCedulaFilesCount && (
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <IconButton
                            onClick={() => {
                              setValue(
                                "cedula.filesCount",
                                watchCedulaFilesCount + 1
                              );
                            }}
                          >
                            <AddCircleIcon
                              htmlColor="green"
                              style={{ width: 18, height: 18 }}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Box>

                    <CheckboxInput
                      name="cedula.exeption"
                      control={control}
                      errors={errors}
                      label="Excepción"
                      register={register("cedula.exeption")}
                    />
                  </Stack>
                  <Stack
                    display="flex"
                    sx={{ pt: 0, pb: 0 }}
                    columnGap={2}
                    flexDirection="row"
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {Array.from(
                        Array(watchCreditHistoryFilesCount).keys()
                      ).map((_, index) => (
                        <Stack direction="row" display="flex">
                          <FileInput
                            key={`credit-history-file-${index}`}
                            fileName={
                              (watchCreditHistoryFiles[index] as File)
                                ?.name
                            }
                            register={register("creditHistory.fileName")}
                            control={control}
                            name={`creditHistory.file.${index}`}
                            label={
                              index === 0 ? "Historial Crediticio" : undefined
                            }
                            errors={errors}
                            onChanges={(files) =>
                              setValue("creditHistory.files", [
                                ...watchCreditHistoryFiles,
                                files[0],
                              ] as never)
                            }
                          />
                          <IconButton
                              disabled={!Boolean((watchCreditHistoryFiles[index] as File)
                                ?.name)}
                              disableRipple
                              onClick={() => {
                                handleShowFile(index, "cedula");
                              }}
                            >
                              <VisibilityIcon style={{ color: "#f1b80e", fontSize: '24px' }} />
                            </IconButton>
                          <IconButton
                            disabled={index === 0}
                            style={{
                              visibility: index === 0 ? "hidden" : "visible",
                            }}
                            disableRipple
                            onClick={() => {
                              handleDeleteFile(index, "creditHistory");
                            }}
                          >
                            <TrashIcon style={{ color: "#ff5757" }} />
                          </IconButton>
                        </Stack>
                      ))}
                      {watchCreditHistoryFiles.length ===
                        watchCreditHistoryFilesCount && (
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <IconButton
                            onClick={() => {
                              setValue(
                                "creditHistory.filesCount",
                                watchCreditHistoryFilesCount + 1
                              );
                            }}
                          >
                            <AddCircleIcon
                              htmlColor="green"
                              style={{ width: 18, height: 18 }}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    <CheckboxInput
                      name="creditHistory.exeption"
                      control={control}
                      errors={errors}
                      label="Excepción"
                      register={register("creditHistory.exeption")}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4} sx={{ mt: 1 }}>
                  <Stack display="flex" sx={{ pt: 0, pb: 0 }}>
                    <DateInput
                      name="expirationDate"
                      control={control}
                      label="Fecha de vencimiento"
                      errors={errors}
                      minDate={new Date()}
                      maxDate={new Date("2024-10-10")}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </QuestionGroup>
      </Stack>
    </Box>
  );
};
