import { useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { useData } from "../../hooks/useData";

interface DialogProps {
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  factorID?: any;
}

interface schemaFactorOption {
  id?: string;
  name: string;
}

const AddOrUpdateSubElements = ({
  cancelModal,
  modalType,
  onSubmit,
  data,
  factorID,
}: DialogProps) => {
  const { riskData, handleFetchRiskLevels } = useApp();
  const { companyIndexDB } = useData();

  const schemaFactorOption = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
  });

  /*  const validation = Yup.object().shape({
    newFactorOption: Yup.array().of(Yup.object().shape(schemaFactorOption)),
  }); */

  const defaultValues = {
    id: data?.id ?? 0,
    name: data?.name ? data.name : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<schemaFactorOption>({
    defaultValues,
    resolver: yupResolver(schemaFactorOption),
    mode: "onChange",
  });



  useEffect(() => {
    if (riskData?.length < 1) {
      handleFetchRiskLevels &&
        handleFetchRiskLevels(
          companyIndexDB?.company.id,
          companyIndexDB?.subCompany.id
        );
    }
  }, []);



  return (
    <>
      <DialogTitle>
        {modalType === "create" ? "Agregar ítem" : "Actualizar ítem"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Nombre"
                  type="text"
                  variant="standard"
                  {...register("name")}
                  error={errors?.name && true}
                  helperText={errors?.name?.message}
                />
              )}
            />
          </Grid>

        </Grid>
        {/*  </form> */}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Agregar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default AddOrUpdateSubElements;
