import { Box, Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation.schema";
import {
  AutocompleteInput,
  BaseInput,
  CheckGroupInput,
  MaskInput,
  SelectInput,
} from "../../Input";
import { DateInput } from "../../Input/DateInput";
import { useData } from "../../../../../hooks/useData";

export const KycTwoPannel = ({ factorsAndOptions }: any) => {
  const { factors } = useData();
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      maritalStatus: "",
      gender: "",
      birthDate: new Date("01/01/2023"),
      birthCountry: {
        id: "",
        description: "",
      },
      citizenshipCountry: {
        id: "",
        description: "",
      },
      birthCity: "",
      monthlyIncomeMainActivity: { id: 1, text: "Construccion" },
      monthlyIncomeSecondaryActivity: { id: 1, text: "Construccion" },
      monthlyIncome: "",
      incomeOrigin: {
        ahorro: false,
        salario: false,
        negocio: false,
        venta: false,
        inversiones: false,
        otro: false,
        herencia: false,
        pensiones: false,
      },
    },
  });

  const formSubmit = (data: any) => {
    console.log({ data });
  };

  console.log(
    factorsAndOptions.filter((item: any) => item.name === "Nacionalidad")[0]
      .options
  );

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <form onSubmit={handleSubmit(formSubmit)} style={{ paddingLeft: 16 }}>
        <Grid container spacing={8} sx={{ pt: 0, pb: 0 }}>
          {/* First column */}
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <SelectInput
                name="maritalStatus"
                label="Estado civil"
                control={control}
                errors={errors}
                register={register("maritalStatus")}
                data={[
                  { id: "soltero", name: "Soltero" },
                  { id: "casado", name: "Casado" },
                  { id: "divorciado", name: "Divorciado" },
                  { id: "viudo", name: "Viudo" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <SelectInput
                name="gender"
                label="Sexo"
                control={control}
                errors={errors}
                register={register("gender")}
                data={[
                  { id: "fimale", name: "Mujer" },
                  { id: "male", name: "Hombre" },
                  { id: "other", name: "Otro" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 8 }}>
              <DateInput
                name="birthDate"
                label="Fecha Nacimiento"
                control={control}
                errors={errors}
                minDate={
                  new Date("Thu Jan 01 1900 20:35:30 GMT-0400 (Venezuela Time)")
                }
                maxDate={new Date()}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <AutocompleteInput
                name="birthCountry"
                control={control}
                errors={errors}
                /* data={[
                  { id: "venezuela", name: "Venezuela" },
                  { id: "colombia", text: "Colombia" },
                  { id: "peru", text: "Peru" },
                  { id: "argentina", text: "Argentina" },
                  { id: "dominicana", text: "Republica Dominicana" },
                ]} */
                data={
                  factorsAndOptions.filter(
                    (item: any) => item.name === "Nacionalidad"
                  )[0]?.options ?? []
                }
                inputProps={{
                  label: "País Nacimiento",
                }}
                getOptionLabel={(option: any) => option.description}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <AutocompleteInput
                name="citizenshipCountry"
                control={control}
                errors={errors}
                data={
                  factorsAndOptions.filter(
                    (item: any) => item.name === "Residencia"
                  )[0]?.options ?? []
                }
                inputProps={{
                  label: "País Ciudadania",
                }}
                getOptionLabel={(option: any) => option.description}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="birthCity"
                control={control}
                errors={errors}
                register={register("birthCity")}
                label="Ciudad Nacimiento"
                fullWidth
              />
            </Grid>
          </Grid>
          {/* Second column */}
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Stack
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
              rowGap={4}
              sx={{ mt: 3 }}
            >
              <AutocompleteInput
                name="monthlyIncomeMainActivity"
                control={control}
                errors={errors}
                data={[
                  { id: 1, text: "Construccion" },
                  { id: 2, text: "Mineria" },
                  { id: 3, text: "Agricultura" },
                  { id: 4, text: "Comercio" },
                  { id: 5, text: "Servicios" },
                ]}
                getOptionLabel={(option: any) => option.text}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                inputProps={{
                  label: "Actividad Principal",
                  fullWidth: true,
                }}
              />
              <AutocompleteInput
                name="monthlyIncomeSecondaryActivity"
                control={control}
                errors={errors}
                data={[
                  { id: 1, text: "Construccion" },
                  { id: 2, text: "Mineria" },
                  { id: 3, text: "Agricultura" },
                  { id: 4, text: "Comercio" },
                  { id: 5, text: "Servicios" },
                ]}
                getOptionLabel={(option: any) => option.text}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                inputProps={{
                  label: "Actividad Secundaria",
                  fullWidth: true,
                }}
              />

              <MaskInput
                name="monthlyIncome"
                control={control}
                errors={errors}
                maskType="currency"
                label="Indicar"
                fullWidth
                register={register("monthlyIncome")}
              />
            </Stack>
          </Grid>
          {/* Third column */}
          <Grid item xs={12} md={4} sx={{ pt: 0, pb: 0 }}>
            <Stack display="flex" justifyContent="center" alignItems="center">
              <CheckGroupInput
                name="incomeOrigin"
                control={control}
                errors={errors}
                label="Origen de Fondos"
                data={[
                  { value: "ahorro", label: "Ahorros Personales" },
                  { value: "salario", label: "Salario Actual" },
                  { value: "negocio", label: "Negocio Propio" },
                  { value: "venta", label: "Venta Propiedades" },
                  { value: "inversiones", label: "Inversiones" },
                  { value: "otro", label: "Otros Ingresos" },
                  { value: "herencia", label: "Herencia" },
                  { value: "pensiones", label: "Pensiones" },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
