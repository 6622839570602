import ColorCircle from "./ColorCircle";
import { Grid, TextField, Typography } from "@mui/material";
import "./RangeField.css";

interface RangeFieldProps {
  fromValue: number;
  toValue: number;
  legend: string;
  color: string;
  onFromValueChange: (newValue: number) => void;
  onToValueChange: (newValue: number) => void;
  onLegendChange: (newValue: string) => void;
  onColorChange: (newColor: string) => void;
  isColorPickerOpen: boolean;
  onColorPickerToggle: () => void;
  type: string;
  isLast: boolean;
  isLastMinusOne?: boolean;
  isLastMinusTwo?: boolean;
  isLastMinusThree?: boolean;
  isLastMinusFour?: boolean;
  selectedMethodology: string;
  isIndex?: any;
  rangeFields?: any;
}

const RangeField: React.FC<RangeFieldProps> = ({
  fromValue,
  toValue,
  legend,
  color,
  onFromValueChange,
  onToValueChange,
  onLegendChange,
  onColorChange,
  isColorPickerOpen,
  onColorPickerToggle,
  type,
  isLast,
  isLastMinusOne,
  isLastMinusTwo,
  isLastMinusThree,
  isLastMinusFour,
  selectedMethodology,
  isIndex,
  rangeFields,
}) => {
  let maxFromValue: number;

  if (type === "2") {
    maxFromValue = 100;
  } else if (type === "1") {
    if (selectedMethodology === "1") {
      maxFromValue = 9;
    } else if (selectedMethodology === "2") {
      maxFromValue = 16;
    } else if (selectedMethodology === "3") {
      maxFromValue = 25;
    } else {
      throw new Error("Metodología no válida para el tipo 1");
    }
  } else {
    throw new Error("Tipo no válido");
  }

  const handleLegendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onLegendChange(newValue);
  };

  const handleColorChange = (newColor: string) => {
    onColorChange(newColor);
  };

  const handleFromValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseInt(event.target.value);
    if (newValue <= maxFromValue) {
      onFromValueChange(newValue);
      // Actualizar el "Hasta" en función del "Desde" más "1"
      onToValueChange(newValue + 1);
    }
  };

  const handleToValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);

    // Verificar si el nuevo valor es mayor que el valor máximo (maxFromValue)
    if (newValue <= maxFromValue) {
      onToValueChange(newValue);
    } else {
    }
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        display: "flex",
        pt: 8,
        pb: 4,
        position: "relative",
        overflowX: "visible",
      }}
    >
      <Grid item xs={9}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1">Desde</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="number"
              /* value={
                isLast && fromValue >= maxFromValue - 1
                  ? maxFromValue - 1
                  : isLastMinusOne && fromValue >= maxFromValue - 3
                  ? maxFromValue - 3
                  : isLastMinusTwo && fromValue >= maxFromValue - 5
                  ? maxFromValue - 5
                  : isLastMinusThree && fromValue >= maxFromValue - 7
                  ? maxFromValue - 7
                  : fromValue
              } */
              /* value={
                isLast && fromValue >= maxFromValue - 1
                  ? maxFromValue - 1
                  : !isLast &&
                    fromValue >= maxFromValue - (rangeFields - isIndex) - 1
                  ? maxFromValue - (isIndex + 1)
                  : fromValue
              } */

              /* value={
                isLast && fromValue >= maxFromValue - 1
                  ? maxFromValue - 1
                  : isLastMinusOne && fromValue >= maxFromValue - 3
                  ? maxFromValue - 3
                  : fromValue
              } */ //este casi casi funciona completo
              /* value={
                isLast && fromValue >= maxFromValue - 1
                  ? maxFromValue - 1
                  : fromValue
              } */ //Funcionaba para el último
              data-from={fromValue}
              value={fromValue.toString()}
              onChange={handleFromValueChange}
              placeholder="Desde"
              data-index={isIndex}
              inputProps={{
                readOnly: true,
                max: isLast
                  ? maxFromValue
                  : isLastMinusOne
                  ? maxFromValue - 1
                  : isLastMinusTwo
                  ? maxFromValue - 3
                  : isLastMinusThree
                  ? maxFromValue - 5
                  : maxFromValue - 7,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" sx={{ textAlign: "right" }}>
              hasta
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              /* value={
                isLast
                  ? maxFromValue
                  : isLastMinusOne && toValue >= maxFromValue - 2
                  ? maxFromValue - 2
                  : isLastMinusTwo && toValue >= maxFromValue - 4
                  ? maxFromValue - 4
                  : isLastMinusThree && toValue >= maxFromValue - 6
                  ? maxFromValue - 6
                  : isLastMinusFour && toValue >= maxFromValue - 8
                  ? maxFromValue - 8
                  : toValue
              } */
              /*  value={
                isLast
                  ? maxFromValue
                  : isLastMinusOne && toValue >= maxFromValue - 2
                  ? maxFromValue - 2
                  : toValue
              } */
              data-toValue={toValue}
              value={isLast ? maxFromValue : toValue} //Funciona para el último valor
              type="number"
              onChange={handleToValueChange}
              placeholder="Hasta"
              data-isLastMinusOne={isLastMinusOne}
              data-last={isLast}
              data-index={isIndex}
              inputProps={{
                readOnly: isLast,
                max: isLast
                  ? maxFromValue
                  : isLastMinusOne
                  ? maxFromValue - 2
                  : isLastMinusTwo
                  ? maxFromValue - 4
                  : isLastMinusThree
                  ? maxFromValue - 6
                  : maxFromValue - 8,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1">Leyenda</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              type="text"
              value={legend}
              onChange={handleLegendChange}
              placeholder="Leyenda"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <ColorCircle
          color={color}
          onColorChange={handleColorChange}
          isPickerOpen={isColorPickerOpen}
          onToggleColorPicker={onColorPickerToggle}
        />
      </Grid>
    </Grid>
  );
};

export default RangeField;
