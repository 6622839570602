// @ts-ignore
import pdfMake from "pdfmake/build/pdfmake";
// @ts-ignore
import pdfFonts from "pdfmake/build/vfs_fonts"; 
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePdf = async ({
    capacitationTotal,
    total_hours,
    completedHours,
    pendingHours,
    period,
    companiaLabel,
    subcompaniaLabel,
    logoHref,
}: any, trainings: any[]) => {
    console.log({trainings});
    
    const getMainInfo = () => {
        const mainInfo = {
          "Total de capacitaciones:": capacitationTotal,
          "Total de horas requeridas:": total_hours,
          "Total de horas recibidas:": completedHours,
          "Total de horas pendientes:": pendingHours,
        };
        return Object.entries(mainInfo).map(([key, value]) => [
          { text: key, style: ["label"] },
          { text: value, style: ["value"] },
        ]);
      };
    
      const getTrainings = (trainingData: any[]) => {
        const data = trainingData.map((item: any) => {
            return {
                name: item.capacitationId?.name,
                instructor: item.capacitationId?.instructor,
                method: item.capacitationId?.method,
                total_hours: item.capacitationId?.total_hours,
            }
        })
        return [
          [
            {
              text: "Tema de capacitación",
              style: ["trainingsHeader"],
              border: [false, false, false, false],
            },
            {
              text: "Instructor",
              style: ["trainingsHeader"],
              border: [false, false, false, false],
            },
            {
              text: "Modalidad",
              style: ["trainingsHeader"],
              border: [false, false, false, false],
            },
            {
              text: "Total de horas",
              style: ["trainingsHeader"],
              border: [false, false, false, false],
            },
          ],
          ...data.map((item: any) => [ 
            ...Object.entries(item).map(([key, value]) => ({
              text: value,
              style: ["trainingItem"],
              border: [false, false, false, true],
            })),
          ]),
        ];
      }
    
      var docDefinition = {
        content: [
          {
            columns: [
              {
                image: "logo",
                fit: [60, 60],
                marginBottom: 5,
              },
              [
                {
                  style: ["mainHeader"],
                  text: "Reporte de Capacitaciones",
                },
                {
                  text: `Periodo ${period}`,
                  style: ["mainSubHeader"],
                },
              ],
            ],
          },
          {
            table: {
              widths: ["*", "*"],
              heights: [10, 10, 100, 10, "auto"],
              headerRows: 1,
              body: [
                [
                  {
                    columns: [{
                        text: `${trainings[0]?.employeeId?.firstName} ${trainings[0]?.employeeId?.lastName}`,
                    },{
                    columns: [
                      { text: "Compañía: ", style: ["headerLabel"] },
                      { text: companiaLabel },
                    ],
                    border: [false, true, true, false],
                    style: ["header", "title", "left"],
                  },
                  ],
                    style: ["header", "title", "left"],
                    border: [true, true, true, false],
                    colSpan: 2,
                    margin: [20, 10, 10, 0]
                  },
                  {}
                ],
                [
                  {
                    columns: [
                        {},
                        {
                            columns: [
                                
                      { text: "Sub compañía: ", style: ["headerLabel"] },
                      { text: subcompaniaLabel },
                                ]
                        },
                    ],
                    style: ["header", "subtitle", "left"],
                    border: [true, false, true, false],
                    colSpan: 2,
                    margin: [20, 0, 10, 10]
                  },
                  {}
                ],
                [
                  {
                    style: ["info"],
                    border: [true, false, true, true],
                    colSpan: 2,
                    alignment: "left",
                    columns: [{},{
                        width: "auto",
                        table: {
                      widths: ["auto", "auto"],
                      headerRows: 1,
                      body: getMainInfo(),
                    },
                    layout: {
                      hLineColor: function (i: number, node: any) {
                        return i === 0 || i === node.table.body.length
                          ? "#ccc"
                          : "gray";
                      },
                      hLineWidth: function (i: number, node: any) {
                        return i === 0 || i === node.table.body.length ? 1 : 0;
                      },
                      vLineColor: function (i: number, node: any) {
                        return i === 0 || i === node.table.widths.length
                          ? "#ccc"
                          : "gray";
                      },
                      vLineWidth: function (i: number, node: any) {
                        return i === 0 || i === node.table.widths.length ? 1 : 0;
                      },
                    },
                    },{}]
                  },
                  {},
                ],
                [
                  {
                    style: ["section", "sectionLabel"],
                    border: [true, false, true, false],
                    colSpan: 2,
                    alignment: "left",
                    text: "Capacitaciones",
                  },
                ],
                [
                  {
                    style: ["body"],
                    border: [true, false, true, true],
                    colSpan: 2,
                    alignment: "center",
                    columns: [{},{
                        width: "auto",
                        table: {
                      body: getTrainings(trainings),
                    },
                    layout: {
                      hLineColor: "#ccc",
                    },
                    },{}]
                  },
                ],
              ],
            },
            layout: {
              hLineColor: function (i: number, node: any) {
                return i === 0 || i === node.table.body.length ? "black" : "#ccc";
              },
            },
          },
        ],
        images: {
          logo: logoHref,
        },
        styles: {
          mainHeader: {
            fontSize: 18,
            margin: 5,
            color: "#333333",
            bold: true,
            alignment: "right",
          },
          mainSubHeader: {
            fontSize: 16,
            bold: true,
            margin: 5,
            color: "#333333",
            alignment: "right",
          },
          header: {
            fillColor: "#eeeeee",
            bold: true,
            margin: 2,
            rowGap: 5
          },
          headerLabel: {
            color: "#666",
            bold: true,
            fontSize: 14,
            marginRight: 6,
          },
          title: {
            fontSize: 16,
            color: "#333333",
            bold: true,
          },
          left: {
            alignment: "left",
          },
          right: {
            alignment: "right",
          },
          subtitle: {
            fontSize: 14,
            color: "#6c6c6c",
          },
          section: {
              margin: [20, 20, 20, 0],
          },
          info: {
              margin: 20,
          },
          body: {
            margin: [20, 20, 20, 40],
    
          },
          label: {
            color: "#666",
            bold: true,
            fontSize: 14,
            margin: 4,
          },
          sectionLabel: {
            color: "#333333",
            fontSize: 16,
            bold: true,
          },
          value: {
            color: "#333333",
            bold: true,
            fontSize: 14,
            margin: 4,
          },
          trainingsHeader: {
            margin: 10,
            fontSize: 12,
            fillColor: "#e9e9e9",
            color: "#333333",
            bold: true,
          },
          trainingItem: {
            margin: 10,
            color: "#666",
          },
        },
      };

  pdfMake.createPdf(docDefinition).open();
};
