import { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Dialog,
  DialogContentText,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
} from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import ErrorAlert from "../../components/utils/ErrorAlert";
import { useData } from "../../hooks/useData";
import { useApp } from "../../hooks/useApp";
import { useForm, Controller } from "react-hook-form";
import Step1Agrupacion from "./Step1Agrupacion";
import { getRequestUserMatriz } from "../../services/users/UserMatrizService";
import {
  getConstitutionTime,
  getRequestAgrupacion,
} from "../../services/agrupaciones/AgrupacionService";
import {
  createProfileMatriz,
  getProfileMatriz,
} from "../../services/profile/ProfileService";
import Step2ValidateClient from "./Step2ValidateClient";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RiskMatrixTabs = () => {
  const { companyIndexDB, subCompanyData, handleFetchFactors, factors } =
    useData();
  const { setLoading, setErrorMsg, riskData, setSuccessMsg } = useApp();
  const [valueStep, setValueStep] = useState<number>(0);
  const [dataConstitutionTime, setDataConstitutionTime] = useState<any>(null);
  const [showInitModal, setShowInitModal] = useState<boolean>(false);
  const [dataUserSearched, setDataUserSearched] = useState<any>(null);
  const [dataUserSearchedShow, setDataUserSearchedShow] = useState<any>(null);
  const [optionsTipoPersona, setOptionsTipoPersona] = useState<any>([]);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [expendedTab1, setExpendedTab1] = useState<boolean>(true);
  const [expendedTab2, setExpendedTab2] = useState<boolean>(false);
  const [factorsAndOptions, setFactorsAndOptions] = useState<any>(null);
  const [listShow, setListShow] = useState<{ label: string }[]>([]);
  const [matrixGroups, setMatrixGroups] = useState<any>(null);
  const [branchData, setBranchData] = useState<any>(null);
  const [optionsSelected, setOptionsSelected] = useState<any>([]);
  const [optionsEjecutivo, setOptionsEjecutivo] = useState<any>(null);

  const [runUploadDataInForm, setRunUploadDataInForm] =
    useState<boolean>(false);

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueStep(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!companyIndexDB?.company?.id) return;

      const subCompanyId =
        subCompanyData !== null ? companyIndexDB?.subCompany?.id : "";
      handleFetchFactors &&
        handleFetchFactors(0, companyIndexDB.company.id, subCompanyId, 100);

      /* const dataEjectutivo = await getRequestUserMatriz(
        companyIndexDB.company.id,
        subCompanyId,
        "Ejecutivo de ventas"
      );
      setOptionsEjecutivo(dataEjectutivo.content);

      const constitutionTime = await getConstitutionTime();
      console.log(constitutionTime, "constitutionTime");
      if (subCompanyData !== null) setDataConstitutionTime(constitutionTime); */
    };

    fetchData();
  }, [companyIndexDB, subCompanyData]);

  /*   useEffect(() => {
    console.log("factors:", !!factors);
    console.log("factors.dataLegal:", !!factors.dataLegal);
    if (!!factors && !!factors.dataLegal) {
      setShowInitModal(true);
    }

    // @ts-ignore
  }, [factors]); */

  //Función de cerrar el modal
  /* const handleCloseInitModal = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "escapeKeyDown") {
      return;
    }
    setShowInitModal(false);
    setDataUserSearchedShow(null);
  };

  // Esta función maneja la búsqueda de usuarios.
  const handleSearchUser = async (data: any) => {
    console.log(data, "data");

    const { searchUser } = data;
    // Observa el valor del campo 'searchUser'.

    try {
      // Inicia la carga.
      setLoading && setLoading(true);
      // Realiza una solicitud para obtener el perfil de la matriz.
      const response = await getProfileMatriz(
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id,
        searchUser
      );

      // Si la respuesta no contiene contenido, muestra un mensaje de error.
      if (response.content.length === 0) {
        setErrorMsg && setErrorMsg("Usuario no encontrado");
        setLoading && setLoading(false);
        return;
      }

      // Establece los datos del usuario buscado.
      setDataUserSearched(
        response.content.find(
          (item: any) => item.document_number === searchUser
        )
      );
      setDataUserSearchedShow(
        response.content.find(
          (item: any) => item.document_number === searchUser
        )
      );

      // Finaliza la carga.
      setLoading && setLoading(false);
    } catch (error) {
      // En caso de error, muestra el error y finaliza la carga.
      console.log(error);
      setLoading && setLoading(false);
    }
  };

  useEffect(() => {
    console.log("runUploadDataInForm: ", runUploadDataInForm);
  }, [runUploadDataInForm]);

  const loadDataMatrix = async () => {
    setRunUploadDataInForm(true);
    setShowInitModal(false);
  };
 */
  return (
    <FancyPaper pagetitle="Mantenimiento / Matriz de riesgo 2">
      {factors === null || factors.length === 0 ? (
        <ErrorAlert
          message={
            !companyIndexDB?.company?.description
              ? "Debe seleccionar primero compañía y subcompañía"
              : "La compañía tiene subcompañías asociadas, debe seleccionar una de ellas"
          }
        />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={valueStep}
              onChange={handleChange}
              aria-label="tabs risk matrix"
            >
              <Tab label="Matriz de riesgo" {...a11yProps(0)} />
              <Tab label="Lexis Nexis" {...a11yProps(1)} />
              <Tab label="Resultados" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={valueStep} index={0}>
            {/* {!showInitModal && ( */}
            <Step1Agrupacion
              showInitModal={showInitModal}
              setShowInitModal={setShowInitModal}
              dataUserSearched={dataUserSearched}
              setDataUserSearched={setDataUserSearched}
              dataUserSearchedShow={dataUserSearchedShow}
              setDataUserSearchedShow={setDataUserSearchedShow}
              expanded={expanded}
              setExpanded={setExpanded}
              expendedTab1={expendedTab1}
              setExpendedTab1={setExpendedTab1}
              expendedTab2={expendedTab2}
              setExpendedTab2={setExpendedTab2}
              factorsAndOptions={factorsAndOptions}
              setFactorsAndOptions={setFactorsAndOptions}
              listShow={listShow}
              setListShow={setListShow}
              matrixGroups={matrixGroups}
              setMatrixGroups={setMatrixGroups}
              branchData={branchData}
              setBranchData={setBranchData}
              optionsSelected={optionsSelected}
              setOptionsSelected={setOptionsSelected}
              optionsTipoPersona={optionsTipoPersona}
              setOptionsTipoPersona={setOptionsTipoPersona}
              optionsEjecutivo={optionsEjecutivo}
              setOptionsEjecutivo={setOptionsEjecutivo}
              dataConstitutionTime={dataConstitutionTime}
              setDataConstitutionTime={setDataConstitutionTime}
              setValueStep={setValueStep}

              /*runUploadDataInForm={runUploadDataInForm} */
            />
            {/* )} */}
          </CustomTabPanel>
          <CustomTabPanel value={valueStep} index={1}>
            <Step2ValidateClient
              dataClient={dataUserSearchedShow}
              factorsAndOptions={factorsAndOptions}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueStep} index={2}>
            Item Three
          </CustomTabPanel>
        </Box>
      )}
      {/* <Dialog
        open={showInitModal}
        onClose={handleCloseInitModal}
        fullWidth
        disableEscapeKeyDown={false}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            pt: 4,
            px: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h5">Matriz de Riesgo</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!dataUserSearchedShow ? (
              <form onSubmit={handleSubmit(handleSearchUser)}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginY="1rem"
                >
                  <Typography color="black">
                    Buscar por número de identificación:
                  </Typography>
                  <Controller
                    control={control}
                    name="searchUser"
                    rules={{
                      required: "Este campo es obligatorio",
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Formato no válido",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        onChange={onChange}
                        value={value}
                        required
                        fullWidth
                        sx={{
                          maxWidth: "300px",
                        }}
                        inputProps={{}}
                        variant="standard"
                        error={!!errors.searchUser}
                        // required
                      />
                    )}
                  />
                  {errors.searchUser && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "5px",
                      }}
                    >
                      
                      {errors.searchUser.message ?? ""}
                    </span>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      marginTop: "2rem",
                      width: "150px",
                      height: "30px",
                    }}
                  >
                    Buscar
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowInitModal(false)}
                    sx={{
                      marginTop: "1rem",
                      height: "30px",
                    }}
                  >
                    Crear nueva matriz
                  </Button>
                </Box>
              </form>
            ) : (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  marginY="1rem"
                  textAlign="left"
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <strong>Cédula/RNC:</strong>
                          </TableCell>
                          <TableCell>
                            {dataUserSearchedShow.document_number}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Nombre cliente:</strong>
                          </TableCell>
                          <TableCell>{dataUserSearchedShow.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Fecha de actualización:</strong>
                          </TableCell>
                          <TableCell>
                            {dataUserSearchedShow.updated_at || "N/D"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Nivel de riesgo:</strong>
                          </TableCell>
                          <TableCell>
                            {dataUserSearchedShow.risk_level || "N/D"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: "2rem",
                      width: "150px",
                      marginX: "auto",
                    }}
                    onClick={loadDataMatrix}
                    //onClick={uploadDataInForm}
                  >
                    Cargar datos
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowInitModal(false)}
                    sx={{
                      marginTop: "1rem",
                      width: "200px",
                      height: "30px",
                      marginX: "auto",
                    }}
                  >
                    Crear nueva matriz
                  </Button>
                </Box>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setDataUserSearchedShow(null)}
          >
            Regresar
          </Button>
          <Button variant="outlined" onClick={handleCloseInitModal}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
    </FancyPaper>
  );
};

export default RiskMatrixTabs;
