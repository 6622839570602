import { styled } from "@mui/material/styles";
import { Tabs, Tab, Card } from "@mui/material";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderRight: 1,
  borderColor: "divider",
  width: "15%",
  minWidth: "150px",
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: "#A2A2A2",
  textAlign: "left",
  borderRadius: 0,
  borderBottom: "2px solid #8BC8BC",
  width: "100%",
  alignItems: "flex-start",
}));


export const TabCard = styled(Card)(({ theme }) => ({
    boxShadow:
      "0px 1px 1px 1px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
    width: "100%",
    backgroundColor: "#fafafa",
  }));
  