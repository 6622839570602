import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  BaseInput,
  MaskInput,
  RadioGroupInput,
  AutocompleteInput,
} from "../../Input";
import { validationSchema } from "./validation.schema";
import { useData } from "../../../../../hooks/useData";
import { useDueDiligenceContext } from "../../../provider/DuediligentProvider";
import { idText } from "typescript";

export const KycOnePannel = ({ factorsAndOptions }: any) => {
  const { dispatch, state } = useDueDiligenceContext();
  const [activity, setActivity] = useState<any>(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      firstName: state?.client?.name ?? "",
      lastName: "",
      street: "",
      building: "",
      idType: "cedula",
      idNumber: state?.client?.document_number ?? "",
      activity: "",
      town: "",
      address: "",
      province: "",
      homePhone: "",
      cellPhone: "",
      workPlace: "",
      workPhone: "",
      workAdress: "",
      role: "",
      email: "",
    },
  });
  //let activity: any = null;
  const filterActivity = async () => {
    let a = await factorsAndOptions?.filter(
      (item: any) => item.name === "Actividad Económica"
    )[0];
    setActivity(a);
  };

  useEffect(() => {
    const getData = async () => {
      await filterActivity();
    };
    if (factorsAndOptions !== null) {
      getData();
    }
  }, [factorsAndOptions]);

  useEffect(() => {
    let factores = state?.client?.factores;
    setValue("firstName", state?.client?.name);
    setValue("idNumber", state?.client?.document_number);
    const renderActivities = async () => {
      try {
        // Await the result of filterActivity()
        //await filterActivity();

        if (activity) {
          // Await the result of filtering factores
          const activityFactors = await Promise.resolve(
            factores?.filter((f) => f.factor_id === activity.id)
          );

          if (activityFactors && activityFactors.length > 0) {
            // Await the result of filtering activity.options
            const filteredActivityOption = await Promise.resolve(
              activity.options.filter(
                (option: any) =>
                  option.id === activityFactors[0].factor_option_id
              )
            );

            if (filteredActivityOption && filteredActivityOption[0]) {
              setValue("activity", filteredActivityOption[0]);
            } else {
              // Handle case where filteredActivityOption is not found
            }
          } else {
            // Handle case where activityFactors is not found
          }
        } else {
          // Handle case where filteredActivity is not found
        }
      } catch (error) {
        // Handle any errors that occur during filtering
        console.error("Error during filtering:", error);
      }
    };
    if (state.client.id !== "") {
      renderActivities();
    }
  }, [activity, state]);

  const formSubmit = (data: any) => {
    console.log({ data });
  };

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <form onSubmit={handleSubmit(formSubmit)} style={{ paddingLeft: 16 }}>
        <Grid container spacing={8} sx={{ pt: 0, pb: 0 }}>
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="firstName"
                label="Nombre"
                fullWidth
                control={control}
                errors={errors}
                register={register("firstName")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="lastName"
                fullWidth
                label="Apellidos"
                control={control}
                errors={errors}
                register={register("lastName")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <RadioGroupInput
                name="idType"
                control={control}
                errors={errors}
                label="Tipo ID"
                data={[
                  { value: "cedula", label: "Cédula" },
                  { value: "passport", label: "Pasaporte" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                //maskType="idNumber"
                name="idNumber"
                label="Número ID"
                fullWidth
                control={control}
                errors={errors}
                register={register("idNumber")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              {/*  <BaseInput
                name="activity"
                control={control}
                errors={errors}
                fullWidth
                multiline
                rows={2}
                register={register("activity")}
                label="Actividad económica"
              /> */}
              <AutocompleteInput
                name="activity"
                control={control}
                errors={errors}
                data={
                  factorsAndOptions?.filter(
                    (item: any) => item.name === "Actividad Económica"
                  )[0]?.options ?? []
                }
                inputProps={{
                  label: "Actividad económica",
                }}
                getOptionLabel={(option: any) => option.description}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2, minHeight: "62px" }}></Grid>
          </Grid>
          {/*Columna 2*/}
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="street"
                label="Calle"
                fullWidth
                control={control}
                errors={errors}
                register={register("street")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="building"
                label="Edificio"
                fullWidth
                control={control}
                errors={errors}
                register={register("building")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="town"
                label="Barrio"
                fullWidth
                control={control}
                errors={errors}
                register={register("town")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="address"
                label="Dirección"
                fullWidth
                control={control}
                errors={errors}
                multiline
                rows={2}
                register={register("address")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="province"
                label="Provincia"
                fullWidth
                control={control}
                errors={errors}
                register={register("province")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <MaskInput
                maskType="phone"
                name="homePhone"
                label="Teléfono Casa"
                fullWidth
                control={control}
                errors={errors}
                register={register("homePhone")}
              />
            </Grid>
          </Grid>
          {/*Columna 3*/}
          <Grid
            item
            container
            rowSpacing={2}
            xs={12}
            md={4}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <MaskInput
                maskType="phone"
                name="cellPhone"
                label="Teléfono Celular"
                fullWidth
                control={control}
                errors={errors}
                register={register("cellPhone")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="workPlace"
                label="Lugar de Trabajo"
                fullWidth
                control={control}
                errors={errors}
                register={register("workPlace")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <MaskInput
                maskType="phone"
                name="workPhone"
                label="Teléfono Trabajo"
                fullWidth
                control={control}
                errors={errors}
                register={register("workPhone")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="workAdress"
                label="Dirección Trabajo"
                fullWidth
                control={control}
                errors={errors}
                multiline
                rows={2}
                register={register("workAdress")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="role"
                label="Cargo Desempeñado"
                fullWidth
                control={control}
                errors={errors}
                register={register("role")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BaseInput
                name="email"
                label="Correo Electrónico"
                fullWidth
                control={control}
                errors={errors}
                register={register("email")}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
