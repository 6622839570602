import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TextField,
  Button,
  Stack,
  Dialog,
} from "@mui/material";
import {
  TrashIcon,
  PlusIcon,
  PenIcon,
  ConfigIcon,
} from "../components/svgicons/SvgIcons";
import { properties } from "../utils/Properties_es";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";

import FancyTablePagination from "../components/utils/FancyTablePagination";
import { useApp } from "../hooks/useApp";
import AddOrUpdateCompanyModal from "../components/utils/AddOrUpdateCompanyModal";
import AddOrUpdateRoleModal from "../components/utils/AddOrUpdateRoleModal";
import AddOrUpdateSettingsTrainingControlModal from "../components/utils/AddOrUpdateSettingsTrainingControlModal";
import AddOrUpdateSettingsEmployeesTrainingModal from "../components/utils/AddOrUpdateSettingsEmployeesTrainingModal";

import DeleteModal from "../components/utils/DeleteModal";
import { useForm } from "react-hook-form";

import {
  getRequest,
  createRequest,
  updateRequest,
  findByIdRequest,
  deleteRequest,
  createRequestCapacitationEmployee,
} from "../services/trainingcontrol/TrainingControl";

import ResourceAccess from "../components/security/ResourceAccess";
import { useData } from "../hooks/useData";
import ErrorAlert from "../components/utils/ErrorAlert";

import UpdateButton from "../components/utils/iconButtons/UpdateButton";
import DeleteButton from "../components/utils/iconButtons/DeleteButton";
import AddEmployeeButton from "../components/utils/iconButtons/AddEmployeeButton";

interface AddOrUpdateTrainingControl {
  id?: number;
  name: string;
  method: string;
  instructor: string;
  date: string;
  period: string;
  total_hours?: number;
}

const SettingTrainingControl = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();

  const theme = useTheme();
  const navigate = useNavigate();
  const [trainingData, setTrainingData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const { companyIndexDB, subCompanyData } = useData();
  const initForm = {
    id: 0,
    name: "",
    method: "",
    instructor: "",
    date: "",
    period: "",
    total_hours: "",
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();

  useEffect(() => {
    console.log("useEffect companyIndexDB=", companyIndexDB);
    //console.log("useEffect company.length=",companyIndexDB.company.length);
    const dataInit = async () => {
      //if (companyIndexDB.company === null) bActivo = false;
      if (companyIndexDB.company !== null || companyIndexDB.company !== null) {
        if (companyIndexDB.company.length != 0) {
          await handleFetchData(0, "");
        }
      }
    };
    if (companyIndexDB !== null) {
      //console.log(companyIndexDB);
      dataInit();
    }
  }, [companyIndexDB]);

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  const handleFetchData = async (currentPage: number, filter?: string) => {
    setLoading && setLoading(true);
    try {
      const companyId = companyIndexDB.company.id;
      const subcompanyId = companyIndexDB.subCompany
        ? companyIndexDB.subCompany.id
        : 0;

      setPreFilter(filter);
      setPage(currentPage);
      let data: any = await getRequest(
        currentPage,
        filter,
        companyId,
        subcompanyId
      );
      //console.log("handleFetchData=",data.content)
      if (data) {
        setTrainingData(data);
        //console.log(data);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    //console.log("onSubmit modalType=", modalData?.modalType);
    //console.log("onSubmit data=", data);
    //return;
    console.log(data);
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      case "addEmployee":
        await handleAddEmployee(data);
        break;
      default:
        break;
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      //crear companias
      /* */
      let createData = await createRequest({
        ...data,
      });
      //@ts-ignore
      const idCompany = createData.id;

      if (!createData) {
        setLoading && setLoading(false);
        //console.log("no se envia");
        return;
      }

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_request_update);
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        modifierUser: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_mapprisk_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_request_update);
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    //console.log("handleDelete id="+id);
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg("asd");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_request_delete);

      //reset page and call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let trainingData: any = await findByIdRequest(id);

      setFormData(trainingData.content[0]);
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleAddEmployee = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    console.log("Enttra a agregar empleado");
    console.log(data);
    try {
      let createData;
      if (data.length !== 0) {
        createData = await createRequestCapacitationEmployee({
          data,
        });

        if (!createData) {
          setLoading && setLoading(false);
          //console.log("no se envia");
          return;
        }
      }

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_request_update);
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    //console.log("handleOpenModal id=",id);
    //console.log("handleOpenModal companyIndexDB=",companyIndexDB);
    console.log("handleOpenModal modalAction=", modalAction);

    if (!companyIndexDB.company) {
      setErrorMsg && setErrorMsg("Debes seleccionar al menos una compañía");
      return;
    }
    if (companyIndexDB.company.length === 0) {
      setErrorMsg && setErrorMsg("Debes seleccionar al menos una compañía");
      return;
    }

    if (modalAction === "update") {
      await handleFetchByID(id);
    }

    if (modalAction === "addEmployee") {
      await handleFetchByID(id);
    }

    if (modalAction === "delete") {
      object = trainingData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  return (
    <>
      <FancyPaper pagetitle="Capacitaciones / Capacitaciones">
        {companyIndexDB === null ||
        companyIndexDB?.company === null ||
        companyIndexDB.company.length === 0 ? (
          <ErrorAlert message="Debe seleccionar primero compañía y subcompañía" />
        ) : (
          <>
            {subCompanyData?.length > 0 &&
              companyIndexDB?.subCompany === null && (
                <ErrorAlert message="La compañía tiene subcompañías asociadas, debe seleccionar una de ellas" />
              )}
            <Grid container spacing={8} sx={{ pb: 8 }}>
              <Grid item md={9} sm={6} xs={12}>
                <ResourceAccess isCode={true} pathOrCode={"CAPACITACIONES:WRITE"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    data-name="create"
                  >
                    Agregar capacitaciones
                    <PlusIcon sx={{ ml: 1 }} />
                  </Button>
                </ResourceAccess>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <TextField
                    placeholder="Filtro de búsqueda"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "0.2rem 0 0 0.2rem",
                      },
                      "& fieldset": {
                        borderRightWidth: "0",
                      },
                    }}
                    value={preFilter}
                    onChange={(e: any) => {
                      setPreFilter(e.target.value);
                      if (e.target.value === "") {
                        handleFetchData(0, "");
                      }
                    }}
                    onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApplyFilter}
                    sx={{
                      borderRadius: "0 0.2rem 0.2rem 0!important",
                      padding: "1.5px 16px!important",
                    }}
                  >
                    Buscar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Modalidad</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Período</TableCell>
                    <TableCell>Instructor</TableCell>
                    <TableCell>Duración</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainingData.content &&
                    trainingData.content.map((row: any, i: number) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.method}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.period}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.instructor}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.total_hours}
                        </TableCell>
                        <TableCell align="center">
                          <ResourceAccess
                            isCode={true}
                            pathOrCode={"CAPACITACIONES:WRITE"}
                          >
                            <UpdateButton
                              dataID={row.id}
                              dataName={"update"}
                              handleOpenModal={handleOpenModal}
                              disabled={false}
                            />

                            <DeleteButton
                              dataID={row.id}
                              dataName={"delete"}
                              handleOpenModal={handleOpenModal}
                              disabled={false}
                            />

                            <AddEmployeeButton
                              dataID={row.id}
                              dataName={"addEmployee"}
                              handleOpenModal={handleOpenModal}
                              disabled={false}
                            />
                          </ResourceAccess>
                        </TableCell>
                      </TableRow>
                    ))}

                  {(!trainingData ||
                    !trainingData.content ||
                    trainingData.content.length <= 0) && (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No existen registros
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <FancyTablePagination
              count={
                trainingData?.content?.length > 0
                  ? trainingData?.content?.length
                  : 0
              }
              rowsPerPage={trainingData.size}
              page={page}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              totalElements={trainingData.totalElements}
              totalPages={trainingData.totalPages}
              numberOfElements={trainingData?.numberOfElements ?? 0}
            />
          </>
        )}
      </FancyPaper>

      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateSettingsTrainingControlModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}

      {modalData?.modalType === "addEmployee" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateSettingsEmployeesTrainingModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}

      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.description}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingTrainingControl;
