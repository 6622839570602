import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Button,
  Dialog,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useApp } from "../../hooks/useApp";
import { useData } from "../../hooks/useData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyPaperValidateClient from "../../components/FancyPaperValidateClient";
import AddMoreQueries from "../../components/utils/AddMoreQueries";
import ValidateClientDetalis from "../../components/utils/ValidateClientDetalis";
import FancyTooltip from "../../components/utils/FancyTooltip";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InputLabelToForm from "../../components/InputLabelToForm";
import {
  searchClient,
  searchClientBlackListAndInternalList,
  requestCountries,
} from "../../services/searchClient/SearchClient";
import {
  capitalize,
  formatPropertyName,
  searchAuthority,
} from "../../utils/UtilsFunctions";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import {
  generatePDFGeneral,
  generatePDFIndividual,
} from "../../services/downloadPdf/DownloadPDFServide";
import { getIndexData } from "../../utils/LocalStorageManager";
import {
  getRequestInquiries,
  requestMoreInquiries,
} from "../../services/DashboardServices";
import { getInternalListDocument } from "../../services/externalapi/ExternalApi";
import { uploadDocumentBatch } from "../../services/batch/BatchService";
import { PatternFormat } from "react-number-format";
import {
  ClienteData,
  CountriesTypes,
  TabPanelProps,
  documentNameType,
} from "../../types/ValidateClient";

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Step2ValidateClient = ({ dataClient, factorsAndOptions }: any) => {
  const theme = useTheme();
  const {
    setLoading,
    setErrorMsg,
    authInfo,
    modalData,
    setModalData,
    setSuccessMsg,
  } = useApp();
  const { companyIndexDB } = useData();
  const [searchData, setSearchData] = useState(false);
  const [typePersonValidate, setTypePersonValidate] = useState("Física");
  const [listSearch, setListSearch] = useState<any>(null);
  const [listSearchBlackList, setListSearchBlackList] = useState<any>(null);
  const [listSearchCRCResult, setListSearchCRCResult] = useState<any>(null);
  const [listSearchJCEResult, setListSearchJCEResult] = useState<any>(null);
  const [dataSelected, setDataSelected] = useState<any>({});
  const [page, setPage] = useState(0);
  const [allInput, setAllInput] = useState<any>({});
  const [localCompanyData, setLocalCompanyData] = useState<any>(null);
  const [localCompanyAdminData, setLocalCompanyAdminData] = useState<any>(null);
  const [localSubCompanyAdminData, setLocalSubCompanyAdminData] =
    useState<any>(null);
  const [numberInquiries, setNumberInquiries] = useState<any>({});
  const [modalError, setModalError] = useState<any>(false);
  const [modalAddQueries, setModalAddQueries] = useState<any>(false);
  const [numConsultas, setNumConsultas] = useState<any>(0);
  const [value, setValue] = useState(0);
  const [documentName, setDocumentName] = useState<documentNameType | null>(
    null
  );
  const [countries, setCountries] = useState<CountriesTypes[] | null>(null);
  const [clientName, lastNameClient] = dataClient?.name.split(" ");

  useEffect(() => {
    const getData = async () => {
      if (dataClient.type === "F") {
        setTypePersonValidate("Física");
      } else {
        setTypePersonValidate("Jurídica");
      }
      await handleFetchCountries();
      const getLocalCompanyDataAdmin: any = await getIndexData(
        "setLocalCompany"
      );
      console.log("getLocalCompanyDataAdmin: ", getLocalCompanyDataAdmin);
      const getLocalSubCompanyDataAdmin: any = await getIndexData(
        "setLocalSubCompany"
      );
      console.log("getLocalSubCompanyDataAdmin: ", getLocalSubCompanyDataAdmin);

      setLocalCompanyAdminData(getLocalCompanyDataAdmin);
      setLocalSubCompanyAdminData(getLocalSubCompanyDataAdmin);
      /* setLocalCompanyAdminData(getLocalCompanyDataAdmin?.companyAllData);
      setLocalSubCompanyAdminData(getLocalCompanyDataAdmin?.subCompanyAllData); */

      const getLocalCompanyData: any = await getIndexData("companyData");
      //console.log("getLocalCompanyData?.id: ", getLocalCompanyDataAdmin?.id);
      setLocalCompanyData({
        company: {
          id: getLocalCompanyDataAdmin?.id,
        },
        subCompany: {
          id: getLocalSubCompanyDataAdmin?.id,
        },
        /* screeningNumberInquiries:
          getLocalCompanyDataSelect?.screeningNumberInquiries === null
            ? false
            : getLocalCompanyDataSelect?.screeningNumberInquiries, */
        massiveRequest:
          getLocalCompanyData?.screeningNumberInquiries === null
            ? false
            : getLocalCompanyData?.screeningNumberInquiries,
      });
    };
    getData();
  }, []);

  const downloadPdfIndividual = async (data: any) => {
    setLoading && setLoading(true);
    //console.log("listSearchCRCResult: ", listSearchCRCResult);
    //console.log(data);
    const filterJCE =
      listSearchJCEResult !== null &&
      data.entityDetails.ids.entityID.some(
        (item: any) =>
          item.number ===
          listSearchJCEResult?.mun_ced +
            listSearchJCEResult?.seq_ced +
            listSearchJCEResult?.ver_ced
      );
    //console.log("filterJCE: ", filterJCE);

    try {
      let newData;
      if (
        !listSearchBlackList &&
        !listSearchCRCResult &&
        !listSearchJCEResult &&
        !data
      ) {
        newData = {};
      } else {
        newData = {
          lexisNexisResult: data,
          blackListResult: listSearchBlackList,
          crcResult: listSearchCRCResult,
          jceResult: filterJCE ? listSearchJCEResult : null,
        };
      }
      //console.log(newData);
      const response = await generatePDFIndividual(
        authInfo.username,
        typePersonValidate === "Física"
          ? `${watchName} ${watchLastname}`
          : `${watchJuridicoNombre}`,
        localCompanyData?.company?.id || 0,
        localCompanyData?.subCompany?.id,
        newData
      );
      const blob = new Blob([response.data], { type: "application/pdf" }); // Cambiado a 'application/pdf'
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${
        typePersonValidate === "Física"
          ? allInput.name + " " + allInput.lastname
          : allInput.juridico_nombre
      }.pdf`; // Cambiado a extensión .pdf
      downloadLink.click();
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
    //setLoading && setLoading(false);
  };

  const downloadPdfGeneral = async () => {
    setLoading && setLoading(true);

    const listSearchxPage = listSearch?.slice(page * 10, (page + 1) * 10);
    const totalPage = (listSearch?.length / 10).toFixed(0);

    let newData;
    if (
      !listSearchxPage &&
      !listSearchBlackList &&
      !listSearchCRCResult &&
      !listSearchJCEResult
    ) {
      newData = {};
    } else {
      newData = {
        lexisNexisResult: listSearchxPage,
        blackListResult: listSearchBlackList,
        crcResult: listSearchCRCResult,
        jceResult: listSearchJCEResult,
      };
    }
    const response = await generatePDFGeneral(
      authInfo.username,
      typePersonValidate === "Física"
        ? `${watchName} ${watchLastname}`
        : `${watchJuridicoNombre}`,
      localCompanyData?.company?.id || 0,
      localCompanyData?.subCompany?.id,
      page,
      totalPage ? Number(totalPage) : 0,
      newData
    );
    const blob = new Blob([response.data], { type: "application/pdf" }); // Cambiado a 'application/pdf'
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `Resultado General ${
      typePersonValidate === "Física"
        ? allInput.name + " " + allInput.lastname
        : allInput.juridico_nombre
    } Pagina ${page + 1}.pdf`;
    downloadLink.click();
    setLoading && setLoading(false);
  };

  //console.log("localCompanyData: ", localCompanyData);

  const handleNumbersInquiries = async () => {
    let getNumberInquiries: any = null;
    /* localCompanyData !== null &&
      localCompanyData.company.id !== undefined &&
      (!!localCompanyData?.subCompany?.id
        ? (getNumberInquiries = await getRequestInquiries(
            "",
            localCompanyData.subCompany.id
          ))
        : (getNumberInquiries = await getRequestInquiries(
            localCompanyData.company.id,
            ""
          ))); */
    companyIndexDB !== null &&
      companyIndexDB.company.screeningCompanyId !== undefined &&
      (!!companyIndexDB?.subCompany?.screeningSubCompanyId
        ? (getNumberInquiries = await getRequestInquiries(
            "",
            companyIndexDB.subCompany.screeningSubCompanyId
          ))
        : (getNumberInquiries = await getRequestInquiries(
            companyIndexDB.company.screeningCompanyId,
            ""
          )));
    if (getNumberInquiries) {
      //console.log("getNumberInquiries: ", getNumberInquiries);
      setNumberInquiries(getNumberInquiries);
    }
  };

  const handleInternalListDocument = async (
    company: string,
    subCompany?: string
  ) => {
    try {
      setLoading && setLoading(true);
      let data: any = await getInternalListDocument(company, subCompany);
      if (data) {
        setDocumentName(data);
        setLoading && setLoading(false);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleFetchCountries = async () => {
    try {
      let getCountries: any = await requestCountries();
      if (getCountries) {
        setCountries(getCountries);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
    }
  };

  useEffect(() => {
    if (
      localCompanyData !== null &&
      localCompanyData.company.id !== undefined
    ) {
      //console.log("localCompanyData: ", localCompanyData);
      handleNumbersInquiries();
      handleInternalListDocument(
        localCompanyData.company.id,
        localCompanyData.subCompany.id
      );
    }
  }, [localCompanyData]);

  useEffect(() => {
    if (companyIndexDB !== null) {
      setLocalCompanyData({
        ...localCompanyData,
        company: {
          id: companyIndexDB?.company?.id,
        },
        subCompany: {
          id: companyIndexDB?.subCompany?.id,
        },
      });
    }
  }, [companyIndexDB]);

  const isAdmin = authInfo.userRole.authorityRoles.some(
    (authority: any) => authority.authority.code === "ADMIN:WRITE"
  );

  useEffect(() => {
    if (!listSearch && localCompanyAdminData !== null) {
      console.log(dataClient);
      onSubmit(dataClient);
    }
    const country = searchFactor("Datos del cliente")[1].id;
    console.log("Datos del cliente: ", country);
  }, [localCompanyAdminData, localSubCompanyAdminData]);

  const searchFactor = (label: string) => {
    console.log("factorsAndOptions: ", factorsAndOptions);
    const factor = factorsAndOptions?.find(
      (factor: any) => factor.name === label
    );
    return factor ? factor.factors : [];
  };

  const onSubmit = async (data: any) => {
    console.log("Ingresa onSubmit ValidateClient2", data);
    // const getNumberInquiries: any = await handleNumbersInquiries();
    if (numberInquiries.remainingRequest <= 0 && !isAdmin) {
      setErrorMsg && setErrorMsg("No tiene consultas disponibles");
      return;
    }

    try {
      setListSearch([]);
      setLoading && setLoading(true);
      setAllInput({});

      let dataInput = {};

      const nacionalityOptionsFactor: any = searchFactor(
        "Datos del cliente"
      ).find((item: any) => item.name === "Nacionalidad");

      const nacionalityClient = data.factores.find(
        (item: any) => item.factor_id === nacionalityOptionsFactor.id
      );

      if (typePersonValidate === "Física") {
        dataInput = {
          first_name: clientName,
          last_name: lastNameClient,
          entity_type: "INDIVIDUAL",
          user_id: authInfo.username,
          company_id:
            localSubCompanyAdminData?.screeningCompanyId ??
            localCompanyAdminData.screeningCompanyId,
          sub_company_id:
            localSubCompanyAdminData?.screeningSubCompanyId ??
            localCompanyAdminData.screeningSubCompanyId,
          address: data.address1,
          document_id_number: data.document_number,
          id1: data.document_number.slice(0, 3),
          id2: data.document_number.slice(4, 11),
          id3: data.document_number.slice(12, 13),
          city: data.city,
          nationality: nacionalityOptionsFactor.options.find(
            (item: any) => item.id === nacionalityClient.factor_option_id
          )?.name,
          file_id: documentName?.id,
          crc: data.crc,
          jce: data.jce,
        };
      } else {
        dataInput = {
          business_name: data.juridico_nombre,
          entity_type: "BUSINESS",
          user_id: authInfo.username,
          company_id:
            localSubCompanyAdminData?.screeningCompanyId ??
            localCompanyData.screeningCompanyId,
          sub_company_id:
            localSubCompanyAdminData?.screeningSubCompanyId ??
            localCompanyData.screeningSubCompanyId,
          address: data.address1,
          document_id_number: data.juridico_id,
          city: data.city,
          nationality: data?.country?.name,
          file_id: documentName?.id,
        };
      }
      //console.log("dataInput: ", dataInput);
      /**Este busca solo en lexis nexis*/
      //const dataRequest = await searchClient(dataInput);

      /**Este busca en lexis nexis y lista interna*/
      const dataRequest = await searchClientBlackListAndInternalList(dataInput);

      /**Lexis Nexis */
      if (dataRequest) {
        if (dataRequest?.lexisNexisResult !== null) {
          setListSearch(
            dataRequest?.lexisNexisResult[0]?.watchlist.matches.wlmatch
          );
        } else {
          setListSearch(null);
        }
        if (dataRequest?.blackListResult !== null) {
          setListSearchBlackList(dataRequest?.blackListResult);
        } else {
          setListSearchBlackList(null);
        }
        if (dataRequest?.crcResult !== null) {
          setListSearchCRCResult(dataRequest?.crcResult);
        } else {
          setListSearchCRCResult(null);
        }
        if (dataRequest?.jceResult !== null) {
          setListSearchJCEResult(dataRequest?.jceResult);
        } else {
          setListSearchJCEResult(null);
        }
      }

      if (
        dataRequest &&
        (dataRequest?.lexisNexisResult !== null ||
          dataRequest?.blackListResult !== null ||
          dataRequest?.crcResult !== null ||
          dataRequest?.jceResult !== null)
      ) {
        setSearchData(true);
        setLoading && setLoading(false);
        setAllInput(data);
      } else {
        setErrorMsg && setErrorMsg("No se detectaron coincidencias");
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      console.log("Error: ", error);
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };
  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const onBackSearch = async () => {
    setListSearch([]);
    setPage(0);
    reset(defaultValues);
    setTypePersonValidate("Física");
    handleNumbersInquiries();
    try {
      setSearchData(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    /**/
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: null,
      });
  };

  const handleCancelModal = () => {
    setDataSelected({});
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangeTypePerson = (event: any) => {
    setTypePersonValidate(event.target.value);
    clearErrors("country");
  };

  const formSchemaOptions = {
    id: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
  };

  const validationFisica = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    lastname: Yup.string().required("Campo es requerido"),
    crc: Yup.boolean(),
    jce: Yup.boolean(),
    document_id_number: Yup.string().when(["crc", "jce"], {
      is: (crc: boolean, jce: boolean) => {
        if (crc === true || jce === true) {
          return true;
        }
      },
      then: Yup.string().required("Campo es requerido"),
      otherwise: Yup.string(),
    }),
  });

  const validationJuridica = Yup.object().shape({
    country: Yup.object().nullable(),
  });

  const defaultValues = {
    id: 0,
    name: clientName,
    typeperson: dataClient.type === "F" ? "Física" : "Jurídica",
    name2: "",
    lastname: lastNameClient ?? "",
    lastname2: "",
    birthday: "",
    address1: "",
    document_id_number: dataClient.document_number ?? "",
    city: "",
    year: 0,
    phonework: "",
    phone: "",
    crc:
      companyIndexDB !== null && companyIndexDB.subCompany?.id
        ? companyIndexDB.subCompany.isCRC
        : companyIndexDB.company.isCRC !== null
        ? companyIndexDB.company.isCRC
        : false,
    jce:
      companyIndexDB !== null && companyIndexDB.subCompany?.id
        ? companyIndexDB.subCompany.isJCE
        : companyIndexDB.company.isJCE !== null
        ? companyIndexDB.company.isJCE
        : false,
    juridico_nombre: "",
    juridico_id: "",
    country: [],
    // typeperson: [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    clearErrors,
  } = useForm<ClienteData>({
    defaultValues,
    resolver: yupResolver(
      typePersonValidate === "Física" ? validationFisica : validationJuridica
    ),
    mode: "onChange",
  });

  const cleanData = () => {
    reset(defaultValues);
    setTypePersonValidate("Física");
  };

  const handleRequestMoreInquiries = async (data: any) => {
    setLoading && setLoading(true);
    let responseRequestMoreInquiries: any = null;
    const requestInquiries: any = {
      name: authInfo?.name,
      email: authInfo.username,
      company: localCompanyData?.company?.id || 0,
      subcompany: localCompanyData?.subCompany?.id || 0,
      numberRequest: data.queries,
    };
    try {
      responseRequestMoreInquiries = await requestMoreInquiries(
        "21",
        requestInquiries
      );
      if (responseRequestMoreInquiries) {
        setSuccessMsg &&
          setSuccessMsg("Solicitud de consultas envíada al administrador");
        setLoading && setLoading(false);
      }
    } catch (error) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg("Error al solicitar más consultas");
    }
    setModalAddQueries(!modalAddQueries);
    setModalError(!modalError);
  };

  const watchName = watch("name");
  const watchLastname = watch("lastname");
  const watchJuridicoNombre = watch("juridico_nombre");

  const uploadDocument = async (e: any) => {
    try {
      setLoading && setLoading(true);
      const file = e.target.files[0];

      if (file.type !== "text/csv") {
        setErrorMsg && setErrorMsg("Solo se aceptan archivos CSV.");
        setLoading && setLoading(false);
        e.target.value = null; // Clear the input
        return; // Exit the function early
      }

      await uploadDocumentBatch(
        file,
        authInfo.username,
        localCompanyData?.company?.id || 0,
        localCompanyData?.subCompany?.id || 0
      );
      setSuccessMsg &&
        setSuccessMsg("Cuando el proceso termine se le notificará por correo");
      setLoading && setLoading(false);

      //limpiar el input
      e.target.value = null;
    } catch (error: any) {
      e.target.value = null;

      if (!!error.message) {
        setNumConsultas(error.message);
        setModalError(!modalError);
      } else {
        setErrorMsg && setErrorMsg("Error inesperado al subir el archivo.");
      }

      setLoading && setLoading(false);
      console.log(error);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 5 }}>
          {!searchData && isAdmin && (
            <Typography fontSize="16px">
              Consultas disponibles:{" "}
              <span style={{ fontWeight: "bold" }}>
                {numberInquiries.remainingRequest || "0"} de{" "}
                {numberInquiries.numberInquiries || "0"}
              </span>
            </Typography>
          )}

          {/* {!searchData ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Card variant="outlined" sx={{ maxWidth: 1000 }}>
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                      maxWidth="lg"
                      sx={{ pt: 0, pb: 0 }}
                    >
                      <Grid item sm={8} xs={10} sx={{ marginTop: "16px" }}>
                        <Typography
                          color="inherit"
                          sx={{ fontSize: "1.5em", flexGrow: 1 }}
                          noWrap
                          component="div"
                        >
                          Ingresa los datos del cliente
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        data-isCRC={localCompanyAdminData?.isCRC}
                        data-isCRCSub={localSubCompanyAdminData?.isCRC}
                      >
                        <Controller
                          control={control}
                          name="crc"
                          rules={{ required: false }}
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={onChange}
                                  checked={value}
                                  {...field}
                                  disabled={
                                    localCompanyAdminData?.isCRC !== true &&
                                    localSubCompanyAdminData?.isCRC !== true
                                  }
                                />
                              }
                              label="CRC"
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="jce"
                          rules={{ required: false }}
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={onChange}
                                  checked={value}
                                  {...field}
                                  disabled={
                                    localCompanyAdminData?.isJCE !== true &&
                                    localSubCompanyAdminData?.isJCE !== true
                                  }
                                />
                              }
                              label="JCE"
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        xs={12}
                        sx={{
                          marginTop: "16px",
                          textAlign: {
                            sm: "right",
                            xs: "left",
                          },
                        }}
                      >
                        <Controller
                          name="typeperson"
                          control={control}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabelToForm style={{ top: 10 }}>
                                Tipo
                              </InputLabelToForm>
                              <Select
                                {...field}
                                variant="standard"
                                sx={{
                                  "& select": {
                                    pl: "0!important",
                                  },
                                  textAlign: "left",
                                  "& .MuiSelect-select": {},
                                }}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleChangeTypePerson(e); // Custom onChange logic
                                }}
                              >
                                <MenuItem value="Física">Física</MenuItem>
                                <MenuItem value="Jurídica">Jurídica</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>

                    <form>
                      <Grid
                        container
                        spacing={3}
                        maxWidth="lg"
                        sx={{ pt: 4, pb: 8 }}
                      >
                        {typePersonValidate === "Física" ? (
                          <Grid item xs={12} md={3} lg={3}>
                            <Controller
                              name={"name"}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  label="Nombre"
                                  type="text"
                                  variant="standard"
                                  value={value}
                                  sx={{ "& input": { pl: "0!important" } }}
                                  {...register("name")}
                                  error={errors.name && Boolean(errors.name)}
                                  helperText={
                                    errors.name && errors.name.message
                                  }
                                />
                              )}
                            />
                          </Grid>
                        ) : null}

                        {typePersonValidate === "Física" ? (
                          <Grid item xs={12} md={6} lg={3}>
                            <Controller
                              name={"name2"}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  label="Segundo nombre"
                                  type="text"
                                  variant="standard"
                                  value={value}
                                  sx={{ "& input": { pl: "0!important" } }}
                                  {...register("name2")}
                                  error={errors.name2 && Boolean(errors.name2)}
                                  helperText={
                                    errors.name2 && errors.name2.message
                                  }
                                />
                              )}
                            />
                          </Grid>
                        ) : null}

                        {typePersonValidate === "Física" ? (
                          <Grid item xs={12} md={6} lg={3}>
                            <Controller
                              name={"lastname"}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  label="Primer Apellido"
                                  type="text"
                                  variant="standard"
                                  value={value}
                                  sx={{ "& input": { pl: "0!important" } }}
                                  {...register("lastname")}
                                  error={
                                    errors.lastname && Boolean(errors.lastname)
                                  }
                                  helperText={
                                    errors.lastname && errors.lastname.message
                                  }
                                />
                              )}
                            />
                          </Grid>
                        ) : null}

                        {typePersonValidate === "Física" ? (
                          <Grid item xs={12} md={6} lg={3}>
                            <Controller
                              name={"lastname2"}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  label="Segundo Apellido"
                                  type="text"
                                  variant="standard"
                                  value={value}
                                  sx={{ "& input": { pl: "0!important" } }}
                                  {...register("lastname2")}
                                  error={
                                    errors.lastname2 &&
                                    Boolean(errors.lastname2)
                                  }
                                  helperText={
                                    errors.lastname2 && errors.lastname2.message
                                  }
                                />
                              )}
                            />
                          </Grid>
                        ) : null}

                        {typePersonValidate === "Jurídica" ? (
                          <Grid item xs={12} md={6} lg={6}>
                            <Controller
                              name={"juridico_nombre"}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  label="Nombre"
                                  type="text"
                                  variant="standard"
                                  value={value}
                                  sx={{ "& input": { pl: "0!important" } }}
                                  {...register("juridico_nombre")}
                                  error={
                                    errors.juridico_nombre &&
                                    Boolean(errors.juridico_nombre)
                                  }
                                  helperText={
                                    errors.juridico_nombre &&
                                    errors.juridico_nombre.message
                                  }
                                />
                              )}
                            />
                          </Grid>
                        ) : null}
                        {typePersonValidate === "Jurídica" ? (
                          <Grid item xs={12} md={6} lg={6}>
                            <Controller
                              name={"juridico_id"}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  label="Identificación"
                                  type="text"
                                  variant="standard"
                                  value={value}
                                  sx={{ "& input": { pl: "0!important" } }}
                                  {...register("juridico_id")}
                                  error={
                                    errors.juridico_id &&
                                    Boolean(errors.juridico_id)
                                  }
                                  helperText={
                                    errors.juridico_id &&
                                    errors.juridico_id.message
                                  }
                                />
                              )}
                            />
                          </Grid>
                        ) : null}

                        <Grid item xs={12} md={6} lg={12}>
                          <Controller
                            name={"address1"}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                size="small"
                                label="Dirección"
                                type="text"
                                variant="standard"
                                value={value}
                                sx={{ "& input": { pl: "0!important" } }}
                                {...register("address1")}
                                error={
                                  errors.address1 && Boolean(errors.address1)
                                }
                                helperText={
                                  errors.address1 && errors.address1.message
                                }
                              />
                            )}
                          />
                        </Grid>

                        <Grid item sx={{ mt: 5 }} xs={12} md={4} lg={4}>
                          <Controller
                            control={control}
                            name="country"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                onChange={(event, newValue: any) => {
                                  onChange(newValue);
                                }}
                                options={
                                  countries ?? [
                                    {
                                      id: 0,
                                      name: "",
                                    },
                                  ]
                                }
                                getOptionLabel={(option: any) =>
                                  option.name || ""
                                }
                                isOptionEqualToValue={(
                                  option: any,
                                  value: any
                                ) => option.id === value.id}
                                //@ts-ignore
                                value={value}
                                renderInput={(params) => {
                                  const inputProps = params.inputProps;
                                  inputProps.autoComplete = "new-password";
                                  return (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      label="Seleccione el país"
                                      variant="standard"
                                      sx={{ "& input": { pl: "0!important" } }}
                                      error={!!errors.country}
                                      helperText={
                                        errors.country && errors.country.message
                                      }
                                    />
                                  );
                                }}
                              />
                            )}
                          />
                        </Grid>

                        {typePersonValidate === "Física" && (
                          <Grid item sx={{ mt: 5 }} xs={12} md={4} lg={4}>
                            <Controller
                              name={"document_id_number"}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <FormControl
                                  sx={{ width: "100%" }}
                                  className={`input-mapprisk ${
                                    errors.document_id_number && "error"
                                  }`}
                                >
                                  <FormLabel sx={{ color: "#5d5e60" }}>
                                    Número de identificación
                                  </FormLabel>
                                  <PatternFormat
                                    name={"document_id_number"}
                                    onPaste={(e: any) => e.preventDefault()}
                                    mask="_"
                                    format={"###-#######-#"}
                                    value={value}
                                    onChange={onChange}
                                    placeholder={"###-#######-#"}
                                  />
                                  <span></span>
                                </FormControl>
                              )}
                            />
                            {errors.document_id_number && (
                              <FormHelperText>
                                {errors.document_id_number.message}
                              </FormHelperText>
                            )}
                          </Grid>
                        )}                      
                        <Grid item sx={{ mt: 5 }} xs={12} md={4} lg={4}>
                          <Controller
                            name={"city"}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                size="small"
                                label="Ciudad"
                                type="text"
                                variant="standard"
                                value={value}
                                autoComplete="name3"
                                sx={{ "& input": { pl: "0!important" } }}
                                {...register("city")}
                                error={errors.city && Boolean(errors.city)}
                                helperText={errors.city && errors.city.message}
                              />
                            )}
                          />
                        </Grid>

                        <Grid
                          item
                          sx={{ mt: 5 }}
                          xs={12}
                          md={12}
                          lg={12}
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={cleanData}
                            sx={{
                              mt: 2,
                              mr: 2,
                            }}
                          >
                            Limpiar
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              mt: 2,
                              mr: 2,
                            }}
                            onClick={handleSubmit(onSubmit)}
                          >
                            Realizar consulta
                          </Button>
                        </Grid>                       
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Box>
            ) : ( */}
          <Box sx={{ mt: 5 }}>
            <Box
              sx={{
                width: "100%",
                borderBottom: "1px solid #eee",
                paddingBottom: 5,
              }}
              display="flex"
            >
              <Typography
                color="inherit"
                sx={{ fontSize: "1.5em", flexGrow: 1 }}
                noWrap
                component="div"
              >
                Consulta realizada a:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {typePersonValidate === "Física"
                    ? `${watchName} ${watchLastname}`
                    : `${watchJuridicoNombre}`}
                </span>
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mt: 2,
                  mr: 2,
                }}
                onClick={onBackSearch}
              >
                Regresar
              </Button>
            </Box>
            <Box
              sx={{
                width: "100%",
                borderBottom: "1px solid #eee",
                paddingBottom: 5,
                paddingTop: 5,
              }}
              display="flex"
            >
              <Button variant="contained" onClick={downloadPdfGeneral}>
                {/* <a href='#' onClick={onDownloadPdf}> */}
                Descargar PDF
                {/* </a> */}
              </Button>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Lexis nexis" {...a11yProps(0)} />
                <Tab
                  label="Lista interna"
                  {...a11yProps(1)}
                  disabled={documentName === null}
                />
                <Tab
                  label="Resultados CRC"
                  {...a11yProps(2)}
                  disabled={
                    localCompanyAdminData?.isCRC !== true &&
                    localSubCompanyAdminData?.isCRC !== true
                  }
                />
                <Tab
                  label="Resultados JCE"
                  {...a11yProps(3)}
                  disabled={
                    localCompanyAdminData?.isJCE !== true &&
                    localSubCompanyAdminData?.isJCE !== true
                  }
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell align="center">Coincidencia</TableCell>
                      <TableCell align="center">Nombre</TableCell>
                      {typePersonValidate === "Física" && (
                        <>
                          <TableCell align="center">
                            Fecha de nacimiento
                          </TableCell>
                          <TableCell>Género</TableCell>
                        </>
                      )}

                      <TableCell>Número de ID</TableCell>
                      <TableCell>Dirección</TableCell>
                      <TableCell>País</TableCell>
                      <TableCell>Tipo de entidad</TableCell>

                      {typePersonValidate === "Física" && (
                        <TableCell>Ciudadanía</TableCell>
                      )}

                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!listSearch ? (
                      listSearch
                        .slice(page * 10, (page + 1) * 10)
                        .map((row: any, i: number) => (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {listSearch.indexOf(row) + 1}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row.bestNameScore}%
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row.entityName}
                            </TableCell>
                            {typePersonValidate === "Física" && (
                              <>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {row.entityDetails.additionalInfo.entityAdditionalInfo
                                    .filter((item: any) => item.type === "DOB")
                                    .slice(0, 2)
                                    .map((item: any, index: number) => {
                                      return (
                                        <span key={index}>
                                          {item.value} <br />
                                        </span>
                                      );
                                    })}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.entityDetails.gender}
                                </TableCell>
                              </>
                            )}
                            <TableCell component="th" scope="row">
                              {row.entityDetails.ids.entityID
                                .slice(0, 2)
                                .map((item: any, index: number) => {
                                  return (
                                    <span key={index}>
                                      {item.number} (
                                      {formatPropertyName(item.type)}) <br />
                                    </span>
                                  );
                                })}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {!!row.entityDetails.addresses &&
                                row.entityDetails.addresses.entityAddress
                                  .slice(0, 2)
                                  .map((item: any, index: number) => {
                                    return (
                                      <span key={index}>
                                        {item.city} {item.stateProvinceDistrict}{" "}
                                        {item.country} <br />
                                      </span>
                                    );
                                  })}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {!!row.entityDetails.addresses &&
                                row.entityDetails.addresses.entityAddress
                                  .slice(0, 1)
                                  .map((item: any, index: number) => {
                                    return (
                                      <span key={index}>
                                        {item.country} <br />
                                      </span>
                                    );
                                  })}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {capitalize(row.entityDetails.entityType)}
                            </TableCell>
                            {typePersonValidate === "Física" && (
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {row.entityDetails.additionalInfo.entityAdditionalInfo
                                  .filter(
                                    (item: any) => item.type === "CITIZENSHIP"
                                  )
                                  .map((item: any, index: number) => {
                                    return (
                                      <span key={index}>
                                        {item.value} <br />
                                      </span>
                                    );
                                  })}
                              </TableCell>
                            )}

                            <TableCell align="center">
                              <FancyTooltip
                                title={"Ver detalle"}
                                placement="top"
                              >
                                <IconButton
                                  aria-label="userdetails"
                                  component="label"
                                  color="primary"
                                  sx={{
                                    "&:hover": {
                                      color: theme.palette.secondary.dark,
                                    },
                                  }}
                                  data-id={row.capacitationId}
                                  data-name="userdetails"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenModal(e);
                                    setDataSelected(row);
                                  }}
                                >
                                  <FormatListBulletedIcon />
                                </IconButton>
                              </FancyTooltip>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={typePersonValidate === "Física" ? 10 : 8}
                          align="center"
                        >
                          No hay coincidencias
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {listSearch?.length > 0 && (
                <FancyTablePagination
                  count={
                    // usersData.totalElements && usersData.totalElements > 0 ? usersData.totalElements : 0
                    listSearch.length
                  }
                  rowsPerPage={10}
                  page={page}
                  onPageChange={handleChangePage}
                  // onRowsPerPageChange={(e: any) => console.log(e.target.value)}
                  totalElements={listSearch.length}
                  totalPages={(listSearch.length / 10).toFixed(0)}
                  numberOfElements={
                    listSearch.length > 10
                      ? Math.min(10, listSearch.length - page * 10)
                      : listSearch.length
                  }
                />
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Tipo de cliente</TableCell>
                      <TableCell>Alias</TableCell>
                      <TableCell>Nacionalidad</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!listSearchBlackList ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {listSearchBlackList.name +
                            " " +
                            listSearchBlackList.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {listSearchBlackList.client_type}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {listSearchBlackList.alias}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {listSearchBlackList.nacionality}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No hay coincidencias
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">No</TableCell>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">PEP</TableCell>
                      <TableCell>Cargo o relacionado</TableCell>
                      <TableCell>Cargo</TableCell>
                      <TableCell>Nombre PEP relacionado</TableCell>
                      <TableCell>Parentezco PEP relacionado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!listSearchCRCResult ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          1
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {listSearchCRCResult.pep_nombre}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {listSearchCRCResult.pep_valido === "1" ? "Si" : "No"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {listSearchCRCResult.api_fuente}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {listSearchCRCResult.pep_cargo}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {listSearchCRCResult.rel_nombre}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {listSearchCRCResult.rel_parentesco}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No hay coincidencias
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">No</TableCell>
                      <TableCell align="left">Nombre</TableCell>
                      <TableCell align="center">Identificación</TableCell>
                      <TableCell>Lugar de nacimiento</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!listSearchJCEResult ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        {/*  <TableCell component="th" scope="row" align="center">
                            <img
                              src={`https://dataportal.jce.gob.do${listSearchJCEResult.fotourl}`}
                              alt={listSearchJCEResult.nombres}
                            />
                          </TableCell> */}
                        <TableCell align="left">1</TableCell>
                        <TableCell component="th" scope="row" align="left">
                          {listSearchJCEResult.nombres +
                            " " +
                            listSearchJCEResult.apellido1 +
                            " " +
                            listSearchJCEResult.apellido2}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {listSearchJCEResult.mun_ced +
                            "-" +
                            listSearchJCEResult.seq_ced +
                            "-" +
                            listSearchJCEResult.ver_ced}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {listSearchJCEResult.lugar_nac}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No hay coincidencias
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
          {/* )} */}
        </Grid>
      </Grid>
      <Dialog
        open={modalAddQueries}
        onClose={() => setModalAddQueries(!modalAddQueries)}
        fullWidth
        maxWidth="xs"
      >
        <AddMoreQueries
          onSubmit={handleRequestMoreInquiries}
          cancelModal={() => setModalAddQueries(!modalAddQueries)}
          limit={5000}
          rate={500}
        />
      </Dialog>

      <Dialog
        open={modalError}
        onClose={() => setModalError(!modalError)}
        fullWidth
        maxWidth="xs"
      >
        <Box
          sx={{ p: 6, width: "auto", height: "auto" }}
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="h6"
            sx={{ textAlign: "center" }}
            marginBottom="2rem"
            fontWeight={700}
          >
            No tiene consultas disponibles
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: "center" }}
            fontSize="16px"
          >
            Su archivo posee{" "}
            {Number(numConsultas || 0) +
              Number(numberInquiries.remainingRequest || 0)}{" "}
            consultas y solo tiene disponible {numberInquiries.remainingRequest}{" "}
            consultas.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginTop: "2rem",
              mx: "auto",
            }}
            onClick={() => setModalAddQueries(!modalAddQueries)}
          >
            Solicitar más consultas
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              marginTop: "0.8rem",
              mx: "auto",
            }}
            onClick={() => setModalError(!modalError)}
            autoFocus
          >
            Cerrar
          </Button>
        </Box>
      </Dialog>

      {modalData?.modalType === "userdetails" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
          maxWidth="lg"
        >
          <ValidateClientDetalis
            searchName={
              typePersonValidate === "Física"
                ? `${watchName} ${watchLastname}`
                : `${watchJuridicoNombre}`
            }
            data={dataSelected}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            allInput={allInput}
            downloadPdfIndividual={downloadPdfIndividual}
          />
        </Dialog>
      )}
    </>
  );
};

export default Step2ValidateClient;
