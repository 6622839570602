import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequestAgrupacion = async (page: any, filter: any, companyId: any, subcompanyId: any) => {
  let responseData = {};

  await privateFetch
    .get(URL.REQUEST_MATRIX_GROUPS(page, UrlBase.registration_x_page, companyId, subcompanyId, filter ))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const createAgrupacion = async (data: any) => {
  let responseData = {};
    await privateFetch
    .post(URL.REQUEST_MATRIX_GROUPS_CREATE, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};


export const deleteAgrupacion = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_MATRIX_GROUPS_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error);
      throw new Error(error.response.data.message);
      //throw new Error(properties.com_mapprisk_label_role_delete_error);
    });
  return responseData;
};

export const getConstitutionTime = async () => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_CONSTITUTION_TIME, {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error);
      throw new Error(error.response.data.message);
      //throw new Error(properties.com_mapprisk_label_role_delete_error);
    });
  return responseData;
};
