import React from "react";
import { en_es } from "../components/utils/I18n";
import { TableCell } from "@mui/material";

interface dataType {
  data: {
    id?: number;
    filteredData: any;
    type?: string | null;
    tableName?: string;
    updateAt?: string;
  };
}

const RowHead = ({ data }: dataType) => {
  const { type, id, tableName, updateAt, ...filteredData } = data || {};

  const dataFiltrada = filteredData;

  const mappedData = Object.keys(dataFiltrada).map((key) => {
    if (en_es.hasOwnProperty(key)) {
      return en_es[key];
    } else {
      return key;
    }
  });

  const tableRows = mappedData.map((text, index) => (
    <TableCell key={index} align="center">
      {text}
    </TableCell>
  ));

  return <React.Fragment>{tableRows}</React.Fragment>;
};

export default RowHead;
