import { Card, CardContent, Box } from "@mui/material";
import { KycOnePannel } from "../KycOnePannel";
import { KycTwoPannel } from "../KycTwoPannel";
import { StyledTab, StyledTabs, TabCard } from "./KycTabs.styles";
import { useKycTabs } from "./KycTabs.hooks";
import { KycThirdPannel } from "../KycThirdPannel/KycThirdPannel";
import { KycFourthPannel } from "../KycFourthPannel";
import { DocumentsPannel } from "../../DocumentsPannel";

export const KycTabsLegalPerson = () => {
  const { activeTab, handleTabChange } = useKycTabs();
  return (
    <TabCard variant="outlined">
      <CardContent sx={{ pl: 0 }}>
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex",backgroundColor: "#fafafa" }}>
          <StyledTabs
            onChange={handleTabChange}
            value={activeTab}
            orientation="vertical"
          >
            <StyledTab label="KYC-1" />
            <StyledTab label="KYC-2" />
            <StyledTab label="KYC-3" />
            <StyledTab label="KYC-4" />
            <StyledTab label="Documents" />
          </StyledTabs>
          {activeTab === 0 && <KycOnePannel />}
          {activeTab === 1 && <KycTwoPannel />}
          {activeTab === 2 && <KycThirdPannel />}
          {activeTab === 3 && <KycFourthPannel />}
          {activeTab === 4 && <DocumentsPannel/>}
        </Box>
      </CardContent>
    </TabCard>
  );
};
