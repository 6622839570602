import { FC } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../../hooks/useApp";
import PaperSessions from "../../../components/PaperSessions";
import { rememberPasword } from "../../../types/RememberPassword";
import { SVRecoveryPassRequest } from "../../../services/security/SecurityService";
import { properties } from "../../../utils/Properties_es";

const RememberPassword: FC = () => {
  const { setLoading, setErrorMsg, setSuccessMsg } = useApp();

  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Email es requerido")
      .email("Email es invalido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<rememberPasword>({
    resolver: yupResolver(validation),
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading && setLoading(true);

      //Authenticate user
      const revoveryData: any = await SVRecoveryPassRequest(data);
      setSuccessMsg && setSuccessMsg(revoveryData.info);

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(
          error.message
            ? error.message
            : properties.com_mapprisk_label_login_general_error
        );
    }
  };

  return (
    <PaperSessions
      to="/"
      textLink="Regresar a inicio de sesión"
      titleForm="Recuperar contraseña"
    >
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name={"username"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  size="small"
                  label="Correo electrónico"
                  type="email"
                  variant="standard"
                  autoComplete="username"
                  {...register("username")}
                  error={errors.username && Boolean(errors.username)}
                  helperText={errors.username && errors.username.message}
                  sx={{
                    "& .MuiInput-root:before": {
                      borderBottomColor: "#8bc8bc!important",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              data-name="update"
              name="update"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              sx={{
                borderRadius: "0.25rem",
                mt: 3,
              }}
            >
              Enviar{" "}
            </Button>
          </Grid>
        </Grid>
      </form>
    </PaperSessions>
  );
};

export default RememberPassword;
