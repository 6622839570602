import React from "react";
import { Box, Grid, Stack } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BaseInput, CheckboxInput, MaskInput } from "../../Input";

export const KycFourPannel = ({ factorsAndOptions }: any) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      institutions: [
        {
          institutionName: "",
          contact: "",
          phone: "",
          isBlank: false,
        },
        {
          institutionName: "",
          contact: "",
          phone: "",
          isBlank: false,
        },
      ],
    },
  });
  const formSubmit = (data: any) => {
    console.log({ data });
  };

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <form onSubmit={handleSubmit(formSubmit)} style={{ paddingLeft: 16 }}>
        <Stack
          display="flex"
          flexDirection="column"
          sx={{ pt: 0, pb: 0, columnGap: 5 }}
        >
          {getValues("institutions").map((institution, index) => (
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                <BaseInput
                  name={`institutions.${index}.institutionName`}
                  control={control}
                  errors={errors}
                  label="Nombre de la institución"
                  register={register(`institutions.${index}.institutionName`)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                <BaseInput
                  name={`institutions.${index}.contact`}
                  control={control}
                  errors={errors}
                  label="Contacto"
                  register={register(`institutions.${index}.contact`)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                <MaskInput
                  name={`institutions.${index}.phone`}
                  control={control}
                  errors={errors}
                  label="Teléfono"
                  maskType="phone"
                  register={register(`institutions.${index}.phone`)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md={2} sx={{ mt: 1 }}>
                <CheckboxInput
                  name={`institutions.${index}.isBlank`}
                  control={control}
                  errors={errors}
                  label="Blank"
                  register={register(`institutions.${index}.isBlank`)}
                />
              </Grid>
            </Grid>
          ))}
        </Stack>
      </form>
    </Box>
  );
};
