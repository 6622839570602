import { privateFetch } from "../../utils/CustomFetch";
import { URL } from "../../utils/UrlConstants";
import { UrlBase } from "../../url/Urls";

export const generatePDFIndividual = async (user: string, searchName:string, companyId: string, subCompanyId: string, data: any ) => {

  try {
    const response = await privateFetch.post(
      UrlBase.base_url_screening + UrlBase.api_context + URL.REQUEST_PDF_DOWNLOAD_INDIVIDUAL(user, searchName, companyId, subCompanyId),
      data,
        {
          responseType: 'blob',
        }
    );

    return response
  } catch (error: any) {

    throw new Error("No se encuentran datos para generar el PDF");

  }
};

export const generatePDFGeneral = async (user: string, searchName:string, companyId: string, subCompanyId: string,  actualPage: number, totalPages : number, data: any ) => {
  try {    
    const response = await privateFetch.post(
      UrlBase.base_url_screening + UrlBase.api_context + URL.REQUEST_PDF_DOWNLOAD_GENERAL(user, searchName, companyId, actualPage, totalPages, subCompanyId),
      data,
        {
          responseType: 'blob',
        }
    );
    return response
  } catch (error: any) {
    throw new Error("No se encuentran datos para generar el PDF");
  }
};

