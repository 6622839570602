import * as Yup from "yup";

export const validationRegister = Yup.object().shape({
  name: Yup.string()
    .required("Nombre es requerido"),
  lastName: Yup.string()
    .required("Apellido es requerido"),
  email: Yup.string()
    .required("Email es requerido")
    .email("Email es invalido"),
  password: Yup.string()
  .required("Contaseña es requerida")
  .min(6, "Contraseña debe tener al menos 6 caracteres"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Contraseñas no coinciden")
    .required("Repetir contraseña es requerido"),
  acceptPoliciesAndServices: Yup.bool().test("acceptPoliciesAndServices", "Aceptar políticas y servicios es requeerido" , (val:any) => {
    return val;
  }),
});


export const validationLogin = Yup.object().shape({
  email: Yup.string()
    .required("Email es requerido")
    .email("Email es invalido"),
  password: Yup.string()
  .required("Contaseña es requerida")
  .min(6, "Contraseña debe tener al menos 6 caracteres")
});

export const validationLogin2FA = Yup.object().shape({
  email: Yup.string()
    .required("Email es requerido")
    .email("Email es invalido"),
  password: Yup.string()
  .required("Contaseña es requerida")
  .min(6, "Contraseña debe tener al menos 6 caracteres"),
  code2fa: Yup.string()
  .required("Código es requerido")
  .min(3, "Código es muy corto")
});

export const validationRecoverPassword = Yup.object().shape({
  username: Yup.string()
        .required("Email es requerido")
        .email("Email es invalido"),
    });;

export const validationForgotPassword = Yup.object().shape({
  password: Yup.string()
  .required("Contaseña es requerida"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Contraseñas no coinciden")
    .required("Repetir contraseña es requerido"),
});
