import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Grid, Stack, Button, Dialog } from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import FactorTabs from "../../components/FactorTabs";
import {
  TrashIcon,
  PlusIcon,
  PenIcon,
  ConfigIcon,
} from "../../components/svgicons/SvgIcons";
import { useApp } from "../../hooks/useApp";
import { useData } from "../../hooks/useData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AddOrUpdateFactor from "../../components/utils/AddOrUpdateFactor";
import {
  createUpdFactors,
  deleteFactors,
} from "../../services/factors/FactorsService";
import { factorsType } from "../../types/Factors";
import DeleteModal from "../../components/utils/DeleteModal";
import { properties } from "../../utils/Properties_es";
import ErrorAlert from "../../components/utils/ErrorAlert";
import { getRequestAgrupacion } from "../../services/agrupaciones/AgrupacionService";

const FactorsManagement = () => {
  const navigate = useNavigate();
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    errorMsg,
    modalData,
    setModalData,
    handleFetchRiskLevels,
  } = useApp();
  const {
    subCompanyData,
    companyIndexDB,
    factors,
    handleFetchFactors,
    valueTab,
  } = useData();
  const [page, setPage] = useState(0);
  const [matrizData, setMatrizData] = useState<any>([]);

  useEffect(() => {
    const getData = async () => {
      if (subCompanyData !== null && companyIndexDB?.subCompany?.id) {
        handleFetchFactors &&
          handleFetchFactors(
            page,
            companyIndexDB?.company?.id,
            companyIndexDB?.subCompany?.id
          );
      } else if (
        subCompanyData === null &&
        companyIndexDB?.subCompany === null
      ) {
        handleFetchFactors &&
          handleFetchFactors(page, companyIndexDB?.company?.id, "");
      } else {
        return;
      }
      handleFetchRiskLevels &&
        handleFetchRiskLevels(
          companyIndexDB?.company.id,
          companyIndexDB?.subCompany.id
        );
    };
    if (companyIndexDB !== null && companyIndexDB?.company?.id) {
      getData();
      handleFetchData(page);
    }
  }, [companyIndexDB]);

  const handleFetchData = async (currentPage: number, filter?: string) => {
    setLoading && setLoading(true);
    try {
      const companyId = companyIndexDB.company.id;
      const subcompanyId = companyIndexDB.subCompany
        ? companyIndexDB.subCompany.id
        : 0;

      setPage(currentPage);
      let data: any = await getRequestAgrupacion(
        currentPage,
        filter,
        companyId,
        subcompanyId
      );
      //console.log("handleFetchData=",data.content)
      if (data) {
        setMatrizData(data);
        //console.log(data);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleAddFactor = async (data: any) => {
    console.log(data);
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let newData: any = [];
      await data?.newFactor?.map((item: any) => {
        const newItem = {
          company: companyIndexDB?.company?.id ?? 0,
          name: item.name,
          type: valueTab === 0 ? "F" : "J",
          percentage: 0,
          editable: true,
          user: authInfo.username,
          high_risk: false,
          matrix_group: item.matrix_group,
        };

        if (companyIndexDB?.subCompany?.id) {
          //@ts-ignore
          newItem.sub_company = companyIndexDB.subCompany.id;
        }

        newData.push(newItem);
      });
      let response = await createUpdFactors(newData);
      if (response) {
        setSuccessMsg && setSuccessMsg("Factores agregados");
        handleFetchFactors &&
          handleFetchFactors(
            page,
            companyIndexDB?.company?.id,
            companyIndexDB?.subCompany?.id
          );
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleUpdateFactor = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let response = await createUpdFactors(data.newFactor);
      if (response) {
        setSuccessMsg &&
          setSuccessMsg(properties.com_mapprisk_label_request_update);
        handleFetchFactors &&
          handleFetchFactors(
            page,
            companyIndexDB?.company?.id,
            companyIndexDB?.subCompany?.id
          );
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleDelete = async (id: string) => {
    handleCancelModal();
    setLoading && setLoading(true);
    console.log(id);
    try {
      let responseDelete = await deleteFactors(id);
      if (responseDelete) {
        setSuccessMsg && setSuccessMsg(responseDelete);
      }
      setLoading && setLoading(false);
      handleFetchFactors &&
        handleFetchFactors(
          page,
          companyIndexDB?.company?.id,
          companyIndexDB?.subCompany?.id
        );
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      handleFetchFactors &&
        handleFetchFactors(
          customPage,
          companyIndexDB?.company?.id,
          companyIndexDB?.subCompany?.id
        );
    }
  };

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;

    const id = event.currentTarget.getAttribute("data-id");
    const name = event.currentTarget.getAttribute("data-name-element");

    if (modalAction === "update") {
      const dataFilter =
        valueTab === 0
          ? factors?.dataPhysical.content
          : factors?.dataLegal.content;

      object = await dataFilter.filter((f: any) => f.id === Number(id));
    } else {
      object = { id: id, name: name ?? "" };
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAddFactor(data);
        break;
      case "update":
        await handleUpdateFactor(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Mantenimiento / Factores">
        {companyIndexDB === null ||
        companyIndexDB?.company === null ||
        companyIndexDB?.company?.length === 0 ? (
          <ErrorAlert message="Debe seleccionar primero compañía y subcompañía" />
        ) : (
          <>
          {subCompanyData?.length > 0 &&
            companyIndexDB?.subCompany === null && (
              <ErrorAlert message="La compañía tiene subcompañías asociadas, debe seleccionar una de ellas" />
            )}
          <Grid container spacing={4} sx={{ pb: 8 }}>
            <Grid item xs={12} sx={{ my: 3 }}>
              <Stack alignItems={"center"} direction={"row"} spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                  data-name="create"
                >
                  Agregar
                  <PlusIcon sx={{ ml: 1 }} />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate("/dashboard/settingLevelFactors/")}
                >
                  Ajustes
                  <ConfigIcon sx={{ ml: 1 }} />
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <FactorTabs
                handleOpenModal={handleOpenModal}
                handleChangePage={handleChangePage}
                page={page}
              />
            </Grid>
          </Grid>
          </>
        )}
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateFactor
            /* data={
              valueTab === 0
                ? factors?.dataPhysical.content
                : factors?.dataLegal.content
            } */
            //@ts-ignore
            data={modalData?.modalObject[0]}
            type={valueTab === 0 ? "F" : "J"}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            matrizData={matrizData}
            numberItems={
              valueTab === 0
                ? factors?.dataPhysical?.totalElements
                : factors?.dataLegal?.totalElements
            }
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject.name}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={onSubmit}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default FactorsManagement;
