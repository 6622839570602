import {
  Stack,
  Link,
  Typography,
  styled,
  useTheme,
  SvgIcon,
} from "@mui/material";
import { Twitter, Linkedin, Instagram, Facebook } from "./svgicons/SvgIcons";

const LinkText = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#FFFFFF",
  display: "inline-block",
  marginBottom: "0.15rem",
  "&:hover": {
    color: "#dfc285",
  },
}));

const HoverableIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: "1.625rem",
  width: 26,
  transition: "color 0.3s ease",
  color: "#FFFFFF",
  "&:hover": {
    color: "#dfc285",
  },
}));

const Footer = () => {
  const theme = useTheme();
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        width: "100%",
        px: "20px",
        position: {
          md: "absolute",
        },
        bottom: {
          md: 4,
        },
        left: {
          md: 0,
        },
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        sx={{ mt: "1.5rem", mb: "0.5rem", display: "flex" }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={2}
          direction="row"
        >
          <Link
            href="https://www.facebook.com/profile.php?id=100063218021465"
            target="_blank"
            underline="none"
            color={theme.palette.primary.contrastText}
          >
            <HoverableIcon>
              <Facebook />
            </HoverableIcon>
          </Link>
          <Link
            href="https://www.instagram.com/mappriskdr/"
            target="_blank"
            underline="none"
            color={theme.palette.primary.contrastText}
          >
            <HoverableIcon>
              <Instagram />
            </HoverableIcon>
          </Link>
          <Link
            href="https://www.linkedin.com/company/mappriskdr/"
            target="_blank"
            underline="none"
            color={theme.palette.primary.contrastText}
          >
            <HoverableIcon>
              <Linkedin />
            </HoverableIcon>
          </Link>
        </Stack>
        <Typography
          sx={{
            color: "#DD9807",
          }}
        >
          <LinkText href="mailto:info@mappriskdr.com">
            info@mappriskdr.com
          </LinkText>
        </Typography>
      </Stack>
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: "1.5rem", mb: "0.5rem", display: "flex" }}
      >
        <Typography
          color={{ xs: "secondary.contrastText", md: "secondary.dark" }}
          variant="body1"
        >
          © 2023 by MappRisk.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Footer;
