import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequestUser = async (page, company, subCompany, filter) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["subCompany", subCompany ?? ""],
    ["filter", filter],
  ]);

  let keysParams = [];
  params.forEach((value, key) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });
  keysParams.forEach((key) => {
    params.delete(key);
  });

  await privateFetch
    .get(URL.REQUEST_USER_LIST(page, UrlBase.registration_x_page, company), {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_USER(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_mapprisk_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data) => {
  //console.log("enra a crear usuao");
  let responseData = {};
  const requestBody = {
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    roleID: data.roleID,
    password: data.password ?? "",
    company: data.company,
    departments: data.departments,
    identification: data.identification,
  };
  console.log(requestBody);
  await privateFetch
    .post(URL.REQUEST_USER_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log(error.response.data.message);

      throw new Error(error.response.data.message);
      console.log(error);
    });
  return responseData;
};

export const updateRequest = async (data) => {
  let responseData = {};
  /* const requestBody = {
    id: data.id,
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    status: data.status,
    roleID: data.roleID.id,
    password: data.password,
    departments: data.departments.id,
    identification: data.identification,
  }; */
  await privateFetch
    .put(URL.REQUEST_USER(null), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_mapprisk_label_user_update_error);
      //console.log(error)
    });
  return responseData;
};

export const updatePassRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    status: data.status,
    roleID: data.roleID.id,
    password: data.password,
    departments: data.departments,
    identification: data.identification,
  };
  await privateFetch
    .put(URL.REQUEST_USER_CHANGE_PASSWORD, {
      ...requestBody,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_mapprisk_label_user_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_USER(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_mapprisk_label_user_delete_error);
    });
  return responseData;
};

export const activateUser = async (code, username) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ACTIVATE_USER(code, username), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error activando usuario");
    });
  return responseData;
};
