import { Control, Controller, ValidationRule } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioGroupProps,
  FormHelperText,
} from "@mui/material";
import { ReactNode } from "react";

type TData = {
  value: string;
  label: string;
};

interface IRadioGroupInputProps extends Omit<RadioGroupProps, "name"> {
  control: Control<any>;
  name: string;
  required?: string | ValidationRule<boolean> | undefined;
  label?: ReactNode | undefined;
  data: TData[];
  errors: any;
}

export const RadioGroupInput = ({
  control,
  name,
  required,
  label,
  errors,
  data,
  ...rest
}: IRadioGroupInputProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "text.primary" }}
          >
            {label}
          </FormLabel>
          <RadioGroup
            value={value}
            onChange={onChange}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            {...rest}
          >
            {data?.length &&
              data.map((item: TData) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
          </RadioGroup>
          <FormHelperText error={!!errors[name]}>
            {errors[name] && errors[name].message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
