import { Card, CardContent, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation.schema";
import { AutocompleteInput } from "../Input/AutocompleteInput";
import { useDueDiligenceContext } from "../../provider/DuediligentProvider";
import { useEffect } from "react";
import { TClientType } from "../../provider/types";
import { FiltersCard } from "./FiltersPannel.styles";
import { customerMatriz } from "../../provider/types";
import { useApp } from "../../../../hooks/useApp";

interface IFilterData {
  client: customerMatriz;
  riskLevel: string;
  clientType: TClientType;
}

export const FiltersPannel = ({ customerMatriz }: any) => {
  const { dispatch, state } = useDueDiligenceContext();
  const { riskData } = useApp();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<IFilterData>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      client: {
        id: "",
        name: "",
      },
      riskLevel: "",
      clientType: state.clientType,
    },
  });
  const whatchClientType = watch("clientType");
  const whatchClient = watch("client");

  const formSubmit = (data: IFilterData) => {
    console.log(data);
  };

  useEffect(() => {
    dispatch({ type: "TOGGLE_CLIENT_TYPE", payload: whatchClientType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whatchClientType]);

  useEffect(() => {
    dispatch({ type: "SET_CLIENT", payload: whatchClient });
    dispatch({
      type: "TOGGLE_CLIENT_TYPE",
      payload: whatchClient?.type
        ? whatchClient?.type === "F"
          ? { label: "Persona Física", value: "F" }
          : { label: "Persona Jurídica", value: "J" }
        : { value: "", label: "" },
    });
    setValue(
      "clientType",
      whatchClient?.type
        ? whatchClient?.type === "F"
          ? { label: "Persona Física", value: "F" }
          : { label: "Persona Jurídica", value: "J" }
        : { value: "", label: "" }
    );
    whatchClient?.final_result && compareRanges(whatchClient?.final_result);
    //console.log(whatchClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whatchClient]);

  const compareRanges = (value: number) => {
    const inherentRisk =
      riskData !== null && riskData?.find((item: any) => item.type === "2");
    const matches = inherentRisk?.details?.filter((element: any) => {
      return element.from <= value && value <= element.to;
    });

    if (matches?.length > 0) {
      setValue("riskLevel", matches[0]);
    }

    /* if (matches?.length > 0) {
      setValue("actual_legend_risk", matches[0].id);
      setCurrentRiskLevel(`${matches[0].caption}`);
      setRiskColors(`${matches[0].color}`);
      setTextColor(isColorLight(matches[0].color) ? "black" : "white");
    } else {
      setValue("actual_legend_risk", inherentRisk.details[0].id);
      setCurrentRiskLevel("");
      setRiskColors("#FFFFFF");
    } */
  };

  return (
    <FiltersCard variant="outlined" style={{ width: "100%" }}>
      <CardContent>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Grid container spacing={8} sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={4} lg={3} sx={{ mt: 5 }}>
              <AutocompleteInput
                name="client"
                control={control}
                errors={errors}
                data={customerMatriz ?? []}
                //data={["John Doe", "Jane Doe"]}
                getOptionLabel={(option) => option.name ?? ""}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                inputProps={{
                  label: "Cliente",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ mt: 5 }}>
              <AutocompleteInput
                name="clientType"
                control={control}
                errors={errors}
                data={[
                  { label: "Persona Física", value: "F" },
                  { label: "Persona Jurídica", value: "J" },
                ]}
                getOptionLabel={(option: any) => {
                  // console.log({ option });
                  return option?.label || "";
                }}
                isOptionEqualToValue={(option, value) => {
                  // console.log({ option, value });

                  return option.value === value.value;
                }}
                inputProps={{
                  label: "Tipo Cliente",
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ mt: 5 }}>
              <AutocompleteInput
                name="riskLevel"
                control={control}
                errors={errors}
                data={
                  riskData?.filter((risk: any) => risk.type === "2")[0]
                    ?.details ?? []
                }
                //data={["Riesgo bajo", "Riesgo medio", "Riesgo alto"]}
                getOptionLabel={(option) => option.caption ?? ""}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                inputProps={{
                  label: "Nivel Riesgo",
                }}
                disabled={true}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </FiltersCard>
  );
};
