import { URL } from "../../utils/UrlConstants";
import { UrlBase } from "../../url/Urls";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";

export const searchClient = async (data: any) => {
  try {
    const response = await privateFetch.post(UrlBase.base_url_screening + UrlBase.api_context + URL.REQUEST_SEARCH_CLIENT, data);
    const responseData = response.data;
    return responseData;
  } catch (error: any) {
    // Puedes lanzar un nuevo error personalizado o simplemente propagar el error original
    throw new Error(error.response.data.message);
    //console.log(error.response.data.message)
  }
};

export const searchClientBlackListAndInternalList = async (data: any) => {
  try {
    const response = await privateFetch.post(UrlBase.base_url_screening + UrlBase.api_context + URL.REQUEST_SEARCH_CLIENT_BLACKLIST_INTERNALLIST, data);
    const responseData = response.data;
    return responseData;
  } catch (error: any) {
    // Puedes lanzar un nuevo error personalizado o simplemente propagar el error original
    throw new Error(error.response.data.message);
    //console.log(error.response.data.message)
  }
};

export const requestCountries = async () => {
  let responseData = {};
  await privateFetch
    .get(UrlBase.base_url_screening + UrlBase.api_context + URL.REQUEST_COUNTRIES, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};
