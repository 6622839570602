import { useEffect, useState } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  IconButton,
  useTheme,
  DialogContentText,
  Autocomplete,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyTooltip from "../../components/utils/FancyTooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TrashIcon, PlusIcon } from "../../components/svgicons/SvgIcons";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useApp } from "../../hooks/useApp";
import { useData } from "../../hooks/useData";
import InputLabelToForm from "../InputLabelToForm";
import { CONSTANT } from "../../utils/Constants";
import { getRequestUser } from "../../services/users/UserService";
import { getRequestEmployeeList } from "../../services/employees/EmployeesControl";
import {
  getRequestCapacitationEmployee,
  deleteRequestCapacitationEmployee,
} from "../../services/trainingcontrol/TrainingControl";

import DeleteButton from "../../components/utils/iconButtons/DeleteButton";
import FindName from "../../components/utils/employee/FindName";

interface AddOrUpdateEmployee {
  id?: number;
  employeeId: {
    id?: number;
    firstName?: string;
    lastName: string;
    phone: string;
    email: string;
    state: string;
  }[];
}

interface ItemType {
  id?: number;
  firstName: string;
  lastName: string;
  visible?: number;
}

interface ItemType4 {
  id?: number;
  employeeId: any;
  capacitationId: any;
  completedHours: number;
  pendingHours?: number;
  urlCertificateStorage: string;
}

interface ItemType3 {
  id?: number;
  employeeId?: {
    id?: number;
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    state?: string;
  };
  capacitationId?: {
    id?: number;
    name?: string;
    method?: string;
    instructor?: string;
    total_hours?: number;
    date?: string;
    period?: string;
  };
  completedHours?: number;
  pendingHours?: number;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateSettingsEmployeesModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { companyIndexDB } = useData();
  const [companiaLabel, setCompaniaLabel] = useState("");
  const [subcompaniaLabel, setSubcompaniaLabel] = useState("");
  const [employeeIdSel, setEmployeeIdSel] = useState(null);
  const [errorEmployee, setErrorEmployee] = useState(false);

  const [employeeData, setEmployeeData] = useState<ItemType[]>([]);
  const [capacitationEmployeeData, setCapacitationEmployeeData] = useState<
    ItemType3[]
  >([]);

  useEffect(() => {
    handleFetchData();
  }, []);
  const handleFetchData = async () => {
    try {
      //console.log("AddOrUpdateSettingsEmployeesModal handleFetchData companyIndexDB=",companyIndexDB);
      //console.log("AddOrUpdateSettingsEmployeesModal handleFetchData data=",data);

      if (companyIndexDB.company)
        setCompaniaLabel(companyIndexDB.company.description);
      if (companyIndexDB.subCompany)
        setSubcompaniaLabel(companyIndexDB.subCompany.description);

      const companyId = parseInt(companyIndexDB.company.id);

      let subcompanyId = 0;
      if (companyIndexDB.subCompany) {
        subcompanyId = parseInt(companyIndexDB.subCompany.id);
      }

      //console.log("AddOrUpdateSettingsEmployeesModal companyId=",companyId);
      //console.log("AddOrUpdateSettingsEmployeesModal subcompanyId=",subcompanyId);

      let capacitationEmployeeData: any = await getRequestCapacitationEmployee(
        0,
        data.id
      );
      /* console.log(
        "handleFetchData capacitationEmployeeData=",
        capacitationEmployeeData.content
      ); */
      let arrCapacitationEmployeeEmployees: any[] = [];
      if (capacitationEmployeeData.content.length === 0) {
        setCapacitationEmployeeData([]);
      } else {
        arrCapacitationEmployeeEmployees = capacitationEmployeeData.content;
        setCapacitationEmployeeData(capacitationEmployeeData.content);
      }

      //let employeeData: any = await getRequestEmployeeList(0,"",companyId,subcompanyId);
      let employeeData: any = await getRequestUser(
        0,
        companyId,
        subcompanyId,
        ""
      );
      //console.log("AddOrUpdateSettingsEmployeesModal employeeData=",employeeData.content);
      if (employeeData.content.length === 0) {
        setEmployeeData([]);
      } else {
        let employeeDataAssing: any[] = [];
        const arrEmployees = employeeData?.content;
        for (let i = 0; i < arrEmployees.length; i++) {
          const obj = arrEmployees[i];
          const findEmployee = arrCapacitationEmployeeEmployees.some(
            (item) => item.employeeId.id === obj.id
          );
          if (findEmployee) {
            obj.visible = 0;
          } else {
            obj.visible = 1;
          }
          employeeDataAssing.push(obj);
        }
        setEmployeeData(employeeDataAssing);
        //console.log("handleFetchData employeeDataAssing=", employeeDataAssing);
      }
    } catch (error: any) {
      console.log("handleFetchData error=", error.message);
    }
  };

  const handleAddOnChange = async (employeeId: any) => {
    try {
      //console.log(employeeId);
      //console.log(capacitationEmployeeData);
      /*  console.log(
        "handleAdd capacitationEmployeeData=",
        capacitationEmployeeData
      ); */
      if (employeeId) {
        //console.log("handleAdd employeeId=",employeeId);
        const findEmployeeCapacitation = capacitationEmployeeData.filter(
          (item: any) => item.employeeId?.id === employeeId
        );
        //console.log(findEmployeeCapacitation);
        /* console.log(
          "handleAdd findEmployeeCapacitation=",
          findEmployeeCapacitation
        ); */
        if (findEmployeeCapacitation.length === 0) {
          setErrorEmployee(false);

          //console.log("handleAdd employeeData=", employeeData);
          const findEmployee = employeeData.filter(
            (item: any) => item.id === employeeId
          );
          //console.log("handleAdd findEmployee=", findEmployee);
          findEmployee[0].visible = 0;

          let employeeAdd: ItemType3[] = [];
          const newItem = {
            id: 0,
            employeeId: {
              id: parseInt(employeeId),
              firstName: findEmployee[0].firstName,
              lastName: findEmployee[0].lastName,
            },
            capacitationId: {
              id: parseInt(data.id),
            },
            completedHours: 0,

            urlCertificateStorage: "",
          };

          console.log("handleAdd newItem=", newItem);
          //"pendingHours":0,
          employeeAdd = [...capacitationEmployeeData, newItem];
          //console.log("handleAdd employeeAdd", employeeAdd);
          setCapacitationEmployeeData(employeeAdd);
        }
      }
    } catch (error: any) {
      console.log("handleDelete error=", error.message);
    }
  };

  const handleDelete = async (id: any, index: any) => {
    try {
      //console.log("handleDelete id=",id);
      //console.log("handleDelete index=",index);
      /**/
      const idEmployee = capacitationEmployeeData[index].employeeId?.id;
      //console.log("handleDelete capacitationEmployeeData=",capacitationEmployeeData[index]);
      //console.log("handleDelete idEmployee=",idEmployee);
      const findEmployee = employeeData.filter(
        (item: any) => item.id === idEmployee
      );
      findEmployee[0].visible = 1;
      //return;
      const employeeDelete = capacitationEmployeeData.filter(
        (_, indexItem) => indexItem !== index
      );
      //console.log("employeeDelete index=",employeeDelete);
      setCapacitationEmployeeData(employeeDelete);

      if (id !== 0) {
        //elimna de db
        let deleteRequest: any = await deleteRequestCapacitationEmployee(id);
        //handleFetchData();
      }
    } catch (error: any) {
      console.log("handleDelete error=", error.message);
    }
  };
  const onSubmit2 = (data: any) => {
    try {
      let bActive = true;
      data.company = parseInt(companyIndexDB.company.id);

      let subcompany = null;
      if (companyIndexDB.subCompany) {
        subcompany = parseInt(companyIndexDB.subCompany.id);
      }
      data.subcompany = subcompany;
      //console.log("onSubmit2 data=",data);

      //console.log("onSubmit2 capacitationEmployeeData=",capacitationEmployeeData);
      let employeeAdd: ItemType3[] = [];
      for (let i = 0; i < capacitationEmployeeData.length; i++) {
        const obj = capacitationEmployeeData[i];
        if (obj.id === 0) {
          employeeAdd.push(obj);
        }
      }
      //console.log("onSubmit2 employeeAdd=",employeeAdd);
      if (capacitationEmployeeData.length === 0) {
        setErrorEmployee(true);
        bActive = false;
      } else {
        setErrorEmployee(false);
      }

      //bActive=false;
      if (bActive) onSubmit(employeeAdd);
    } catch (error: any) {
      console.log("onSubmit2 error=", error.message);
    }
  };

  //Validación de datos
  const formSchemaOptionsName = {
    id: Yup.string(),
    firstName: Yup.string(),
  };

  const validation = Yup.object().shape({
    employeeId: Yup.object().shape(formSchemaOptionsName).nullable(),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : "",
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateEmployee>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {modalType === "update"
          ? properties.com_mapprisk_label_settingemployeestraining_button_assing_title
          : properties.com_mapprisk_label_settingemployeestraining_button_assing_title}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {/*
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            fullWidth
                            label="Compañía seleccionada"
                            size="small"
                            type="text"
                            variant="standard"
                            value={companiaLabel}
                            sx={{ "& input": { pl: "0!important" } }}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            fullWidth
                            label="Sub Compañía seleccionada"
                            size="small"
                            type="text"
                            variant="standard"
                            value={subcompaniaLabel}
                            sx={{ "& input": { pl: "0!important" } }}
                            disabled
                        />
                    </Grid>
                    */}

            <Grid item xs={12}>
              <Controller
                control={control}
                name="employeeId"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    //multiple
                    disableCloseOnSelect
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                      //console.log("newValue=", newValue);
                      setEmployeeIdSel(newValue.id);
                      handleAddOnChange(newValue.id);
                    }}
                    filterOptions={(employeeData, { inputValue }) => {
                      return employeeData.filter((item) => {
                        return item.visible === 1;
                      });
                    }}
                    options={employeeData}
                    getOptionLabel={(option: any) =>
                      option.firstName + " " + option.lastName || ""
                    }
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Empleado"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.employeeId}
                          helperText={
                            errors.employeeId && errors.employeeId.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            {/* 
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                      type="button"
                      variant="outlined"
                      onClick={() => {
                        handleAdd();
                      }}
                    >
                      <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> 
                      Agregar empleado
                    </Button>
                  </Grid>
                  */}
          </Grid>
        </form>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {capacitationEmployeeData &&
                capacitationEmployeeData.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row?.employeeId?.firstName +
                        " " +
                        row?.employeeId?.lastName}
                    </TableCell>
                    <TableCell align="center">
                      <FancyTooltip title={"Eliminar"} placement="top">
                        <IconButton
                          aria-label="trash"
                          component="label"
                          color="error"
                          onClick={() => {
                            handleDelete(row.id, i);
                          }}
                        >
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {errorEmployee ? (
          <Alert severity="error" sx={{ mb: 5 }}>
            Debes agregar un empleado
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit2)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateSettingsEmployeesModal;
